import { Heading, Icon } from '@brewlogix/blx-ui'
import Image from 'next/image'
import { Icon as ChakraIcon } from '@chakra-ui/react'
import Link from 'next/link'
import React from 'react'
import { AppStoreOptions } from 'src/types/ui/enums'
import AppStoreButton from '../buttons/AppStoreButton'
import { Menu } from '../header'
import { XIcon } from '../icons/XIcon'
interface IFooter {
    brand: {
        title: string
        url: string
        asset: string
    }
}

export const Footer: React.FC<IFooter> = ({ brand }) => {
    return (
        <footer id='footer' className=' bg-white py-12 lg:pl-8'>
            <div className='container mx-auto'>
                <div className='footer--main mb-6 grid grid-cols-12'>
                    <div className='col-span-12 text-center md:col-span-4 md:text-left'>
                        <Link href='/' passHref>
                            <a className='bdb-menu-bar--left__brand-title flex justify-center lg:justify-start'>
                                <span className={brand.asset ? 'sr-only' : ''}>{brand.title}</span>
                                <div className='relative h-[60px] w-[250px] '>
                                    {Object.entries(brand).map((value, key) =>
                                        value[0] === 'asset' ? (
                                            <Image
                                                key={key}
                                                src={value[1]}
                                                layout='fill'
                                                alt={brand.title}
                                                className='mx-auto w-full max-w-[16rem] pb-2 '
                                                objectFit='contain'
                                            />
                                        ) : null
                                    )}
                                </div>
                            </a>
                        </Link>
                        <p className='mt-2 pr-4 font-semibold text-gray-400 md:mb-6 md:mt-auto'>
                            Your Next Brewery Adventure Awaits.
                        </p>
                        <div className='flex flex-col items-center justify-center gap-2 pb-4 pt-4 md:flex-row md:justify-start md:pt-0'>
                            <AppStoreButton appStore={AppStoreOptions.AppStore} />
                            <AppStoreButton appStore={AppStoreOptions.GooglePlay} />
                        </div>
                        <div className='h-30 flex w-full flex-row items-center justify-center md:w-20'>
                            <img
                                src='/assets/ba-certified.svg'
                                width={133}
                                height={80}
                                alt="Independent craft certification logo for Brewer's Association"
                                className='max-h-30 max-w-[4rem]'
                            />
                        </div>
                    </div>
                    <div className='col-span-2'></div>
                    <div className='col-span-12 mt-6 flex flex-col text-center md:col-span-2'>
                        <Heading
                            customClasses='text-center md:text-left mb-6 uppercase font-lato font-bold text-base text-gray-400 flex justify-center md:justify-start'
                            text='Explore'
                            size='h6'
                            type='h2'
                        />
                        <Menu
                            customWrapperClasses=' text-left flex justify-start'
                            label='Home'
                            href='/'
                            id='menu--home'
                        />
                        <Menu
                            customWrapperClasses='bdb-menu-bar--left__items-menu text flex justify-start'
                            label='Breweries'
                            href='/map'
                            id='menu--breweries'
                        />
                        <Menu
                            customWrapperClasses='bdb-menu-bar--left__items-menu text flex justify-start'
                            label='Brews'
                            href='/brewknowledge'
                            id='menu--brews'
                        />
                    </div>
                    <div className='col-span-12 mt-6 flex flex-col text-center md:col-span-2'>
                        <Heading
                            customClasses='text-center md:text-left mb-6 uppercase font-lato font-bold text-base text-gray-400 flex justify-center md:justify-start'
                            text='Company'
                            size='h6'
                            type='h2'
                        />
                        <Menu
                            id='bdb-menu-bar--left__items-products'
                            customWrapperClasses='text-left flex justify-start'
                            label='About Us'
                            href='https://www.brewlogix.com'
                            target='_blank'
                        />
                        <Menu
                            id='bdb-menu-bar--left__items-products'
                            customWrapperClasses='bdb-menu-bar--left__items-menu text flex justify-start'
                            label='Contact'
                            href='https://www.brewlogix.com/contact-us/'
                            target='_blank'
                        />
                    </div>
                    <div className='col-span-12 mb-6 mt-6 text-center md:col-span-2 md:mb-auto'>
                        <Heading
                            customClasses='text-center md:text-left mb-6 uppercase font-lato font-bold text-base text-gray-400 flex justify-center md:justify-start'
                            text='Stay Connected'
                            size='h6'
                            type='h2'
                        />

                        <div className='icons__wrapper flex justify-center md:justify-start'>
                            <a
                                className='mr-3'
                                href='https://www.facebook.com/brewlogix/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <Icon slug='Facebook' />
                                <div className='sr-only'>Facebook Profile</div>
                            </a>
                            <a
                                className='mr-3'
                                href='https://www.instagram.com/brewlogix/?hl=en'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <Icon slug='Instagram' />
                                <div className='sr-only'>Instagram Profile</div>
                            </a>
                            <a
                                className='mr-3'
                                href='https://x.com/brewlogix'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <ChakraIcon as={XIcon} />
                                <div className='sr-only'>X Profile</div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='footer--utility'>
                    <div className='mx-auto mr-0 flex flex-col text-center md:mx-0 md:flex-row'>
                        <div className='flex w-full items-center justify-center py-2 text-center md:w-1/2 md:justify-start md:text-left'>
                            <a
                                rel='noreferrer'
                                className='font-medium text-gray-400'
                                target='_blank'
                                href='https://www.brewlogix.com/'
                            >
                                &copy; {new Date().getFullYear()} BrewLogix, LLC. All rights
                                reserved.
                            </a>
                        </div>
                        <div className='flex w-full flex-col justify-around py-2 text-right md:w-1/2 md:flex-row md:justify-end'>
                            <Link href='/privacy'>
                                <a className='text-center font-medium text-gray-400 md:mr-3'>
                                    Privacy Policy
                                </a>
                            </Link>
                            <Link href='/terms'>
                                <a className='text-center font-medium text-gray-400 md:mr-3'>
                                    Terms of Service
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
