import React from 'react'
import * as CC from '@chakra-ui/react'
import { useSavedBoardsStore } from '../state/useSavedBoardsStore'
import { createNewBoardConfig } from '../config/createNewBoardConfig'
import CreateBoardActions from './CreateBoardActions'
import CreateBoardFormField from './CreateBoardFormField'

interface ICreateBoard {}

const CreateBoard: React.FC<ICreateBoard> = () => {
    const createBoardModalOpen = useSavedBoardsStore((state) => state.createBoardModalOpen)
    const setCreateBoardModalOpen = useSavedBoardsStore((state) => state.setCreateBoardModalOpen)
    const { fields } = createNewBoardConfig
    return (
        <CC.Modal
            isOpen={createBoardModalOpen}
            onClose={() => setCreateBoardModalOpen(false)}
            isCentered={true}
            size='xl'
        >
            <CC.ModalOverlay />
            <CC.ModalContent className='space-y-3' p={6}>
                <CC.ModalHeader p={0}>Create New Board</CC.ModalHeader>
                <CC.ModalCloseButton />
                <CC.ModalBody p={0}>
                    <CC.VStack spacing={5}>
                        {fields.map((field) => (
                            <CreateBoardFormField key={field.name} field={field} />
                        ))}
                    </CC.VStack>
                </CC.ModalBody>
                <CC.ModalFooter p={0}>
                    <CreateBoardActions />
                </CC.ModalFooter>
            </CC.ModalContent>
        </CC.Modal>
    )
}
export default CreateBoard
