import Image from 'next/image'
import React from 'react'
import { formHeaderConfig } from '../../config/formHeaderConfig'
import FormHeaderVariant from '../../types/FormHeaderVariant'

interface IFormHeader {
    variant: FormHeaderVariant
}

const FormHeader: React.FC<IFormHeader> = ({ variant }) => {
    const imgProps = { ...formHeaderConfig[variant].image }
    const title: string = formHeaderConfig?.[variant]?.title()
    const description: string = formHeaderConfig?.[variant]?.description()
    return (
        <>
            <Image src={imgProps.src()} alt={imgProps.alt()} className='rounded-t-md' />
            {(title || description) && (
                <div className='space-y-3 px-6 pt-4'>
                    {title && <p>{title}</p>}
                    {description && (
                        <p className='text-base font-semibold text-gray-300'>{description}</p>
                    )}
                </div>
            )}
        </>
    )
}
export default FormHeader
