import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { checkboxAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    checkboxAnatomy.keys
)

const baseStyle = definePartsStyle({
    label: {
        fontFamily: 'Lato',
    },
    control: {
        rounded: 'md',
        boxSize: 5,
        borderColor: 'gray.100',
    },
})
const sizes = {
    md: {
        label: {
            fontSize: '0.875rem',
        },
    },
}
const variants = {}
const multiStyleConfig = defineMultiStyleConfig({ baseStyle, variants, sizes })

const CheckboxConfig = multiStyleConfig

export default CheckboxConfig
