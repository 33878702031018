import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { menuAnatomy } from '@chakra-ui/anatomy'
import tailwindConstants from '@brewlogix/blx-ui/tailwind.constants.json'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys)
const color = tailwindConstants.theme.colors

const baseStyle = definePartsStyle({
    list: {
        boxShadow: 'lg',
        borderRadius: 'md',
        border: 'none',
        bg: 'light',

        py: '1',
    },
    item: {
        color: color.gray[500],
        fontWeight: 'normal',
        px: '20px',
        _hover: { bg: color.blue[50] },
        _focus: { bg: color.blue[50] },
    },
    groupTitle: {
        textTransform: 'uppercase',
        color: color.gray[300],
        fontWeight: 700,
    },
    command: {
        opacity: '0.8',
        fontFamily: 'mono',
        fontSize: 'sm',
        letterSpacing: 'tighter',
        pl: '4',
    },
})

const multiStyleConfig = defineMultiStyleConfig({ baseStyle })

const MenuConfig = { baseStyle, multiStyleConfig }

export default MenuConfig
