import { gql } from 'graphql-request'

export const deleteBoardMutation = gql`
mutation DeleteBoard($input: inputDeleteBoard!) {
	deleteBoard(input: $input){
		updatedDate
		title
		lastUpdatedBy
		id
		createdDate
		createdBy
		favorites {
			brands
			favoriteBrands {
				brandId
				isActive
				notes
			}
			favoriteLocations {
				brandId
				isActive
				locationId
				notes
			}
			favoriteProducts {
				brandId
				isActive
				notes
				productId
			}
			locations {
				brandId
				locationId
			}
			products {
				brandId
				productId
			}
		}
		routes {
            title
            id
        }
	}
}`
