import * as React from 'react'
import * as UI from '@brewlogix/blx-ui'
import classNames from 'classnames'
import { isFavorited } from '../utils/helpers/isFavorited'
import { useFavoriteActionsStore } from '../state/useFavoriteActionsStore'
import { userStore } from 'src/features/auth/state/userStore'
import { useLoginGateStore } from 'src/features/auth/state/useLoginGateStore'
import { FavoriteType } from '../types/enums/FavoriteType'
import { Brand, Product, Location, BoardItemType } from 'src/types/schema/graphql'
import { useToast } from '@chakra-ui/react'
import SavedItemToast from './SavedItemToast'
import { savedItemToastConfig } from '../config/savedItemToastConfig'
import * as CC from '@chakra-ui/react'
import * as HeroIconsOutline from '@heroicons/react/outline'
import * as HeroIconsSolid from '@heroicons/react/solid'
import { ActiveFavoriteItemState } from '../types/enums'

interface IFavoritesButton {
    hasText?: boolean
    type: FavoriteType
    heartSize?: 'sm' | 'md'
    // If the button is on an account card, then it should update the account store
    shouldUpdateAccountStore?: boolean
    ariaLabel?: string
    item: Brand | Location | Product
}

const FavoritesButton: React.FC<IFavoritesButton> = ({
    item,
    hasText = false,
    type,
    heartSize = 'md',
    shouldUpdateAccountStore = false,
    ariaLabel,
}): React.ReactElement => {
    const favoriteBrandIds = useFavoriteActionsStore((state) => state.favoriteBrandIds)
    const favoriteLocationIds = useFavoriteActionsStore((state) => state.favoriteLocationIds)
    const favoriteProductIds = useFavoriteActionsStore((state) => state.favoriteProductIds)
    const setActiveFavoriteItem = useFavoriteActionsStore((state) => state.setActiveFavoriteItem)
    const setActiveFavoriteType = useFavoriteActionsStore((state) => state.setActiveFavoriteType)
    const setFavoriteModalOpen = useFavoriteActionsStore((state) => state.setFavoriteModalOpen)
    const setShouldUpdateAccountStore = useFavoriteActionsStore(
        (state) => state.setShouldUpdateAccountStore
    )
    const toggleFavorite = useFavoriteActionsStore((state) => state.toggleFavorite)
    const favoritesNote = useFavoriteActionsStore((state) => state.favoritesNote)
    const setFavoritesNote = useFavoriteActionsStore((state) => state.setFavoritesNote)
    const setUpdatedNote = useFavoriteActionsStore((state) => state.setUpdatedNote)
    const setActiveFavoriteAction = useFavoriteActionsStore(
        (state) => state.setActiveFavoriteAction
    )
    const setAllFavoritesBoxChecked = useFavoriteActionsStore(
        (state) => state.setAllFavoritesBoxChecked
    )
    const setActiveFavoriteItemState = useFavoriteActionsStore(
        (state) => state.setActiveFavoriteItemState
    )

    const setLoginGateActive = useLoginGateStore((state) => state.setLoginGateActive)
    const setUserAction = useLoginGateStore((state) => state.setUserAction)

    const user = userStore((state) => state.user)

    const [isHover, toggleHover] = React.useState<boolean>(false)
    const [isFavorite, setIsFavorite] = React.useState<boolean>()
    const [isActive, setIsActive] = React.useState<boolean>(false)
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false)

    React.useEffect(() => {
        const isFav = isFavorited(item?.id, type)
        setIsFavorite(isFav)
    }, [favoriteLocationIds, favoriteProductIds, favoriteBrandIds])
    const toast = useToast()

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShouldUpdateAccountStore(shouldUpdateAccountStore)
        setActiveFavoriteAction(isFavorite ? 'remove' : 'add')
        setActiveFavoriteItem(item)
        setActiveFavoriteType(type as unknown as BoardItemType)
        setIsActive(!isActive)
        e.stopPropagation()
        e.preventDefault()
        if (user) {
            setButtonLoading(true)
            if (!isFavorite) {
                setUpdatedNote('')
                setFavoritesNote('')
                const success = await toggleFavorite(
                    type,
                    item?.id,
                    (item as Location)?.brand?.id,
                    shouldUpdateAccountStore
                )
                if (success) {
                    toast({
                        ...savedItemToastConfig,
                        render: () => <SavedItemToast item={item} />,
                    })
                    setAllFavoritesBoxChecked(true)
                    setActiveFavoriteItemState(ActiveFavoriteItemState.Active)
                }
            } else {
                setActiveFavoriteAction('edit')
                setFavoriteModalOpen(true)
            }
            setButtonLoading(false)
        } else {
            setLoginGateActive(true)
            setUserAction('Add Favorite')
        }
    }

    return (
        <CC.Button
            onMouseEnter={() => toggleHover(true)}
            onMouseLeave={() => toggleHover(false)}
            border='none'
            style={{ background: 'transparent' }}
            size={heartSize}
            className={classNames(
                'bg-none p-0 text-dark',
                isActive ? 'custom-animation-favorite-button' : ''
            )}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClick(e)}
            aria-label={ariaLabel}
            isLoading={buttonLoading}
            gap={1}
        >
            <CC.Icon
                as={isHover || isFavorite ? HeroIconsSolid.HeartIcon : HeroIconsOutline.HeartIcon}
                fontSize={'1.5rem'}
            />
            {hasText && <p className='hidden text-sm md:flex'>Favorite</p>}
        </CC.Button>
    )
}

export default FavoritesButton
