import React, { useState } from 'react'
import { Button, Icon, Drawer } from '@brewlogix/blx-ui'
import { useRouter } from 'next/router'
import { userStore } from 'src/features/auth/state/userStore'
import classNames from 'classnames'
import Link from 'next/link'
import Script from 'next/script'
import Menu from './Menu'
import { AccountAvatar } from './AccountAvatar'
import * as HeroSolid from '@heroicons/react/solid'
import * as HeroOutline from '@heroicons/react/outline'
import * as CC from '@chakra-ui/react'

interface IHeader {
    customClasses?: string
    brand: {
        title: string
        url: string
        asset: string
    }
    onClickSignOut?: () => void
}

interface IWindow extends Window {
    Olvy?: (slug: string) => void
}

export const Header: React.FC<IHeader> = ({ customClasses = '', brand }) => {
    const [hamburgDrawerOpen, setHamburgDrawerOpen] = useState(false)
    const [showReleaseNotesButton, setShowReleaseNotesButton] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const router = useRouter()
    const { user, role, userIsLoggedIn } = userStore()

    React.useEffect(() => {
        setLoggedIn(userIsLoggedIn)
    }, [userIsLoggedIn])

    const loadOlvy = () => {
        const win: IWindow = window
        if (win.Olvy) {
            new win.Olvy('brewerydb')
            setShowReleaseNotesButton(true)
        }
    }

    const renderMenuList = (device: 'mobile' | 'tablet' | 'desktop') => (
        <nav
            className={classNames('bdb-menu-bar--left__items', {
                [`bdb-menu-bar--left__items-${device}`]: device,
            })}
            id='header-nav'
            role='navigation'
            aria-label='Main'
        >
            <div
                className={classNames(
                    'menu-item__container menu-item__container--breweries py-0',
                    router.pathname === '/map' && 'active'
                )}
                onClick={() => setHamburgDrawerOpen(false)}
            >
                <Menu
                    id='bdb-menu-bar--left__items-locations'
                    customClasses='bdb-menu-bar--left__items-menu font-lato text-gray-500 font-medium'
                    label='Breweries'
                    href='/map?option=breweries-for-you'
                    icon='HeroIcons.Map'
                />
            </div>
            <div
                className={classNames(
                    'menu-item__container menu-item__container--brews py-0',
                    router.pathname === '/brewknowledge' && 'active'
                )}
                onClick={() => setHamburgDrawerOpen(false)}
            >
                <Menu
                    id='bdb-menu-bar--left__items-products'
                    customClasses='bdb-menu-bar--left__items-menu font-lato text-gray-500 font-medium'
                    label='Brews'
                    href='/brewknowledge'
                    icon='Pint'
                />
            </div>
            <div
                className={classNames(
                    'menu-item__container menu-item__container--brews py-0',
                    router.pathname === '/curated-boards' && 'active'
                )}
                onClick={() => setHamburgDrawerOpen(false)}
            >
                <Menu
                    id='bdb-menu-bar--left__items-products'
                    customClasses='whitespace-nowrap bdb-menu-bar--left__items-menu font-lato text-gray-500 font-medium'
                    label='Curated Boards'
                    href='/curated-boards'
                    icon='HeroIcons.ViewGrid'
                />
            </div>
        </nav>
    )
    const pathClass =
        router.pathname.replace('/', '') === '' ? 'home' : router.pathname.replace('/', '')

    return (
        <>
            <Script
                id='olvy-widget'
                strategy='afterInteractive'
                src='https://app.olvy.co/scriptV2.js'
                defer
                onReady={() => loadOlvy()}
            />
            <div
                id='bdb-menu-bar'
                className={classNames(
                    `bdb-menu-bar bdb-menu-bar--${pathClass}`,
                    customClasses,
                    'mx-auto flex h-12 items-center justify-between lg:h-24'
                )}
            >
                <div className='bdb-menu-bar--left space-x-3 lg:space-x-4'>
                    {
                        <CC.Button
                            className='ml-1 flex items-center p-1 text-dark lg:hidden'
                            variant='link'
                            onClick={() => setHamburgDrawerOpen(true)}
                            aria-label='Open Menu'
                        >
                            <CC.Icon as={HeroSolid.MenuAlt4Icon} className='text-2xl' />
                        </CC.Button>
                    }
                    <div className='bdb-menu-bar--left__brand'>
                        <Link href='/' passHref>
                            <a className='bdb-menu-bar--left__brand-title'>
                                <span className={brand.asset ? 'sr-only' : ''}>{brand.title}</span>
                                {Object.entries(brand).map((value, key) =>
                                    value[0] === 'asset' ? (
                                        <img
                                            src={String(value[1])}
                                            className='bdb-menu-bar--left__brand-img w-[120px] lg:w-full'
                                            alt={`${brand.title} logo`}
                                            key={key}
                                        />
                                    ) : null
                                )}
                            </a>
                        </Link>
                    </div>
                    {renderMenuList('desktop')}
                </div>
                <div className='bdb-menu-bar--right'>
                    <Link href='/search'>
                        <a>
                            <CC.Button variant='darkLink'>
                                <CC.Icon as={HeroSolid.SearchIcon} className='text-xl' />
                            </CC.Button>
                        </a>
                    </Link>
                    <CC.Button
                        id='olvy-whats-new'
                        variant='darkLink'
                        disabled={!showReleaseNotesButton}
                    >
                        <CC.Icon as={HeroOutline.LightBulbIcon} className='text-2xl' />
                        <div className='sr-only'>Whats New</div>
                    </CC.Button>
                    {loggedIn ? (
                        <Link href='/saved'>
                            <a>
                                <CC.Button variant='darkLink'>
                                    <CC.Icon as={HeroOutline.HeartIcon} className='text-xl' />
                                </CC.Button>
                            </a>
                        </Link>
                    ) : (
                        <Link href='/login'>
                            <a>
                                <CC.Button variant='darkLink'>
                                    <CC.Icon as={HeroOutline.HeartIcon} className='text-xl' />
                                </CC.Button>
                            </a>
                        </Link>
                    )}

                    {loggedIn ? (
                        <AccountAvatar
                            user={user}
                            role={role}
                            size='sm'
                            customClasses='hover:cursor-pointer ml-3'
                            onClick={() => {
                                router.push('/account')
                            }}
                        />
                    ) : (
                        <Link href='/login'>
                            <a>
                                <CC.Button variant='darkLink'>
                                    <CC.Icon as={HeroOutline.UserCircleIcon} className='text-2xl' />
                                    <div className='sr-only'>Log In</div>
                                </CC.Button>
                            </a>
                        </Link>
                    )}
                    {
                        <Drawer
                            id='bdb-hamburg-drawer'
                            isOpen={hamburgDrawerOpen}
                            anchor='left'
                            customClasses='flex justify-center pt-6'
                            onClose={() => setHamburgDrawerOpen(false)}
                        >
                            <Button
                                label='Close'
                                icon='HeroIcons.X'
                                theme='link'
                                size='sm'
                                customClasses='text-dark absolute top-1 right-1'
                                hiddenText={true}
                                onClick={() => setHamburgDrawerOpen(false)}
                            />
                            {renderMenuList('mobile')}
                        </Drawer>
                    }
                </div>
            </div>
        </>
    )
}

export default Header
