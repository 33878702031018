import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import RegistrationFormStep from '../types/RegistrationFormStep'

interface IUserFeedback {
    message: string
    type: 'success' | 'error' | 'info' | 'warning'
}

interface ILoginGateStore {
    loginGateActive: boolean
    setLoginGateActive: (loginGateActive: boolean) => void
    userAction: 'Save Route' | 'Add Favorite'
    setUserAction: (userAction: 'Save Route' | 'Add Favorite') => void
    createUserFlowActive: boolean
    setCreateUserFlowActive: (createUserFlowActive: boolean) => void
    userFeedback: IUserFeedback
    setUserFeedback: (userFeedback: IUserFeedback) => void
    registerFormStep: RegistrationFormStep
    setRegisterFormStep: (registerFormStep: RegistrationFormStep) => void
}

export const useLoginGateStore = create<ILoginGateStore>()(
    devtools(
        (set) => ({
            loginGateActive: false,
            setLoginGateActive: (loginGateActive) => set({ loginGateActive }),
            userAction: null,
            setUserAction: (userAction) => set({ userAction }),
            createUserFlowActive: false,
            setCreateUserFlowActive: (createUserFlowActive) => set({ createUserFlowActive }),
            userFeedback: null,
            setUserFeedback: (userFeedback) => set({ userFeedback }),
            registerFormStep: null,
            setRegisterFormStep: (registerFormStep) => set({ registerFormStep }),
        }),
        { name: 'useLoginGateStore' }
    )
)
