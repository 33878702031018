export const deleteAuthenticatedUserMutation = /* GraphQL */ `mutation DeleteAuthenticatedUser {
    deleteAuthenticatedUser {
        id
        createdDate
        updatedDate
        createdBy
        lastUpdatedBy
        slug
        givenName
        familyName
        contact {
            phone
            email
        }
        address {
            street
            street2
            city
            state
            country
            postalCode
            geo {
                lat
                lon
                title
            }
        }
        ppSubscriptions {
            ppSubId
            role
            createdDate
            updatedDate
            createdBy
            lastUpdatedBy
        }
        mmbBrands {
            brandId
            role
            createdDate
            updatedDate
            createdBy
            lastUpdatedBy
        }
        description
        contactPreference
        defaultSubscription
        jobTitle
        bdbAccess
        brewlogixSuperadmin
        meta
        favorites {
            brands
            locations {
                brandId
                locationId
            }
            products {
                brandId
                productId
            }
        }
        routes {
            id
            routeId
            title
            description
            distance
            duration
            coordinates {
                lat
                lon
                title
            }
            createdDate
            updatedDate
            createdBy
            lastUpdatedBy
        }
    }
}`
