import React from 'react'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'

interface LoginFormWrapperProps {
    children: React.ReactNode
    onSubmit: () => Promise<unknown>
}

const LoginFormWrapper: React.FC<LoginFormWrapperProps> = ({ children, onSubmit }) => {
    const formRef = React.useRef<HTMLFormElement>()
    const setStatus = loginFormStore((state) => state.setStatus)

    const handleSubmit = async () => {
        setStatus('loading')
        const valid = formRef.current.checkValidity()
        if (!valid) {
            setStatus('ready')
            return
        }
        await onSubmit()
    }

    return (
        <form
            ref={formRef}
            onSubmit={(e) => {
                e.preventDefault()
                handleSubmit()
            }}
        >
            {children}
        </form>
    )
}

export default LoginFormWrapper
