import { useFavoriteActionsStore } from '../../state/useFavoriteActionsStore'

const activeFavoriteAction = useFavoriteActionsStore.getState().activeFavoriteAction
export const getFavoriteModalHeader = () => {
    switch (activeFavoriteAction) {
        case 'add':
            return 'Save to Board'
        case 'remove':
            return 'Remove from Board'
        case 'edit':
            return 'Edit Saved Item'
        default:
            return 'Saved to Board'
    }
}
