import { useLoginGateStore } from '../state/useLoginGateStore'

const setLoginGateActive = useLoginGateStore.getState().setLoginGateActive
const setCreateUserFlowActive = useLoginGateStore.getState().setCreateUserFlowActive
const loginGateActive = useLoginGateStore.getState().loginGateActive

export const loginGateConfig = {
    loginGateActive,
    createUserFlowActive: false,
    userAction: 'Save Route',
    onModalClose: () => {
        setLoginGateActive(false)
        setCreateUserFlowActive(false)
    },
}
