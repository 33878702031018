import { gql } from 'graphql-request'

export const addItemToBoardMutation = gql`mutation AddItemToBoard($input: inputBoardItem!) {
	addItemToBoard(input: $input){
		updatedDate
		title
		lastUpdatedBy
		id
		createdDate
		createdBy
		favorites {
			favoriteBrands {
				brandId
				isActive
				notes
			}
			favoriteLocations {
				locationId
				isActive
				brandId
				notes
			}
			favoriteProducts {
				brandId
				isActive
				notes
				productId
			}
		}
		routes {
			createdBy
			createdDate
			description
			distance
			duration
			id
			lastUpdatedBy
			notes
			routeId
			title
			updatedDate
		}
	}
}`
