/**
 * parseProfileIcon()
 *
 * Takes mapbox procile and returns the correct icon slug from BLX UI
 *
 * @param {string} profile
 * @returns {string}
 */
const parseProfileIcon = (profile: string) => {
    switch (profile) {
        case 'driving':
            return 'Car'
        case 'cycling':
            return 'Bike'
        case 'walking':
            return 'Walk'
        default:
            break
    }
}
export { parseProfileIcon }
