import mapboxgl from 'mapbox-gl'

export const addStateSourceLayers = (mapRef: mapboxgl.Map) => {
    const stateSource: mapboxgl.GeoJSONSourceRaw = {
        type: 'geojson',
        data: 'https://docs.mapbox.com/mapbox-gl-js/assets/us_states.geojson',
    }
    if (!mapRef?.getSource('states')) {
        mapRef?.addSource('states', stateSource)
    }
    if (!mapRef?.getLayer('state-fills')) {
        mapRef?.addLayer({
            id: 'state-fills',
            type: 'fill',
            source: 'states',
            layout: {},
            maxzoom: 6,
            paint: {
                'fill-color': 'rgba(93, 129, 14, .15)',
                'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
            },
        })
    }
    if (!mapRef?.getLayer('state-borders')) {
        mapRef?.addLayer({
            id: 'state-borders',
            type: 'line',
            source: 'states',
            layout: {},
            maxzoom: 6,
            paint: {
                'line-color': '#5D810E',
                'line-width': 1,
                'line-opacity': 1,
            },
        })
    }
    if (!mapRef?.getLayer('state-border-click')) {
        mapRef?.addLayer({
            id: 'state-border-click',
            type: 'line',
            source: 'states',
            layout: {},
            paint: {
                'line-color': '#5D810E',
                'line-width': 1,
                'line-opacity': ['case', ['boolean', ['feature-state', 'click'], false], 1, 0],
            },
        })
    }
    let hoveredStateId: string | number = null
    mapRef?.on('mousemove', 'state-fills', (e) => {
        if (e.features.length > 0) {
            if (hoveredStateId !== null) {
                mapRef.setFeatureState({ source: 'states', id: hoveredStateId }, { hover: false })
                mapRef.getCanvas().style.cursor = 'default'
            }
            const feature: mapboxgl.MapboxGeoJSONFeature = e.features[0]
            mapRef.getCanvas().style.cursor = 'pointer'
            hoveredStateId = feature.id
            mapRef.setFeatureState({ source: 'states', id: hoveredStateId }, { hover: true })
        }
    })
    mapRef?.on('mouseleave', 'state-fills', () => {
        if (hoveredStateId !== null) {
            mapRef.setFeatureState({ source: 'states', id: hoveredStateId }, { hover: false })
        }
        hoveredStateId = null
    })
}
