import {
    FavoriteBrand,
    FavoriteLocation,
    FavoriteProduct,
    InputFavoriteBrand,
    InputFavoriteLocation,
    InputFavoriteProduct,
} from 'src/types/schema/graphql'
import { useFavoriteActionsStore } from '../../state/useFavoriteActionsStore'
import { FavoriteType } from '../../types/enums/FavoriteType'
import { useSavedBoardsStore } from '../../state/useSavedBoardsStore'

export const getNote = (
    id: string,
    favoriteType: FavoriteType,
    getFromFavoriteActionStore: boolean = false
) => {
    let favoriteLocationIds: FavoriteLocation[]
    let favoriteProductIds: FavoriteProduct[]
    let favoriteBrandIds: FavoriteBrand[]

    if (getFromFavoriteActionStore) {
        favoriteLocationIds = useFavoriteActionsStore.getState().favoriteLocationIds
        favoriteProductIds = useFavoriteActionsStore.getState().favoriteProductIds
        favoriteBrandIds = useFavoriteActionsStore.getState().favoriteBrandIds
    } else {
        favoriteLocationIds = useSavedBoardsStore.getState().sharedLocationIds
        favoriteProductIds = useSavedBoardsStore.getState().sharedProductIds
        favoriteBrandIds = useSavedBoardsStore.getState().sharedBrandIds
    }

    switch (favoriteType) {
        case FavoriteType.Location:
            const favoriteLocation: InputFavoriteLocation = favoriteLocationIds?.find(
                (loc) => loc.locationId === id
            )
            if (favoriteLocation) {
                return favoriteLocation?.notes
            }
            return ''
        case FavoriteType.Product:
            const favoriteProduct: InputFavoriteProduct = favoriteProductIds?.find(
                (product) => product.productId === id
            )
            if (favoriteProduct) {
                return favoriteProduct?.notes
            }
            return ''
        case FavoriteType.Brand:
            const favoriteBrand: InputFavoriteBrand = favoriteBrandIds?.find(
                (brand) => brand.brandId === id
            )
            if (favoriteBrand) {
                return favoriteBrand?.notes
            }
            return ''
    }
}
