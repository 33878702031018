import classNames from 'classnames'
import React from 'react'
import checkPasswordRequirements from 'src/features/auth/utils/helpers/checkPasswordRequirements'
import { passwordRequirementLabels, passwordStrengthMapping } from './config'
import PasswordCheckItem from './PasswordCheckItem'

interface PasswordCheckerProps {
    password: string
}

const PasswordChecker: React.FC<PasswordCheckerProps> = ({ password }) => {
    const { requirements, message, strength } = checkPasswordRequirements(password)
    const strengthMeter = Object.values(requirements).sort((a, b) => Number(b) - Number(a))

    return (
        <div className='absolute top-[-15.75rem] z-20 flex h-fit w-full min-w-full flex-col gap-4 rounded bg-gray-50 px-5 py-4 shadow-xl lg:-top-16 lg:left-[calc(100%+1rem)] '>
            <div className='absolute -bottom-2 left-[calc(50%-0.5rem)] h-4 w-4 rotate-45  transform  bg-gray-50 shadow-lg lg:-left-2 lg:bottom-auto lg:top-1/2'></div>

            <div className='flex flex-col gap-3'>
                <span className='text-sm font-bold text-gray-400'>{message}</span>
                <div className='flex justify-between gap-2'>
                    {strengthMeter.map((val, index) => (
                        <div
                            key={index}
                            className={classNames(
                                'h-1 w-full rounded-3xl',
                                'transition-color duration-200 ease-linear',
                                val ? passwordStrengthMapping[strength] : 'bg-gray-100'
                            )}
                        />
                    ))}
                </div>
            </div>

            <div className='flex flex-col gap-1'>
                <span className='text-sm font-semibold text-gray-400'>
                    Passwords should include:
                </span>
                {passwordRequirementLabels.map((val) => (
                    <PasswordCheckItem
                        key={val.key}
                        title={val.title}
                        valid={requirements[val.key]}
                    />
                ))}
            </div>
        </div>
    )
}

export default PasswordChecker
