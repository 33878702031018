import React from 'react'
import * as CC from '@chakra-ui/react'
import { useLoginGateStore } from '../state/useLoginGateStore'
import { loginGateConfig } from '../config/loginGateConfig'
import { AnimatePresence } from 'framer-motion'
import { ModalRegisterForm, ModalFormHeader, ModalLoginForm } from './forms'
import FormHeaderVariant from '../types/FormHeaderVariant'

const LoginGate: React.FC = () => {
    const createUserFlowActive = useLoginGateStore((state) => state.createUserFlowActive)
    const loginGateActive = useLoginGateStore((state) => state.loginGateActive)
    const registerFormStep = useLoginGateStore((state) => state.registerFormStep)

    return (
        <CC.Modal
            isOpen={loginGateActive}
            onClose={loginGateConfig.onModalClose}
            isCentered={true}
            size='md'
        >
            <CC.ModalOverlay />
            <CC.ModalContent>
                <CC.ModalHeader p={0}>
                    <ModalFormHeader
                        variant={
                            createUserFlowActive
                                ? registerFormStep !== 'registerVerify'
                                    ? FormHeaderVariant.Register
                                    : FormHeaderVariant.RegisterVerify
                                : FormHeaderVariant.Login
                        }
                    />
                </CC.ModalHeader>
                <CC.ModalCloseButton className='text-white' />
                <CC.ModalBody p={6}>
                    <AnimatePresence>
                        {createUserFlowActive ? <ModalRegisterForm /> : <ModalLoginForm />}
                    </AnimatePresence>
                </CC.ModalBody>
            </CC.ModalContent>
        </CC.Modal>
    )
}
export default LoginGate
