import React from 'react'
import * as CC from '@chakra-ui/react'
import * as Hero from '@heroicons/react/solid'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'
import PasswordChecker from '../partials/password/PasswordChecker'
import checkPasswordRequirements from 'src/features/auth/utils/helpers/checkPasswordRequirements'
import useOutsideClick from 'src/hooks/useOutsideClick'
import Link from 'next/link'
import FieldWrapper from './FieldWrapper'
import FieldLabel from './FieldLabel'

interface PasswordFieldProps {
    action: 'enter' | 'mutate'
    format?: 'icon' | 'label'
}

const Password: React.FC<PasswordFieldProps> = ({ action, format = 'label' }) => {
    const password = loginFormStore((state) => state?.password)
    const status = loginFormStore((state) => state.status)
    const setPasswordStrength = loginFormStore((state) => state?.setPasswordStrength)
    const showPassword = loginFormStore((state) => state?.showPassword)
    const showPasswordRequirements = loginFormStore((state) => state?.showPasswordRequirements)
    const togglePasswordVisibility = loginFormStore((state) => state.togglePasswordVisibility)
    const togglePasswordRequirements = loginFormStore((state) => state.togglePasswordRequirements)
    const updateField = loginFormStore((state) => state.updateField)
    const passwordRef = React.useRef<HTMLInputElement>()
    const passwordBoxRef = React.useRef<HTMLDivElement>()

    useOutsideClick(passwordBoxRef, () => togglePasswordRequirements(false))

    const handlePasswordChange = (value: string) => {
        updateField('password', value)
        if (action === 'mutate') setPasswordStrength(checkPasswordRequirements(value)?.strength)
    }

    const passwordFieldConfig: CC.InputProps = {
        placeholder: '••••••••••',
        autoComplete: action === 'mutate' ? 'new-password' : 'current-password',
        onFocus: action === 'mutate' ? () => togglePasswordRequirements(true) : null,
        paddingInlineStart: format === 'icon' ? 'var(--chakra-space-10)' : null,
    }

    return (
        <div className='relative z-40' ref={passwordBoxRef}>
            <FieldWrapper format={format}>
                <FieldLabel format={format} icon='LockClosedIcon'>
                    Password
                </FieldLabel>

                <CC.Input
                    type={showPassword ? 'text' : 'password'}
                    className='border-gray-100 focus:border-dark'
                    id='password'
                    value={password}
                    onChange={({ target }) => handlePasswordChange(target.value)}
                    required
                    readOnly={status === 'loading'}
                    ref={passwordRef}
                    {...passwordFieldConfig}
                />
                <CC.InputRightElement>
                    <CC.Icon
                        as={showPassword ? Hero.EyeIcon : Hero.EyeOffIcon}
                        boxSize={6}
                        p={0.5}
                        mt={format === 'label' ? '3.625rem' : null}
                        cursor='pointer'
                        color='gray.300'
                        onClick={togglePasswordVisibility}
                    />
                </CC.InputRightElement>
            </FieldWrapper>

            {showPasswordRequirements && <PasswordChecker password={password} />}
            {action === 'enter' && (
                <div className='mt-2 text-right'>
                    <Link href='/login/forgot-password'>
                        <a className='py-1 text-sm font-semibold text-info transition hover:text-black'>
                            Forgot Password?
                        </a>
                    </Link>
                </div>
            )}
        </div>
    )
}

export default Password
