import React from 'react'
import * as UI from '@brewlogix/blx-ui'
import { Asset } from 'src/types/schema/graphql'
import { getAssetPath } from 'src/util/helpers'
import classNames from 'classnames'
import beerDefault from './../../../../../public/assets/fallbacks/beer-fallback.svg'
import Image from 'next/image'

interface ICardImage {
    asset: Asset
    alt: string
    customClasses?: string
}

const CardImage: React.FC<ICardImage> = ({ asset, alt, customClasses }) => {
    return asset?.type !== 'fallback' ? (
        <UI.Media
            asset={asset}
            customWrapperClasses={classNames(
                customClasses ? customClasses : 'rounded-xl',
                'flex align-center'
            )}
            height={300}
            width={300}
            alt={alt}
        />
    ) : (
        <div className=' relative h-full w-full'>
            <Image
                src={asset?.path}
                alt={alt}
                className={classNames(
                    customClasses ? customClasses : 'rounded-xl',
                    'align-center flex h-full'
                )}
                layout='fill'
            />
        </div>
    )
}
export default CardImage
