import { apiRequest, RequestInput } from 'src/api/apiRequest'
import { BreweryRoute, User } from 'src/types/schema/graphql'
import getSavedUserRoutesQuery from '../queries/getSavedUserRoutesQuery'

export const getSavedUserRoutes = async (): Promise<BreweryRoute[]> => {
    const input: RequestInput<unknown> = {
        query: getSavedUserRoutesQuery,
        args: {
            input: {},
        },
        file: 'fetchUser.ts',
        endpoint: 'getAuthenticatedUserQuery',
    }
    const { data } = await apiRequest<User, unknown>(input)

    return data?.routes
}
