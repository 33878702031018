import BadgeConfig from './components/badgeConfig'
import { BoxConfig } from './components/boxConfig'
import ButtonConfig from './components/buttonConfig'
import CardConfig from './components/cardConfig'
import { ComponentStyleConfig } from '@chakra-ui/react'
import DrawerConfig from './components/drawerConfig'
import HeadingConfig from './components/headingConfig'
import InputConfig from './components/inputConfig'
import ModalConfig from './components/modalConfig'
import SelectConfig from './components/selectConfig'
import TabsConfig from './components/tabsConfig'
import AlertConfig from './components/alertConfig'
import MenuConfig from './components/menuConfig'
import CheckboxConfig from './components/checkboxConfig'

export enum ComponentConfigurations {
    Button = 'ButtonConfig',
}

const componentConfig: { [key: string]: ComponentStyleConfig } = {
    Badge: BadgeConfig,
    Box: BoxConfig,
    Button: ButtonConfig,
    Modal: ModalConfig,
    Heading: HeadingConfig,
    Input: InputConfig,
    Drawer: DrawerConfig,
    Tabs: TabsConfig,
    Card: CardConfig,
    Select: SelectConfig,
    Alert: AlertConfig,
    Menu: MenuConfig,
    Checkbox: CheckboxConfig,
}

export default componentConfig
