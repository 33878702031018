import React from 'react'
import * as CC from '@chakra-ui/react'
import SavedBoardListCard from './SavedBoardListCard'
import { useSavedBoardsStore } from '../state/useSavedBoardsStore'
import AllFavoritesCard from './AllFavoritesCard'

interface ISavedBoardsList {}

const SavedBoardsList: React.FC<ISavedBoardsList> = () => {
    const boards = useSavedBoardsStore((state) => state.boards)
    return (
        <CC.VStack
            spacing={4}
            w='full'
            maxH={boards.length > 2 ? '258px' : 'unset'}
            overflowY='auto'
            pr={boards.length > 2 ? 6 : 0}
            pb={4}
        >
            <AllFavoritesCard />
            {boards.map((board) => {
                const activeFavoriteProducts = board?.favorites?.favoriteProducts?.filter(
                    (product) => product?.isActive
                )
                const activeFavoriteLocations = board?.favorites?.favoriteLocations?.filter(
                    (location) => location?.isActive
                )
                const activeFavoriteBrands = board?.favorites?.favoriteBrands?.filter(
                    (brand) => brand?.isActive
                )
                const activeFavoriteRoutes = board?.routes?.filter((route) => route?.isActive)
                return (
                    board && (
                        <SavedBoardListCard
                            key={`${board?.id}`}
                            id={board?.id}
                            title={board?.title}
                            favorites={board?.favorites}
                            route={board?.routes?.[0]}
                            counts={{
                                routes: activeFavoriteRoutes?.length,
                                favoriteLocations:
                                    activeFavoriteLocations?.length + activeFavoriteBrands?.length,
                                favoriteProducts: activeFavoriteProducts?.length,
                            }}
                        />
                    )
                )
            })}
        </CC.VStack>
    )
}
export default SavedBoardsList
