import { apiRequest, RequestInput } from 'src/api/apiRequest'
import { useAlerts } from 'src/features/alerts/state/useAlerts'
import { useSavedBoardsStore } from 'src/features/favorites/state/useSavedBoardsStore'
import {
    BreweryRoute,
    BreweryRouteGeoCoord,
    InputBreweryRouteGeoCoordInput,
    InputCreateBreweryRoute,
    InputDeleteBreweryRoute,
    InputUpdateBreweryRoute,
    MutationDeleteBreweryRouteArgs,
    MutationUpdateBreweryRouteArgs,
} from 'src/types/schema/graphql'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { IMapboxJson } from '../types'
import { getSavedUserRoutes } from '../utils/functions/getSavedUserRoutes'
import {
    createBreweryRouteMutation,
    deleteBreweryRouteMutation,
    updateBreweryRouteMutation,
} from '../utils/queries'
import { mapStore } from './mapStore'
interface IUseSavedRoutesStore {
    savedRouteModalOpen: boolean
    setSavedRouteModalOpen: (savedRouteModalOpen: boolean) => void
    deleteRouteModalOpen: boolean
    setDeleteRouteModalOpen: (deleteRouteModalOpen: boolean) => void
    title: string
    setTitle: (title: string) => void
    description: string
    setDescription: (description: string) => void
    createBreweryRoute: (
        directionCoordinates: BreweryRouteGeoCoord[],
        routeId: string,
        distance: number,
        duration: number,
        description?: string
    ) => Promise<BreweryRoute>
    updateBreweryRoute: (
        activeSavedRoute: BreweryRoute,
        isActive: boolean,
        coordinates: InputBreweryRouteGeoCoordInput[],
        title: string,
        description: string,
        shouldPopulateBoardDetailPage?: boolean
    ) => Promise<boolean>
    deleteBreweryRoute: (routeId: string) => Promise<boolean>
    currentRoute: IMapboxJson
    setCurrentRoute: (currentRoute: IMapboxJson) => void
    populateRoutes: () => Promise<void>
    parsedRoutes: BreweryRoute[]
    setParsedRoutes: (parsedRoutes: BreweryRoute[]) => void
    routeCardsLoaded: boolean
    setRouteCardsLoaded: (routeCardsLoaded: boolean) => void
    activeSavedRoute: BreweryRoute
    setActiveSavedRoute: (activeSavedRoute: any) => void
}

export const useSavedRoutesStore = create<IUseSavedRoutesStore>()(
    devtools(
        (set, get) => ({
            savedRouteModalOpen: false,
            setSavedRouteModalOpen: (savedRouteModalOpen) => set({ savedRouteModalOpen }),
            deleteRouteModalOpen: false,
            setDeleteRouteModalOpen: (deleteRouteModalOpen) => set({ deleteRouteModalOpen }),
            title: '',
            setTitle: (title) => set({ title }),
            description: '',
            setDescription: (description) => set({ description }),
            activeSavedRoute: null,
            setActiveSavedRoute: (activeSavedRoute) => set({ activeSavedRoute }),
            createBreweryRoute: async (
                directionCoordinates,
                routeId,
                distance,
                duration,
                description = ''
            ) => {
                const routeTitle = `${directionCoordinates?.[0]?.title} to ${
                    directionCoordinates[directionCoordinates.length - 1]?.title
                }`
                get().setTitle(routeTitle)
                const input: RequestInput<InputCreateBreweryRoute> = {
                    query: createBreweryRouteMutation,
                    args: {
                        input: {
                            routeId,
                            title: routeTitle,
                            description,
                            distance,
                            duration,
                            coordinates: directionCoordinates.map(
                                ({ lat, lon, title, placeName, location }) => ({
                                    lat,
                                    lon,
                                    title,
                                    placeName,
                                    location: location?.id
                                        ? {
                                              brandId: location?.brand?.id,
                                              locationId: location?.id,
                                          }
                                        : null,
                                })
                            ),
                        },
                    },
                    file: 'useSavedRoutesStore.ts',
                    endpoint: 'createBreweryRoute',
                    publicQuery: false,
                    noAlerts: false,
                }
                const { success, error, data } = await apiRequest<
                    BreweryRoute,
                    InputCreateBreweryRoute
                >(input)
                if (data) get().setActiveSavedRoute(data)
                if (success) return data

                if (error) {
                    useAlerts.getState().addAlert({
                        id: 'errorCreateBreweryRoute',
                        title: 'Route Saved',
                        description: 'Brewery route failed to save',
                        theme: 'error',
                        status: 'active',
                        duration: 6,
                        icon: 'HeroIcons.ExclamationCircle',
                    })
                    return null
                }
            },
            updateBreweryRoute: async (
                activeSavedRoute,
                isActive = true,
                coordinates,
                title = '',
                description = '',
                shouldPopulateBoardDetailPage = true
            ) => {
                if (!activeSavedRoute) return false
                const { routeId, id, distance, duration } = activeSavedRoute
                const input: RequestInput<InputUpdateBreweryRoute> = {
                    query: updateBreweryRouteMutation,
                    args: {
                        input: {
                            id,
                            isActive,
                            routeId,
                            title,
                            description,
                            distance,
                            duration,
                            coordinates,
                        },
                    },
                    file: 'useSavedRoutesStore.ts',
                    endpoint: 'udpateBreweryRoute',
                    publicQuery: false,
                    noAlerts: false,
                }
                const { success, error, data } = await apiRequest<
                    MutationUpdateBreweryRouteArgs,
                    InputUpdateBreweryRoute
                >(input)
                if (data) get().setActiveSavedRoute(data)
                if (success) {
                    if (shouldPopulateBoardDetailPage)
                        useSavedBoardsStore
                            .getState()
                            .populateBoardDetailPage(
                                useSavedBoardsStore.getState().boardDetailPageData
                            )
                    useAlerts.getState().addAlert({
                        id: 'updateBreweryRoute',
                        title: 'Brewery route updated',
                        theme: 'default',
                        duration: 6,
                    })
                    return true
                }

                if (error) {
                    useAlerts.getState().addAlert({
                        id: 'errorUpdateBreweryRoute',
                        title: 'Route Saved',
                        description: 'Brewery route failed to update',
                        theme: 'default',
                        status: 'active',
                        duration: 6,
                    })
                    return false
                }
            },
            deleteBreweryRoute: async (id: string) => {
                const input = {
                    query: deleteBreweryRouteMutation,
                    args: {
                        input: {
                            id,
                        },
                    },
                    file: 'useSavedRoutesStore.ts',
                    endpoint: 'deleteBreweryRoute',
                    publicQuery: false,
                    noAlerts: false,
                }
                const { success, error } = await apiRequest<
                    MutationDeleteBreweryRouteArgs,
                    InputDeleteBreweryRoute
                >(input)
                if (success) {
                    useAlerts.getState().addAlert({
                        id: `deleteBreweryRouteSuccess-${Math.floor(Math.random() * 10000)}`,
                        title: 'Brewery route successfully deleted',
                        theme: 'default',
                        duration: 6,
                    })
                }
                if (error) {
                    useAlerts.getState().addAlert({
                        id: 'deleteBreweryRouteError',
                        title: 'There was an error deleting your route',
                        theme: 'default',
                        duration: 6,
                    })
                }
                return success
            },
            currentRoute: null,
            setCurrentRoute: (currentRoute) => set({ currentRoute }),
            populateRoutes: async () => {
                const userRoutesCopy: BreweryRoute[] = await getSavedUserRoutes()
                const sortedRoutes = [...userRoutesCopy].sort(
                    (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
                )
                set({ parsedRoutes: sortedRoutes })
                set({ routeCardsLoaded: true })
            },
            parsedRoutes: [],
            setParsedRoutes: (parsedRoutes) => set({ parsedRoutes }),
            routeCardsLoaded: false,
            setRouteCardsLoaded: (routeCardsLoaded) => set({ routeCardsLoaded }),
        }),
        { name: 'useSavedRoutesStore' }
    )
)
