import React from 'react'
import MenuItem from './MenuItem'
import useOutsideClick from 'src/hooks/useOutsideClick'
import classNames from 'classnames'

interface IMenu {
    id: string
    customClasses?: string
    customWrapperClasses?: string
    label: string
    onClick?: () => void
    href?: string
    size?: 'sm' | 'md' | 'lg'
    theme?: 'light' | 'dark'
    children?: React.ReactNode
    target?: string
    icon?: string
}

const Menu: React.FC<IMenu> = ({
    id = 'menu',
    customClasses,
    customWrapperClasses,
    label = 'Menu',
    onClick,
    href,
    size,
    theme = 'light',
    children,
    target = '_self',
    icon,
    ...otherProps
}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false)
    const menuRef = React.useRef()

    const handleOpen = () => {
        if (onClick) onClick()
        setIsOpen(!isOpen)
    }

    useOutsideClick(
        menuRef,
        React.useCallback(() => setIsOpen(false), [])
    )
    return (
        <div
            id={id}
            ref={menuRef}
            className={classNames(
                'blx-menu',
                customClasses,
                `blx-menu--${isOpen ? 'open' : 'closed'}`,
                { [`blx-menu--${size}`]: !!size },
                { [`blx-menu--${theme}`]: !!theme }
            )}
            onClick={handleOpen}
            {...otherProps}
        >
            {href ? (
                <MenuItem id={`${id}-menu-item`} href={href} target={target} icon={icon}>
                    {label}
                </MenuItem>
            ) : (
                <div className='blx-menu-label'>{label}</div>
            )}
            {isOpen && children && (
                <div className={classNames('blx-menu-wrapper', customWrapperClasses)}>
                    {children}
                </div>
            )}
        </div>
    )
}
export default Menu
