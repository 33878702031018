import { Auth } from 'aws-amplify'
import { AuthType } from '../types/AuthType'
import { BDBBUserRole } from '../types'
import { CognitoUser } from '../types/CognitoUser'
import { determineInitialRole } from '../utils/helpers/determineInitialRole'
import { devtools, persist } from 'zustand/middleware'
import { fetchUser } from '../utils/helpers/fetchUser'
import { saveAppsyncUser } from '../utils/helpers/saveAppsyncUser'
import { AccountRequest, InputAddress, InputCreateAccountRequest, UpdateUser, User } from 'src/types/schema/graphql'
import { create } from 'zustand'
import { useAlerts } from 'src/features/alerts/state/useAlerts'
import Router from 'next/router'
import { loginFormStore } from './loginFormStore'
import { useLoginGateStore } from './useLoginGateStore'
import { RequestInput, apiRequest } from 'src/api/apiRequest'
import { deleteAuthenticatedUserMutation } from '../utils/graph/deleteAuthenticatedUserMutation'
import { createAccountRequestMutation } from 'src/features/locations/utils/graph/createAccountRequestMutation'

type AuthAttributes = {
    sub: string
    address: string
    email_verified: boolean
    phone_number_verified: boolean
    phone_number: string
    email: string
    given_name: string
    family_name: string
}

type AuthUser = {
    username: string
    attributes: AuthAttributes
}

type UserData = {
    user: User
    auth: AuthType | AuthUser
    role: BDBBUserRole
    activeSubscription: string
    userIsLoggedIn: boolean
    challengeResponse?: CognitoUser
}

interface RequestResetPasswordProps {
    email: string
}
interface VerifyAccountProps extends RequestResetPasswordProps {
    code: string
}

interface AuthorizeProps extends RequestResetPasswordProps {
    password: string
    redirect?: boolean
}

interface ResetPasswordProps extends AuthorizeProps {
    code: string
}

interface RegisterProps extends AuthorizeProps {
    name: string
}

interface IUserStore extends UserData {
    login: (user: CognitoUser) => Promise<void>
    logout: () => Promise<boolean>
    updateUser: (userData: UpdateUser) => Promise<boolean>
    deleteUser: () => Promise<User>
    createAccountRequest: (formData: InputCreateAccountRequest) => Promise<AccountRequest>
    authorize: ({ email, password, redirect }: AuthorizeProps) => Promise<boolean>
    register: ({ email, password, name, redirect }: RegisterProps) => Promise<boolean>
    verifyAccount: ({ email, code }: VerifyAccountProps) => Promise<boolean>
    resendAccountVerificationCode: ({ email }: RequestResetPasswordProps) => Promise<boolean>
    completeNewPassword: (password: string) => Promise<boolean>
    requestResetPassword: ({ email }: RequestResetPasswordProps) => Promise<boolean>
    resetPassword: ({ email, code, password }: ResetPasswordProps) => Promise<boolean>
    updatePassword: (oldPassword: string, newPassword: string) => Promise<boolean>
    deleteAccountModalOpen: boolean
    setDeleteAccountModalOpen: (open: boolean) => void
}

const defaultValues: UserData = {
    user: null,
    auth: null,
    activeSubscription: null,
    role: 'subscriber',
    userIsLoggedIn: false,
}

const userStore = create<IUserStore>()(
    devtools(
        persist(
            (set, get) => ({
                ...defaultValues,
                login: async (user) => {
                    const userId = user.username
                    const appSyncUser = await fetchUser(userId)

                    if (appSyncUser) {
                        set({
                            userIsLoggedIn: true,
                            user: appSyncUser,
                            auth: {
                                username: user?.username,
                                attributes: user?.attributes,
                                accessToken: user?.signInUserSession.accessToken?.jwtToken,
                            },
                            role: determineInitialRole(appSyncUser),
                        })
                        loginFormStore
                            .getState()
                            .loginFormFeedback({ message: 'Signed In', type: 'success' })
                        useLoginGateStore.getState().setLoginGateActive(false)
                        if (loginFormStore.getState().returnPath)
                            Router.push(loginFormStore.getState().returnPath)
                    } else {
                        loginFormStore
                            .getState()
                            .loginFormFeedback({ message: 'User Not Found', type: 'error' })
                    }
                },
                updateUser: async (userData) => {
                    const updatedUserData = {
                        ...get().user,
                        ...userData,
                    }
                    const safeContact = {
                        phone: updatedUserData?.contact?.phone || '',
                        email: updatedUserData?.contact?.email || '',
                    }
                    const userInputData: UpdateUser = {
                        address: updatedUserData?.address as InputAddress,
                        contact: safeContact,
                        givenName: updatedUserData?.givenName || '',
                        familyName: updatedUserData?.familyName || '',
                    }

                    const updateUserInput = userInputData
                    const appSyncUser = await saveAppsyncUser(updateUserInput)
                    if (appSyncUser) {
                        set({
                            user: appSyncUser,
                        })
                        return true
                    } else return false
                },
                deleteUser: async (): Promise<User> => {
                    const input: RequestInput<null> = {
                        query: deleteAuthenticatedUserMutation,
                        args: { input: null },
                        file: 'userStore.ts',
                        endpoint: 'deleteAuthenticatedUser',
                    }
                    const { success, error } = await apiRequest<User, null>(input)
                    if (success) {
                        return success
                    } else {
                        console.log(error)
                    }
                },
                createAccountRequest: async (formData: InputCreateAccountRequest): Promise<AccountRequest> => {
                    const input: RequestInput<InputCreateAccountRequest> = {
                        query: createAccountRequestMutation,
                        args: { input: formData },
                        file: 'createAccountRequestMutation',
                        endpoint: 'createAccountRequest',
                        publicQuery: true,
                    }
                    const { success, error } = await apiRequest<AccountRequest, InputCreateAccountRequest>(input)
                    if (success) {
                        return success
                    } else {
                        console.log(error)
                    }
                },
                logout: async () => {
                    let success = false
                    try {
                        await Auth.signOut()
                        set(defaultValues)
                        Router.push('/login')
                        loginFormStore
                            .getState()
                            .loginFormFeedback({ message: 'Signed Out', type: 'success' })
                        success = true
                    } catch (error) {
                        loginFormStore
                            .getState()
                            .loginFormFeedback({ message: 'Sign Out Failed', type: 'error' })
                        success = false
                    }
                    return success
                },
                authorize: async ({ email, password, redirect }) => {
                    let success = false
                    useAlerts.getState().resetAlerts()
                    try {
                        const cognitoResponse = await Auth.signIn(email, password)
                        if (cognitoResponse?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            set({ challengeResponse: cognitoResponse })
                            loginFormStore.getState().updateField('password', '')
                            Router.push('/login/new-password')
                        } else {
                            await get().login(cognitoResponse)
                        }
                    } catch (error) {
                        loginFormStore.getState().setStatus('error')
                        if (error?.code === 'PasswordResetRequiredException') {
                            loginFormStore.setState({
                                password: '',
                                returnPath: '/',
                                titleOverride: 'Password Expired',
                                descriptionOverride:
                                    'Your password has expired, please enter a new one to log in.',
                            })
                            Router.push('/login/reset-password')
                            await get().requestResetPassword({ email })
                        } else {
                            loginFormStore.getState().loginFormFeedback({
                                message: error.toString().replace(/(.*):/g, ''),
                                type: 'error',
                            })
                        }
                    }
                    return success
                },
                register: async ({ name, email, password, redirect }) => {
                    let success = false
                    const firstName = name.split(' ')[0]
                    const lastName = name.split(' ')[1]
                    try {
                        const registrationResponse = await Auth.signUp({
                            username: email,
                            password: password,
                            attributes: {
                                email,
                                family_name: lastName,
                                given_name: firstName,
                                name,
                                address: '',
                                phone_number: '+10000000000',
                                'custom:meta': null,
                            },
                            autoSignIn: {
                                enabled: true,
                            },
                        })

                        if (registrationResponse) {
                            success = true
                            if (redirect) {
                                loginFormStore.getState().loginFormFeedback({
                                    message: 'Account Created',
                                    type: 'success',
                                })
                                Router.push('/login/verify-account')
                            }
                        }
                    } catch (error) {
                        console.error('Error while registering new user', error)
                        loginFormStore.getState().setStatus('error')
                        loginFormStore
                            .getState()
                            .loginFormFeedback({ message: error.message, type: 'error' })
                    }
                    return success
                },
                verifyAccount: async ({ email, code }) => {
                    let success = false
                    await Auth.confirmSignUp(email, code)
                        .then((res) => {
                            if (res) success = true
                            const email = loginFormStore.getState().email
                            const password = loginFormStore.getState().password
                            if (email && password) {
                                ; (async () => await get().authorize({ email, password }))()
                            } else {
                                loginFormStore.getState().loginFormFeedback({
                                    message: 'Account Verified',
                                    type: 'success',
                                })
                                Router.push('/login')
                            }
                        })
                        .catch((err) => {
                            console.error('Error verifying new user', err)
                            loginFormStore.getState().setStatus('error')
                            loginFormStore
                                .getState()
                                .loginFormFeedback({ message: err.message, type: 'error' })
                        })
                    return success
                },
                resendAccountVerificationCode: async ({ email }) => {
                    let success = false
                    useAlerts.getState().resetAlerts()
                    try {
                        const cognitoResponse = await Auth.resendSignUp(email)
                        if (cognitoResponse) {
                            success = true
                            loginFormStore.getState().loginFormFeedback({
                                message: 'Verification Code Sent',
                                type: 'success',
                            })
                        }
                    } catch (error) {
                        console.log(error)
                        loginFormStore.getState().loginFormFeedback({
                            message: 'Error Sending Verification Code',
                            type: 'error',
                        })
                    }
                    return success
                },
                completeNewPassword: async (password) => {
                    let success = false
                    const user = get().challengeResponse
                    try {
                        const cognitoResponse = await Auth.completeNewPassword(user, password)

                        if (cognitoResponse) {
                            set({ challengeResponse: null })
                            success = true
                            loginFormStore.setState({
                                returnPath: '/',
                            })
                            loginFormStore
                                .getState()
                                .loginFormFeedback({ message: 'Password Updated', type: 'success' })
                            await get().login(cognitoResponse)
                        }
                    } catch (error) {
                        success = false
                        loginFormStore.getState().setStatus('error')
                        loginFormStore.getState().loginFormFeedback({
                            message: String(error) && error.toString().replace(/(.*):/g, ''),
                            type: 'error',
                        })
                    }
                    return success
                },
                requestResetPassword: async ({ email }) => {
                    let success = false
                    useAlerts.getState().resetAlerts()
                    loginFormStore.setState({
                        returnPath: '/',
                    })
                    try {
                        const cognitoResponse = await Auth.forgotPassword(email)
                        if (cognitoResponse) {
                            success = true
                            loginFormStore.getState().loginFormFeedback({
                                message: 'Password Reset Link Sent',
                                type: 'success',
                            })
                            loginFormStore.getState().setStatus('ready')
                            Router.push('/login/reset-password')
                        }
                    } catch (error) {
                        console.log(error)
                        loginFormStore.getState().setStatus('error')
                        loginFormStore.getState().loginFormFeedback({
                            message: 'Password Reset Failed',
                            type: 'error',
                        })
                    }
                    return success
                },
                resetPassword: async ({ email, code, password }) => {
                    let success = false
                    useAlerts.getState().resetAlerts()

                    try {
                        const cognitoResponse = await Auth.forgotPasswordSubmit(
                            email,
                            code,
                            password
                        )
                        if (cognitoResponse) {
                            success = true
                            loginFormStore
                                .getState()
                                .loginFormFeedback({ message: 'Password Updated', type: 'success' })

                            await get().authorize({ email, password })
                            loginFormStore.setState({
                                titleOverride: null,
                                descriptionOverride: null,
                            })
                        }
                    } catch (error) {
                        console.log(error)
                        loginFormStore.getState().setStatus('error')
                        loginFormStore.getState().loginFormFeedback({
                            message: error?.message || 'Password Reset Failed',
                            type: 'error',
                        })
                    }
                    return success
                },
                updatePassword: async (oldPassword, newPassword) => {
                    const user = await Auth.currentAuthenticatedUser()
                    let success = false

                    try {
                        const cognitoResponse = await Auth.changePassword(
                            user,
                            oldPassword,
                            newPassword
                        )

                        if (cognitoResponse) {
                            success = true
                        }
                    } catch (error) {
                        console.error('Could not update user', error)
                    }
                    return success
                },
                deleteAccountModalOpen: false,
                setDeleteAccountModalOpen: (open) => set({ deleteAccountModalOpen: open }),
            }),
            {
                name: 'user-store',
            }
        )
    )
)
export { userStore }
