import * as UI from '@brewlogix/blx-ui'
import * as CC from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import React from 'react'
import { useSavedRoutesStore } from 'src/features/Map/state/useSavedRoutesStore'
import { Brand, BreweryRoute, CompositeProduct, Location } from 'src/types/schema/graphql'
import { useFavoriteActionsStore } from '../state/useFavoriteActionsStore'

interface ISavedItemToast {
    item?: Location | Brand | CompositeProduct | BreweryRoute
    isRoute?: boolean
}

const SavedItemToast: React.FC<ISavedItemToast> = ({ item, isRoute = false }) => {
    const setSavedRouteModalOpen = useSavedRoutesStore((state) => state.setSavedRouteModalOpen)
    const setFavoriteModalOpen = useFavoriteActionsStore((state) => state.setFavoriteModalOpen)
    const toast = useToast()
    const handleEditClick = () => {
        toast.closeAll()
        if (isRoute) setSavedRouteModalOpen(true)
        else setFavoriteModalOpen(true)
    }
    const Asset: React.FC = () => {
        if (isRoute) {
            return <UI.Icon slug='BeerRoutes' />
        }
        const anyAsset = (item as Location)?.assets?.[0] || (item as Location)?.brand?.assets?.[0]
        return (
            anyAsset && (
                <UI.Media
                    asset={anyAsset}
                    customWrapperClasses='mx-auto object-cover rounded-lg h-[36px] w-[36px] media media--logo card__logo'
                    width={36}
                    height={36}
                    alt={`Logo for ${item?.title || (item as Location)?.brand?.displayTitle}`}
                />
            )
        )
    }

    return (
        <CC.Box pb='10px'>
            <CC.HStack
                rounded='lg'
                color='gray.500'
                p={2}
                bg='white'
                justifyContent='space-between'
                shadow='xl'
            >
                <CC.HStack>
                    <Asset />
                    <CC.Text color='gray.500'>
                        Saved to <span className='font-bold'>Favorites</span>
                    </CC.Text>
                </CC.HStack>
                <CC.Button
                    fontSize='md'
                    variant='link'
                    aria-label='Open edit modal'
                    onClick={handleEditClick}
                >
                    Edit
                </CC.Button>
            </CC.HStack>
        </CC.Box>
    )
}
export default SavedItemToast
