import { gql } from 'graphql-request'

export const deleteFavoriteMutation = gql`mutation deleteFavorite($input: inputDeleteFavorite!) {
  deleteFavorite(input: $input) {
      id
      favorites {
          brands
          locations {
              brandId 
              locationId
          }
          products {
              brandId 
              productId
          }
          favoriteBrands {
            brandId
            notes
          }
          favoriteLocations {
            brandId
            locationId
            notes
          }
          favoriteProducts {
            brandId
            productId
            notes
          }
      }
  }
}
`
