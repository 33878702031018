import React from 'react'
import * as CC from '@chakra-ui/react'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'
import useAutoFocus from 'src/hooks/useAutoFocus'
import FieldWrapper from './FieldWrapper'
import FieldLabel from './FieldLabel'

const FullName: React.FC<{ format?: 'icon' | 'label' }> = ({ format = 'label' }) => {
    const status = loginFormStore((state) => state.status)
    const name = loginFormStore((state) => state?.name)
    const updateField = loginFormStore((state) => state.updateField)

    return (
        <FieldWrapper format={format}>
            <FieldLabel format={format} icon='UserCircleIcon'>
                Name
            </FieldLabel>

            <CC.Input
                type='text'
                required
                placeholder='Full Name'
                className='border-gray-100'
                id='name'
                autoComplete='name'
                value={name}
                onChange={({ target }) => updateField('name', target.value)}
                autoFocus={true}
                readOnly={status === 'loading'}
                paddingInlineStart={format === 'icon' ? 'var(--chakra-space-10)' : null}
                {...useAutoFocus(true)}
            />
        </FieldWrapper>
    )
}

export default FullName
