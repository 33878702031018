import React from 'react'
import * as UI from '@brewlogix/blx-ui'
import * as CC from '@chakra-ui/react'
import { useAccountFavoritesStore } from 'src/features/account/state/useAccountFavoritesStore'
import { useSavedRoutesStore } from 'src/features/Map/state/useSavedRoutesStore'
import * as HeroIcons from '@heroicons/react/solid'
import { useSavedBoardsStore } from '../state/useSavedBoardsStore'
import { useFavoriteActionsStore } from '../state/useFavoriteActionsStore'
import { BoardItemType, InputBreweryRouteGeoCoordInput, Location } from 'src/types/schema/graphql'
import { FavoriteType } from '../types/enums/FavoriteType'
import { ActiveFavoriteItemState } from '../types/enums'
import { coordinatesToInput } from '../utils/helpers'
import FavortitesModalThumbnail from './FavoritesModalThumbnail'

interface IAllFavoritesCard {}

const AllFavoritesCard: React.FC<IAllFavoritesCard> = () => {
    const favoriteProductsLength = useAccountFavoritesStore(
        (state) => state.favoriteProducts.length
    )
    const favoriteLocationsLength = useAccountFavoritesStore(
        (state) => state.favoriteLocations.length
    )
    const favoriteBrandsLength = useAccountFavoritesStore((state) => state.favoriteBrands.length)
    const parsedRoutes = useSavedRoutesStore((state) => state.parsedRoutes)
    const updateBreweryRoute = useSavedRoutesStore((state) => state.updateBreweryRoute)
    const selectedBoardsList = useSavedBoardsStore((state) => state.selectedBoardsList)
    const setSelectedBoardsList = useSavedBoardsStore((state) => state.setSelectedBoardsList)
    const updateFavorite = useFavoriteActionsStore((state) => state.updateFavorite)
    const toggleFavorite = useFavoriteActionsStore((state) => state.toggleFavorite)
    const activeFavoriteType = useFavoriteActionsStore((state) => state.activeFavoriteType)
    const activeFavoriteItem = useFavoriteActionsStore((state) => state.activeFavoriteItem)
    const favoritesNote = useFavoriteActionsStore((state) => state.favoritesNote)
    const allFavorites = useFavoriteActionsStore((state) => state.allFavorites)
    const setActiveFavoriteItemState = useFavoriteActionsStore(
        (state) => state.setActiveFavoriteItemState
    )
    const allFavoritesBoxChecked = useFavoriteActionsStore((state) => state.allFavoritesBoxChecked)
    const setAllFavoritesBoxChecked = useFavoriteActionsStore(
        (state) => state.setAllFavoritesBoxChecked
    )
    const activeSavedRoute = useSavedRoutesStore((state) => state.activeSavedRoute)
    const [loading, setLoading] = React.useState<boolean>(false)

    const handleCheckboxChange = async () => {
        setLoading(true)
        if (allFavoritesBoxChecked) {
            if (activeFavoriteType !== BoardItemType.Route) {
                await updateFavorite(
                    activeFavoriteType as unknown as FavoriteType,
                    activeFavoriteItem.id,
                    (activeFavoriteItem as Location)?.brand?.id,
                    true,
                    favoritesNote,
                    false
                )
            } else {
                const inputCoordinates: InputBreweryRouteGeoCoordInput[] = coordinatesToInput(
                    activeSavedRoute?.coordinates
                )
                const { title = '', description = '' } = activeSavedRoute
                await updateBreweryRoute(
                    activeSavedRoute,
                    false,
                    inputCoordinates,
                    title,
                    description,
                    false
                )
            }
            setSelectedBoardsList([])
            setActiveFavoriteItemState(ActiveFavoriteItemState.Inactive)
            setAllFavoritesBoxChecked(false)
        } else {
            await toggleFavorite(
                activeFavoriteType as unknown as FavoriteType,
                activeFavoriteItem.id,
                (activeFavoriteItem as Location)?.brand?.id,
                true
            )
            setActiveFavoriteItemState(ActiveFavoriteItemState.Active)
            setAllFavoritesBoxChecked(true)
        }
        setLoading(false)
    }
    const activeParsedRoutes = React.useMemo(
        () => parsedRoutes?.filter((route) => route?.isActive),
        [parsedRoutes]
    )
    return (
        <CC.HStack justifyContent='start' gap={3} w='full'>
            <FavortitesModalThumbnail
                route={parsedRoutes[0]}
                title={'All Favorites'}
                favorites={allFavorites}
            />
            <CC.HStack spacing={1} justifyContent='space-between' w='full'>
                <CC.VStack alignItems='start'>
                    <p className='font-bold'>All Favorites</p>
                    <CC.HStack>
                        <p className='text-sm text-gray-400'>{`${activeParsedRoutes?.length} Route${
                            activeParsedRoutes?.length === 1 ? '' : 's'
                        }`}</p>
                        <span className='text-xxs text-gray-300'>•</span>
                        <p className='text-sm text-gray-400'>
                            {`${favoriteLocationsLength + favoriteBrandsLength} Brewer${
                                favoriteLocationsLength + favoriteBrandsLength === 1 ? 'y' : 'ies'
                            }`}
                        </p>
                        <span className='text-xxs text-gray-300'>•</span>
                        <p className='text-sm text-gray-400'>
                            {` ${favoriteProductsLength} Brew${
                                favoriteProductsLength === 1 ? '' : 's'
                            }`}
                        </p>
                    </CC.HStack>
                </CC.VStack>
                {!loading ? (
                    <CC.Checkbox
                        justifySelf='flex-end'
                        size='lg'
                        colorScheme='gray'
                        isChecked={allFavoritesBoxChecked || selectedBoardsList?.length > 0}
                        onChange={handleCheckboxChange}
                    />
                ) : (
                    <CC.Spinner />
                )}
            </CC.HStack>
        </CC.HStack>
    )
}
export default AllFavoritesCard
