export const statesUS = {
    AL: {
        label: 'Alabama',
        code: 'AL',
    },
    AK: {
        label: 'Alaska',

        code: 'AK',
    },
    AZ: {
        label: 'Arizona',
        code: 'AZ',
    },
    AR: {
        label: 'Arkansas',
        code: 'AR',
    },
    CA: {
        label: 'California',

        code: 'CA',
    },
    CO: {
        label: 'Colorado',
        code: 'CO',
    },
    CT: {
        label: 'Connecticut',
        code: 'CT',
    },
    DE: {
        label: 'Delaware',
        code: 'DE',
    },
    DC: {
        label: 'District of Columbia',
        code: 'DC',
    },
    FL: {
        label: 'Florida',
        code: 'FL',
    },
    GA: {
        label: 'Georgia',
        code: 'GA',
    },
    HI: {
        label: 'Hawaii',

        code: 'HI',
    },
    ID: {
        label: 'Idaho',
        code: 'ID',
    },
    IL: {
        label: 'Illinois',
        code: 'IL',
    },
    IN: {
        label: 'Indiana',
        code: 'IN',
    },
    IA: {
        label: 'Iowa',
        code: 'IA',
    },
    KS: {
        label: 'Kansas',
        code: 'KS',
    },
    KY: {
        label: 'Kentucky',
        code: 'KY',
    },
    LA: {
        label: 'Louisiana',
        code: 'LA',
    },
    ME: {
        label: 'Maine',
        code: 'ME',
    },
    MD: {
        label: 'Maryland',
        code: 'MD',
    },
    MA: {
        label: 'Massachusetts',
        code: 'MA',
    },
    MI: {
        label: 'Michigan',
        code: 'MI',
    },
    MN: {
        label: 'Minnesota',
        code: 'MN',
    },
    MS: {
        label: 'Mississippi',
        code: 'MS',
    },
    MO: {
        label: 'Missouri',
        code: 'MO',
    },
    MT: {
        label: 'Montana',
        code: 'MT',
    },
    NE: {
        label: 'Nebraska',
        code: 'NE',
    },
    NV: {
        label: 'Nevada',
        code: 'NV',
    },
    NH: {
        label: 'New Hampshire',
        code: 'NH',
    },
    NJ: {
        label: 'New Jersey',
        code: 'NJ',
    },
    NM: {
        label: 'New Mexico',
        code: 'NM',
    },
    NY: {
        label: 'New York',
        code: 'NY',
    },
    NC: {
        label: 'North Carolina',
        Abbrev: 'N.C.',
        code: 'NC',
    },
    ND: {
        label: 'North Dakota',
        code: 'ND',
    },
    OH: {
        label: 'Ohio',
        code: 'OH',
    },
    OK: {
        label: 'Oklahoma',
        code: 'OK',
    },
    OR: {
        label: 'Oregon',
        code: 'OR',
    },
    PA: {
        label: 'Pennsylvania',
        code: 'PA',
    },
    RI: {
        label: 'Rhode Island',
        code: 'RI',
    },
    SC: {
        label: 'South Carolina',
        code: 'SC',
    },
    SD: {
        label: 'South Dakota',
        code: 'SD',
    },
    TN: {
        label: 'Tennessee',
        code: 'TN',
    },
    TX: {
        label: 'Texas',
        code: 'TX',
    },
    UT: {
        label: 'Utah',
        code: 'UT',
    },
    VT: {
        label: 'Vermont',
        code: 'VT',
    },
    VA: {
        label: 'Virginia',
        code: 'VA',
    },
    WA: {
        label: 'Washington',
        code: 'WA',
    },
    WV: {
        label: 'West Virginia',
        code: 'WV',
    },
    WI: {
        label: 'Wisconsin',
        code: 'WI',
    },
    WY: {
        label: 'Wyoming',
        code: 'WY',
    },
}
