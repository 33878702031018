import { ImageProps, StaticImageData } from 'next/image'
import { CompositeProduct } from 'src/types/schema/graphql'
import beerDefault from './../../../public/assets/fallbacks/beer-fallback.svg'
import ciderDefault from './../../../public/assets/fallbacks/cider-fallback.svg'
import kombuchaDefault from './../../../public/assets/fallbacks/kombucha-fallback.svg'

export default function getDefaultIconByProduct(product: CompositeProduct): StaticImageData {
    if (product?.__typename === 'Beer') {
        return beerDefault
    }
    if (product?.__typename === 'Cider') {
        return ciderDefault
    }
    if (product?.__typename === 'Kombucha') {
        return kombuchaDefault
    }
}
