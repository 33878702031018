export const setRouteVisibilityNone = (mapRef: mapboxgl.Map) => {
    if (mapRef) {
        if (mapRef.getLayer('routeMarkers')) {
            mapRef.setLayoutProperty('routeMarkers', 'visibility', 'none')
        }
        if (mapRef.getLayer('endStopMarker')) {
            mapRef.setLayoutProperty('endStopMarker', 'visibility', 'none')
        }
        if (mapRef?.getLayer('routeLayer'))
            mapRef.setLayoutProperty('routeLayer', 'visibility', 'none')
        if (mapRef?.getLayer('routeStopsLayer'))
            mapRef.setLayoutProperty('routeStopsLayer', 'visibility', 'none')
        if (mapRef?.getLayer('routeLayerShadowFE'))
            mapRef.setLayoutProperty('routeLayerShadowFE', 'visibility', 'none')

        if (mapRef?.getLayer('routeDirectionArrows'))
            mapRef.setLayoutProperty('routeDirectionArrows', 'visibility', 'none')
    } else {
        console.warn('No map ref found for setRouteVisibilityNone()')
    }
}
