import React from 'react'
import * as CC from '@chakra-ui/react'
import { InputGroupProps } from '@chakra-ui/react'
interface IFieldWrapper {
    format?: 'icon' | 'label'
    children: React.ReactNode
}

const FieldWrapper: React.FC<IFieldWrapper> = ({ children, format = 'label' }) => {
    const formatProps: Partial<InputGroupProps> = {
        display: format === 'label' ? 'flex' : null,
        flexDirection: format === 'label' ? 'column' : null,
    }
    return <CC.InputGroup {...formatProps}>{children}</CC.InputGroup>
}

export default FieldWrapper
