import mapboxgl from 'mapbox-gl'

export const addCursorStyleListeners = (mapRef: mapboxgl.Map) => {
    mapRef?.on('mouseenter', 'clusters', () => {
        mapRef.getCanvas().style.cursor = 'pointer'
    })
    mapRef?.on('mouseenter', 'unclustered-point', () => {
        mapRef.getCanvas().style.cursor = 'pointer'
    })
    mapRef?.on('mouseleave', 'clusters', () => {
        mapRef.getCanvas().style.cursor = ''
    })
    mapRef?.on('mouseenter', 'clusters', () => {
        mapRef.getCanvas().style.cursor = 'pointer'
    })
    mapRef?.on('mouseenter', 'unclustered-point', () => {
        mapRef.getCanvas().style.cursor = 'pointer'
    })
    mapRef?.on('mouseleave', 'clusters', () => {
        mapRef.getCanvas().style.cursor = ''
    })
    mapRef?.on('mouseleave', 'unclustered-point', () => {
        mapRef.getCanvas().style.cursor = ''
    })
}
