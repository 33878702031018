import { RequestInput, apiRequest } from 'src/api/apiRequest'
import { InputGetById, Location, Product } from 'src/types/schema/graphql'
import { getLocationByIdQuery } from '../graph/getLocationByIdQuery'

export const getLocationById = async (locationId: string, brandId: string) => {
    const input: RequestInput<InputGetById> = {
        query: getLocationByIdQuery,
        args: {
            input: {
                itemId: locationId,
                parentId: brandId,
            },
        },
        endpoint: 'getLocationById',
        file: 'getLocationById.ts',
        publicQuery: true,
    }
    const { data, success, error } = await apiRequest<Location, InputGetById>(input)
    if (success) {
        return data
    } else {
        console.log(error)
    }
}
