import React from 'react'
import * as CC from '@chakra-ui/react'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'
import FieldLabel from './FieldLabel'
import FieldWrapper from './FieldWrapper'

const Code: React.FC<{ format?: 'icon' | 'label' }> = ({ format = 'icon' }) => {
    const status = loginFormStore((state) => state.status)
    const code = loginFormStore((state) => state?.code)
    const updateField = loginFormStore((state) => state.updateField)

    return (
        <FieldWrapper format={format}>
            <FieldLabel format={format} icon='ShieldCheckIcon'>
                Code
            </FieldLabel>
            <CC.Input
                type='number'
                placeholder='Verification Code'
                className='border-gray-100'
                id='code'
                autoComplete='off'
                pattern='[0-9]*'
                minLength={6}
                value={code}
                readOnly={status === 'loading'}
                paddingInlineStart={format === 'icon' ? 'var(--chakra-space-10)' : null}
                required
                onChange={({ target }) => updateField('code', target.value)}
            />
        </FieldWrapper>
    )
}

export default Code
