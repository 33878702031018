import { gql } from 'graphql-request'

export const updateBreweryRouteMutation = gql`mutation updateBreweryRoute($input: inputUpdateBreweryRoute!) {
	updateBreweryRoute(input: $input) {
        id
        isActive
        title
        routeId
        title
        description
        distance
        duration
        coordinates {
            lat
            lon
            title
            placeName
            location {
                id
            }
        }
        createdDate
        updatedDate
        createdBy
    }
}`
