const getCuratedBoardIdList = (env: string) => {
    if (env === 'prod') {
        return [
            {
                userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
                boardId: '2e5e4dbf-706b-423c-92a9-f67f0816fedc',
            },
            {
                userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
                boardId: '65a2d380-f190-4c86-94d0-84826cf03848',
            },
            {
                userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
                boardId: 'd8b77c53-a323-4364-88a1-40407dca247d',
            },
            {
                userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
                boardId: '05524754-39e9-4a78-936e-ddfefd1ce1b1',
            },
            {
                userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
                boardId: '700129b6-a5bd-466d-8110-61202d6323b0',
            },
            {
                userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
                boardId: '216e734a-568d-40ff-87cb-d974563545a8',
            },
            {
                userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
                boardId: '869aee3d-c7ce-4669-a873-e5c395c38710',
            },
        ]
    }
    if (env === 'dev') {
        return [
            {
                userId: '07be1568-1529-41e2-8e94-c7e476bb69ef',
                boardId: '6dae8d43-78a9-4276-a35e-4d573c017433',
            },
            {
                userId: '7b0fc164-8f45-44dc-9625-de4fce5d736a',
                boardId: '9ff15e4a-e26a-4a95-8372-610202c43ba4',
            },
            {
                userId: '7b0fc164-8f45-44dc-9625-de4fce5d736a',
                boardId: '0d4b9f34-a9fc-43a3-a3db-2550d509cdd8',
            },
            {
                userId: '7b0fc164-8f45-44dc-9625-de4fce5d736a',
                boardId: '57ad6750-aba9-43ab-8197-54f268a85164',
            },
        ]
    }
    return [
        {
            userId: '07be1568-1529-41e2-8e94-c7e476bb69ef',
            boardId: 'eaf08d12-6591-4d75-bca3-950abfbe101c',
        },
        {
            userId: '7b0fc164-8f45-44dc-9625-de4fce5d736a',
            boardId: '9ff15e4a-e26a-4a95-8372-610202c43ba4',
        },
        {
            userId: '7b0fc164-8f45-44dc-9625-de4fce5d736a',
            boardId: '0d4b9f34-a9fc-43a3-a3db-2550d509cdd8',
        },
        {
            userId: '7b0fc164-8f45-44dc-9625-de4fce5d736a',
            boardId: '57ad6750-aba9-43ab-8197-54f268a85164',
        },
        {
            userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
            boardId: '2e5e4dbf-706b-423c-92a9-f67f0816fedc',
        },
        {
            userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
            boardId: '65a2d380-f190-4c86-94d0-84826cf03848',
        },
        {
            userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
            boardId: 'd8b77c53-a323-4364-88a1-40407dca247d',
        },
        {
            userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
            boardId: '05524754-39e9-4a78-936e-ddfefd1ce1b1',
        },
        {
            userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
            boardId: '700129b6-a5bd-466d-8110-61202d6323b0',
        },
        {
            userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
            boardId: '216e734a-568d-40ff-87cb-d974563545a8',
        },
        {
            userId: '5068b7a2-7ebc-450a-b755-ac85086715da',
            boardId: '869aee3d-c7ce-4669-a873-e5c395c38710',
        },
    ]
}

export default getCuratedBoardIdList
