import * as CC from '@chakra-ui/react'
import React from 'react'
import { useFavoriteActionsStore } from '../state/useFavoriteActionsStore'
import { getNote } from '../utils/helpers/getNote'
import { isFavorited } from '../utils/helpers/isFavorited'
import FavoritesModalActions from './FavoritesModalActions'
import FavoritesNote from './FavoritesNote'
import FavoritesNoteInput from './FavoritesNoteInput'
import FavoritesModalCard from './FavoritesModalCard'
import * as HeroIcons from '@heroicons/react/solid'
import SavedBoardsList from './SavedBoardsList'
import { useSavedBoardsStore } from '../state/useSavedBoardsStore'
import { BoardItemType, FavoriteBrand } from 'src/types/schema/graphql'
import { FavoriteType } from '../types/enums/FavoriteType'
import { ActiveFavoriteItemState } from '../types/enums'

const FavoritesModal: React.FC = () => {
    const setFavoriteModalOpen = useFavoriteActionsStore((state) => state.setFavoriteModalOpen)
    const activeFavoriteType = useFavoriteActionsStore((state) => state.activeFavoriteType)
    const setActiveFavoriteItem = useFavoriteActionsStore((state) => state.setActiveFavoriteItem)
    const activeFavoriteItem = useFavoriteActionsStore((state) => state.activeFavoriteItem)
    const setFavoritesNote = useFavoriteActionsStore((state) => state.setFavoritesNote)
    const favoriteModalOpen = useFavoriteActionsStore((state) => state.favoriteModalOpen)
    const activeFavoriteAction = useFavoriteActionsStore((state) => state.activeFavoriteAction)
    const setCreateBoardModalOpen = useSavedBoardsStore((state) => state.setCreateBoardModalOpen)
    const populateSavedBoardsList = useSavedBoardsStore((state) => state.populateSavedBoardsList)
    const setAllFavoritesBoxChecked = useFavoriteActionsStore(
        (state) => state.setAllFavoritesBoxChecked
    )
    const setActiveFavoriteItemState = useFavoriteActionsStore(
        (state) => state.setActiveFavoriteItemState
    )
    React.useEffect(() => {
        if (existingFavorite) {
            setFavoritesNote(
                getNote(activeFavoriteItem.id, activeFavoriteType as unknown as FavoriteType, true)
            )
        }
        ;(async () => await populateSavedBoardsList())()
        setAllFavoritesBoxChecked(true)
        setActiveFavoriteItemState(ActiveFavoriteItemState.Active)
    }, [])

    const handleClose = () => {
        setFavoriteModalOpen(false)
        setActiveFavoriteItem(null)
    }
    const existingFavorite = isFavorited(
        activeFavoriteItem?.id,
        activeFavoriteType as unknown as FavoriteType
    )
    const getFavoriteModalLabel = () => {
        switch (activeFavoriteAction) {
            case 'add':
                return 'Save to Board'
            case 'remove':
                return 'Remove from Favorites'
            case 'edit':
                return 'Edit Saved Item'
            default:
                return 'Save to Board'
        }
    }
    return (
        <CC.Modal isOpen={favoriteModalOpen} onClose={handleClose} isCentered={true} size='xl'>
            <CC.ModalOverlay />
            <CC.ModalContent className='space-y-3' p={6}>
                <CC.ModalHeader p={0}>{getFavoriteModalLabel()}</CC.ModalHeader>
                <CC.ModalCloseButton />
                <CC.ModalBody p={0}>
                    {/* Leaving this commented out for potential use later */}
                    {/* <FavoritesModalCard favoriteItemType={activeFavoriteType} /> */}
                    {activeFavoriteAction !== 'remove' ? (
                        <>
                            <FavoritesNoteInput />
                            <CC.HStack gap={3} my={6}>
                                <div
                                    onClick={() => setCreateBoardModalOpen(true)}
                                    className='flex cursor-pointer items-center gap-3'
                                >
                                    <CC.Button
                                        aria-label='Create a new board'
                                        size='sm'
                                        p={0}
                                        borderRadius='lg'
                                        border='none'
                                        bg='gray.50'
                                    >
                                        <div className='flex p-4'>
                                            <CC.Icon boxSize='6' as={HeroIcons.PlusIcon} />
                                        </div>
                                    </CC.Button>
                                    <p className='font-semibold'>Create a new board</p>
                                </div>
                            </CC.HStack>
                            <SavedBoardsList />
                        </>
                    ) : (
                        <p>
                            {`Are you sure you want to remove this ${
                                activeFavoriteType || 'saved item'
                            } from your favorites?
                            This will also remove it from all other boards.`}
                        </p>
                    )}
                </CC.ModalBody>
                <CC.ModalFooter p={0}>
                    <FavoritesModalActions />
                </CC.ModalFooter>
            </CC.ModalContent>
        </CC.Modal>
    )
}
export default FavoritesModal
