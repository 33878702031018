export const getFavoritesQuery = /* GraphQL */ `
query GetAuthenticatedUser {
  getAuthenticatedUser {
      favorites{
          brands
          locations {
              locationId
              brandId
          }  
          products {
              productId 
              brandId
          }
          favoriteBrands {
            brandId
            notes
            isActive
          }
          favoriteLocations {
              locationId
              brandId
              notes
              isActive
          }  
          favoriteProducts {
              productId 
              brandId
              notes
              isActive
          }
      }
  }}
`
