import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { userStore } from 'src/features/auth/state/userStore'

interface IUserAccountStore {
    nameAndPhoneSectionEdit: boolean
    passwordSectionEdit: boolean
    newPasswordValue: string
    currentPassword: string
    firstNameValue: string
    lastNameValue: string
    phoneNumberValue: string
    nameNumberSectionLoading: boolean
    passwordSectionLoading: boolean
    setCurrentPassword: (currentPassword: string) => void
    setPasswordSectionLoading: (passwordSectionLoading: boolean) => void
    setNameNumberSectionLoading: (nameNumberSectionLoading: boolean) => void
    setFirstNameValue: (firstNameValue: string) => void
    setLastNameValue: (lastNameValue: string) => void
    setPhoneNumberValue: (phoneNumberValue: string) => void
    setNewPasswordValue: (newPasswordValue: string) => void
    setNameAndPhoneSectionEdit: (nameAndPhoneSectionEdit: boolean) => void
    setPasswordSectionEdit: (passwordSectionEdit: boolean) => void
    clearUserAccountStore: () => void
}

const defaultData = {
    nameAndPhoneSectionEdit: false,
    passwordSectionEdit: false,
    newPasswordValue: '',
    currentPassword: '',
    nameNumberSectionLoading: false,
    passwordSectionLoading: false,
}

const userAccountStore = create<IUserAccountStore>()(
    devtools(
        (set, get) => ({
            ...defaultData,
            firstNameValue: userStore.getState().user?.givenName,
            lastNameValue: userStore.getState().user?.familyName,
            phoneNumberValue: userStore.getState().user?.contact?.phone,
            setCurrentPassword: (currentPassword) => set({ currentPassword }),
            setPasswordSectionLoading: (passwordSectionLoading) => set({ passwordSectionLoading }),
            setNameNumberSectionLoading: (nameNumberSectionLoading) =>
                set({ nameNumberSectionLoading }),
            setFirstNameValue: (firstNameValue) => set({ firstNameValue }),
            setLastNameValue: (lastNameValue) => set({ lastNameValue }),
            setPhoneNumberValue: (phoneNumberValue) => set({ phoneNumberValue }),
            setNewPasswordValue: (newPasswordValue) => set({ newPasswordValue }),
            setNameAndPhoneSectionEdit: (nameAndPhoneSectionEdit) =>
                set({ nameAndPhoneSectionEdit }),
            setPasswordSectionEdit: (passwordSectionEdit) => set({ passwordSectionEdit }),
            clearUserAccountStore: () => set(defaultData),
        }),
        { name: 'userAccountStore' }
    )
)

export { userAccountStore }
