import React from 'react'
import {
    Button,
    FormLabel,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useSavedBoardsStore } from '../state/useSavedBoardsStore'
import { set } from 'lodash'

interface IUpdateBoard {}

const UpdateBoardModal: React.FC<IUpdateBoard> = () => {
    const updateBoardModalOpen = useSavedBoardsStore((state) => state.updateBoardModalOpen)
    const setUpdateBoardModalOpen = useSavedBoardsStore((state) => state.setUpdateBoardModalOpen)
    const updateBoardData = useSavedBoardsStore((state) => state.updateBoardData)
    const setUpdateBoardData = useSavedBoardsStore((state) => state.setUpdateBoardData)
    const updateBoard = useSavedBoardsStore((state) => state.updateBoard)
    const getBoardById = useSavedBoardsStore((state) => state.getBoardById)
    const populateBoardDetailPage = useSavedBoardsStore((state) => state.populateBoardDetailPage)
    const [boardTitle, setBoardTitle] = React.useState(updateBoardData?.title)
    const [boardDescription, setBoardDescription] = React.useState(updateBoardData?.description)
    const [loading, setLoading] = React.useState(false)

    const handleUpdateBoard = async () => {
        setLoading(true)
        setUpdateBoardData({ title: boardTitle, description: boardDescription })
        const success = await updateBoard()
        if (success) {
            ;(async () => {
                const board = await getBoardById(updateBoardData.boardId)
                if (board) {
                    await populateBoardDetailPage(board)
                    setUpdateBoardData({ boardId: '', title: '', description: '' })
                }
            })()
            setUpdateBoardModalOpen(false)
            setLoading(false)
        }
    }

    const handleOnClose = () => {
        setUpdateBoardData({ boardId: '', title: '', description: '' })
        setUpdateBoardModalOpen(false)
    }

    return (
        <Modal isOpen={updateBoardModalOpen} onClose={handleOnClose} isCentered={true} size='xl'>
            <ModalOverlay />
            <ModalContent className='space-y-3' p={6}>
                <ModalHeader p={0}>Update Board</ModalHeader>
                <ModalCloseButton />
                <ModalBody p={0} py={3}>
                    <VStack spacing={5}>
                        <InputGroup display='flex' flexDirection={'column'}>
                            <FormLabel className='text-sm font-bold'>Title</FormLabel>
                            <Input
                                className='text-sm'
                                colorScheme='gray'
                                focusBorderColor='gray.500'
                                value={boardTitle}
                                onChange={(e) => setBoardTitle(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup display='flex' flexDirection={'column'}>
                            <FormLabel className='text-sm font-bold'>Description</FormLabel>
                            <Input
                                className='text-sm'
                                colorScheme='gray'
                                focusBorderColor='gray.500'
                                value={boardDescription}
                                onChange={(e) => setBoardDescription(e.target.value)}
                            />
                        </InputGroup>
                    </VStack>
                </ModalBody>
                <ModalFooter p={0} gap={2}>
                    <Button size='sm' onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button
                        size='sm'
                        variant='primary'
                        isLoading={loading}
                        onClick={async () => await handleUpdateBoard()}
                    >
                        Update Board
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
export default UpdateBoardModal
