import { env } from 'process'
import getCuratedBoardIdList from './getCuratedBoardIdList'

export const generateCuratedBoardLink = (boardId: string, envSubdomain: string): string | null => {
    const curatedBoardIdList = getCuratedBoardIdList(envSubdomain)

    for (let i = 0; i < curatedBoardIdList.length; i++) {
        if (curatedBoardIdList[i].boardId === boardId) {
            return `/boards/${curatedBoardIdList[i].userId}/${boardId}`
        }
    }
    return null
}
