import * as CC from '@chakra-ui/react'
import React from 'react'
import { useSavedRoutesStore } from 'src/features/Map/state/useSavedRoutesStore'
import {
    Asset,
    BoardItemType,
    BreweryRoute,
    Favorites,
    InputBreweryRouteGeoCoordInput,
    Location,
} from 'src/types/schema/graphql'
import { useFavoriteActionsStore } from '../state/useFavoriteActionsStore'
import { useSavedBoardsStore } from '../state/useSavedBoardsStore'
import { ActiveFavoriteItemState } from '../types/enums'
import { FavoriteType } from '../types/enums/FavoriteType'
import { coordinatesToInput } from '../utils/helpers'
import FavoritesModalThumbnail from './FavoritesModalThumbnail'
import { useRouter } from 'next/router'

interface ISavedBoardListCard {
    id: string
    title: string
    counts?: {
        routes: number
        favoriteLocations: number
        favoriteProducts: number
    }
    isActive?: boolean
    favorites?: Favorites
    route?: BreweryRoute
}

const SavedBoardListCard: React.FC<ISavedBoardListCard> = ({
    id,
    title,
    counts,
    favorites,
    route,
}) => {
    const router = useRouter()
    const addItemToBoard = useSavedBoardsStore((state) => state.addItemToBoard)
    const removeItemFromBoard = useSavedBoardsStore((state) => state.removeItemFromBoard)
    const itemExistsOnBoard = useSavedBoardsStore((state) => state.itemExistsOnBoard)
    const selectedBoardsList = useSavedBoardsStore((state) => state.selectedBoardsList)
    const setSelectedBoardsList = useSavedBoardsStore((state) => state.setSelectedBoardsList)
    const activeFavoriteType = useFavoriteActionsStore((state) => state.activeFavoriteType)
    const activeFavoriteItem = useFavoriteActionsStore((state) => state.activeFavoriteItem)
    const [isChecked, setIsChecked] = React.useState(
        itemExistsOnBoard(id, activeFavoriteItem?.id, activeFavoriteType)
    )
    const { boardId } = router.query as { boardId: string }
    const [loading, setLoading] = React.useState(false)
    const updateFavorite = useFavoriteActionsStore((state) => state.updateFavorite)
    const favoritesNote = useFavoriteActionsStore((state) => state.favoritesNote)
    const setAllFavoritesBoxChecked = useFavoriteActionsStore(
        (state) => state.setAllFavoritesBoxChecked
    )
    const activeFavoriteItemState = useFavoriteActionsStore(
        (state) => state.activeFavoriteItemState
    )
    const activeSavedRoute = useSavedRoutesStore((state) => state.activeSavedRoute)
    const updateBreweryRoute = useSavedRoutesStore((state) => state.updateBreweryRoute)
    const handleCheckboxChange = async () => {
        setLoading(true)
        if (isChecked) {
            await removeItemFromBoard(id, boardId)
            setIsChecked(false)
            setSelectedBoardsList([...selectedBoardsList])
        } else {
            setSelectedBoardsList([...selectedBoardsList, id])
            setIsChecked(true)
            await addItemToBoard(id)
            setAllFavoritesBoxChecked(true)
            if (activeFavoriteType !== BoardItemType.Route) {
                await updateFavorite(
                    activeFavoriteType as unknown as FavoriteType,
                    activeFavoriteItem.id,
                    (activeFavoriteItem as Location)?.brand?.id,
                    true,
                    favoritesNote,
                    true
                )
            } else {
                const inputCoordinates: InputBreweryRouteGeoCoordInput[] = coordinatesToInput(
                    activeSavedRoute?.coordinates
                )
                const { title = '', description = '' } = activeSavedRoute
                await updateBreweryRoute(
                    activeSavedRoute,
                    true,
                    inputCoordinates,
                    title,
                    description,
                    false
                )
            }
        }
        setLoading(false)
    }
    React.useEffect(() => {
        if (activeFavoriteItemState !== ActiveFavoriteItemState.Active) {
            setIsChecked(false)
        }
    }, [activeFavoriteItemState])

    return (
        <CC.HStack key={id} justifyContent='start' gap={3} w='full'>
            <FavoritesModalThumbnail route={route} favorites={favorites} title={title} />
            <CC.HStack spacing={1} justifyContent='space-between' w='full'>
                <CC.VStack alignItems='start'>
                    <p className='font-bold'>{title}</p>
                    <CC.HStack>
                        <p className='text-sm text-gray-400'>
                            {`${counts?.routes} Route${counts?.routes === 1 ? '' : 's'}`}
                        </p>
                        <span className='text-xxs text-gray-300'>•</span>
                        <p className='text-sm text-gray-400'>
                            {`${counts.favoriteLocations} Brewer${
                                counts.favoriteLocations === 1 ? 'y' : 'ies'
                            }`}
                        </p>
                        <span className='text-xxs text-gray-300'>•</span>
                        <p className='text-sm text-gray-400'>
                            {` ${counts.favoriteProducts} Brew${
                                counts.favoriteProducts === 1 ? '' : 's'
                            }`}
                        </p>
                    </CC.HStack>
                </CC.VStack>
                {!loading ? (
                    <CC.Checkbox
                        justifySelf='flex-end'
                        size='lg'
                        onChange={handleCheckboxChange}
                        colorScheme='gray'
                        isChecked={isChecked}
                    />
                ) : (
                    <CC.Spinner />
                )}
            </CC.HStack>
        </CC.HStack>
    )
}
export default SavedBoardListCard
