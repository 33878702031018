import {
    BoardItemType,
    Brand,
    BreweryRoute,
    FavoriteBrand,
    FavoriteLocation,
    FavoriteProduct,
    Favorites,
    InputFavorite,
    InputLocationIds,
    InputProductIds,
    Location,
    Product,
    User,
} from 'src/types/schema/graphql'
import { addFavoriteMutation, getFavoritesQuery } from '../utils/graph'

import { apiRequest, RequestInput } from 'src/api/apiRequest'
import { useAlerts } from 'src/features/alerts/state/useAlerts'
import { userStore } from 'src/features/auth/state/userStore'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { useAccountFavoritesStore } from '../../account/state/useAccountFavoritesStore'
import { FavoriteType } from '../types/enums/FavoriteType'
import { updateFavoriteMutation } from '../utils/graph/updateFavoriteMutation'
import { isFavorited } from '../utils/helpers/isFavorited'
import { useSavedBoardsStore } from './useSavedBoardsStore'
import { guidGenerator } from 'src/features/Map/utils/functions'
import { ActiveFavoriteItemState } from '../types/enums'

interface DefaultData {
    favoritingType: FavoriteType
    location: InputLocationIds[]
    product: InputProductIds[]
    brand: string[]
    route: BreweryRoute
    note: string
    heading: string
    favoriteLocationIds: FavoriteLocation[]
    favoriteProductIds: FavoriteProduct[]
    favoriteBrandIds: FavoriteBrand[]
    allFavorites: Favorites
}

interface IuseFavoriteActionsStore extends DefaultData {
    setNote: (note: string) => void
    clearStore: () => void
    updateFavorites: (favoriteType: FavoriteType, favoritesData: Favorites) => Promise<boolean>
    populateFavoriteStore: (populateAccountFavorites?: boolean) => Promise<void>
    getUserFavorites: () => Promise<{ response: Favorites; success: boolean }>
    toggleFavorite: (
        type: FavoriteType,
        id: string,
        brandId?: string,
        shouldUpdateAccountStore?: boolean,
        notes?: string
    ) => Promise<boolean>
    updateFavorite: (
        favoriteType: FavoriteType,
        id: string,
        brandId: string,
        shouldUpdateAccountStore?: boolean,
        note?: string,
        isActive?: boolean
    ) => void
    favoriteModalOpen: boolean
    setFavoriteModalOpen: (favoriteModalOpen: boolean) => void
    activeFavoriteItem: Location | Product | Brand
    setActiveFavoriteItem: (activeFavoriteItem: Location | Product | Brand) => void
    activeFavoriteType: BoardItemType
    setActiveFavoriteType: (activeFavoriteType: BoardItemType) => void
    favoritesNote: string
    setFavoritesNote: (favoritesNote: string) => void
    shouldUpdateAccountStore: boolean
    setShouldUpdateAccountStore: (shouldUpdateAccountStore: boolean) => void
    activeFavoriteAction: 'edit' | 'remove' | 'add'
    setActiveFavoriteAction: (activeFavoriteAction: 'edit' | 'remove' | 'add') => void
    favoriteIsChanged: boolean
    setFavoriteIsChanged: (favoriteIsChanged: boolean) => void
    updatedNote: string
    setUpdatedNote: (updatedNote: string) => void
    activeFavoriteItemState: ActiveFavoriteItemState
    setActiveFavoriteItemState: (activeItemState: ActiveFavoriteItemState) => void
    allFavoritesBoxChecked: boolean
    setAllFavoritesBoxChecked: (allFavoritesBoxChecked: boolean) => void
}

const defaultData: DefaultData = {
    favoritingType: FavoriteType.Product,
    product: null,
    brand: null,
    location: null,
    route: null,
    heading: '',
    note: '',
    favoriteProductIds: [],
    favoriteBrandIds: [],
    favoriteLocationIds: [],
    allFavorites: null,
}

export const useFavoriteActionsStore = create<IuseFavoriteActionsStore>()(
    devtools(
        (set, get) => ({
            ...defaultData,
            setNote: (note) => set({ note }),
            populateFavoriteStore: async (populateAccountFavorites: boolean = true) => {
                if (userStore.getState().userIsLoggedIn && userStore.getState().user) {
                    try {
                        const { response, success } = await get().getUserFavorites()
                        if (success) {
                            set({
                                favoriteLocationIds:
                                    response?.favoriteLocations.filter((item) => item?.isActive) ||
                                    [],
                                favoriteBrandIds:
                                    response?.favoriteBrands.filter((item) => item?.isActive) || [],
                                // @TODO: Potentially add in active prodcuctId
                                favoriteProductIds:
                                    response?.favoriteProducts.filter((item) => item?.isActive) ||
                                    [],
                                allFavorites: response,
                            })
                        }
                    } catch (error) {
                        console.error(error)
                    } finally {
                        populateAccountFavorites &&
                            useAccountFavoritesStore.getState().populateAccountFavorites()
                    }
                } else {
                    console.error('Cannot populate favorites for unauthenticated user.')
                }
            },
            updateFavorites: async (favoriteType: FavoriteType, favoritesData: Favorites) => {
                try {
                    switch (favoriteType) {
                        case FavoriteType.Location: {
                            set({ favoriteLocationIds: favoritesData?.favoriteLocations })
                            return true
                        }
                        case FavoriteType.Product:
                            set({ favoriteProductIds: favoritesData?.favoriteProducts })
                            return true
                        case FavoriteType.Brand:
                            set({ favoriteBrandIds: favoritesData?.favoriteBrands })
                            return true
                        default:
                            return false
                    }
                } catch (error) {
                    console.error(
                        'There was an error updating favorites in useFavoriteActionsStore.ts',
                        error
                    )
                    return false
                }
            },
            toggleFavorite: async (
                favoriteType,
                id,
                brandId,
                shouldUpdateAccountStore = false,
                note = ''
            ) => {
                let toggled: boolean
                const isFav = isFavorited(id, favoriteType)

                const addFavoriteInput: InputFavorite =
                    favoriteType !== FavoriteType.Brand
                        ? { brandId, favoriteType, itemId: id, notes: note }
                        : { brandId: id, favoriteType, notes: note }
                const deleteFavoriteInput: InputFavorite =
                    favoriteType !== FavoriteType.Brand
                        ? {
                              brandId,
                              itemId: id,
                              notes: note,
                              isActive: false,
                              favoriteType,
                          }
                        : { brandId: id, favoriteType, notes: note, isActive: false }

                if (!isFav) {
                    const input: RequestInput<InputFavorite> = {
                        query: addFavoriteMutation,
                        args: {
                            input: addFavoriteInput,
                        },
                        file: 'useFavoriteActionsStore.ts',
                        endpoint: 'addFavorite',
                        publicQuery: false,
                    }
                    const { data, success } = await apiRequest<Favorites, InputFavorite>(input)
                    if (data) {
                        toggled = true
                        await get()
                            .updateFavorites(favoriteType, data)
                            .then(async (updated) => {
                                if (shouldUpdateAccountStore && updated)
                                    await get().populateFavoriteStore()
                            })
                    } else toggled = false
                    if (!success)
                        useAlerts.getState().addAlert({
                            id: 'favorite-added-error',
                            title: 'There was a problem adding your favorite.',
                            theme: 'error',
                            duration: 6,
                        })
                } else {
                    const input: RequestInput<InputFavorite> = {
                        query: updateFavoriteMutation,
                        args: {
                            input: deleteFavoriteInput,
                        },
                        file: 'useFavoriteActionsStore.ts',
                        endpoint: 'updateFavorite',
                        publicQuery: false,
                    }
                    const { data, success } = await apiRequest<Favorites, InputFavorite>(input)

                    if (success) {
                        get()
                            .updateFavorites(favoriteType, data)
                            .then((updated) => {
                                shouldUpdateAccountStore &&
                                    updated &&
                                    useSavedBoardsStore
                                        .getState()
                                        .populateBoardDetailPage(
                                            useSavedBoardsStore.getState().boardDetailPageData
                                        )
                            })

                        useAlerts.getState().addAlert({
                            id: 'favorite-deleted-success',
                            title: 'Favorite Removed',
                            theme: 'default',
                            duration: 6,
                        })
                    } else {
                        toggled = true
                        useAlerts.getState().addAlert({
                            id: 'favorite-deleted-error',
                            title: 'There was a problem deleting your favorite.',
                            theme: 'error',
                            duration: 6,
                        })
                    }
                }
                await get().populateFavoriteStore()
                return toggled
            },
            updateFavorite: async (
                favoriteType: FavoriteType,
                id: string,
                brandId: string,
                shouldUpdateAccountStore = true,
                note: string,
                isActive: boolean = true
            ) => {
                const updateFavoriteInput: InputFavorite =
                    favoriteType !== FavoriteType.Brand
                        ? { brandId, favoriteType, itemId: id, notes: note, isActive }
                        : { brandId: id, favoriteType, notes: note, isActive }
                const input: RequestInput<InputFavorite> = {
                    query: updateFavoriteMutation,
                    args: {
                        input: updateFavoriteInput,
                    },
                    file: 'useFavoriteActionsStore.ts',
                    endpoint: 'addUpdateFavorites',
                    publicQuery: false,
                }
                const { data, success } = await apiRequest<Favorites, InputFavorite>(input)
                if (success) {
                    get()
                        .updateFavorites(favoriteType, data)
                        .then((updated) => {
                            shouldUpdateAccountStore &&
                                updated &&
                                useSavedBoardsStore
                                    .getState()
                                    .populateBoardDetailPage(
                                        useSavedBoardsStore.getState().boardDetailPageData
                                    )
                        })
                } else {
                    useAlerts.getState().addAlert({
                        id: 'favorite-updated-error',
                        title: 'There was a problem updating your favorite.',
                        theme: 'error',
                        duration: 6,
                    })
                }
            },
            getUserFavorites: async () => {
                const input: RequestInput<unknown> = {
                    query: getFavoritesQuery,
                    args: {
                        input: {},
                    },
                    file: 'useFavoriteActionsStore.ts',
                    endpoint: 'getAuthenticatedUser',
                    publicQuery: false,
                }
                const { data, success } = await apiRequest<User, unknown>(input)
                return { response: data?.favorites, success }
            },
            clearStore: () => set(defaultData),
            favoriteModalOpen: false,
            setFavoriteModalOpen: (favoriteModalOpen) => set({ favoriteModalOpen }),
            activeFavoriteItem: null,
            setActiveFavoriteItem: (activeFavoriteItem) => set({ activeFavoriteItem }),
            activeFavoriteType: null,
            setActiveFavoriteType: (activeFavoriteType) => set({ activeFavoriteType }),
            favoritesNote: '',
            setFavoritesNote: (favoritesNote) => set({ favoritesNote }),
            shouldUpdateAccountStore: false,
            setShouldUpdateAccountStore: (shouldUpdateAccountStore) =>
                set({ shouldUpdateAccountStore }),
            activeFavoriteAction: null,
            setActiveFavoriteAction: (activeFavoriteAction) => set({ activeFavoriteAction }),
            favoriteIsChanged: false,
            setFavoriteIsChanged: (favoriteIsChanged) => set({ favoriteIsChanged }),
            updatedNote: '',
            setUpdatedNote: (updatedNote) => set({ updatedNote }),
            activeFavoriteItemState: ActiveFavoriteItemState.Active,
            setActiveFavoriteItemState: (activeFavoriteItemState) =>
                set({ activeFavoriteItemState }),
            allFavoritesBoxChecked: true,
            setAllFavoritesBoxChecked: (allFavoritesBoxChecked) => set({ allFavoritesBoxChecked }),
        }),
        { name: `useFavoriteActionsStore` }
    )
)
