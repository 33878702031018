const getSavedUserRoutesQuery = /* GraphQL */ `
query GetAuthenticatedUser {
  getAuthenticatedUser {
    routes {
      isActive
        id
        routeId
        description
        duration
        distance
        title
        coordinates {
            lat
            lon
            title
            placeName
            location {
              id
              brand {
                id
              }
            }
        }
        createdDate
        createdBy
        updatedDate
        lastUpdatedBy
    }
    givenName
    id
  }
}`

export default getSavedUserRoutesQuery
