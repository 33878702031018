import { mapboxGeoPoint, routeData } from '../types/MapTypes'

// Mapbox
import mapboxgl, { Coordinate } from 'mapbox-gl'
import { BreweryRouteGeoCoord, GeoCoords, Location } from 'src/types/schema/graphql'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { IDirectionLeg, IMapboxPlace } from '../types'
import { ICombinedData } from '../components/forYou/types/ICombinedData'

interface IListItem {
    content: string
    placeName: string
}
interface ISelectedWildcardOption {
    label: string
    value: string
    coordinates: number[]
    location?: Location
    type?: string
    id?: string
}
interface IPopupData {
    location: Location
}
const accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

interface IMapStore {
    mapReady: boolean
    setMapReady: (ready: boolean) => void
    resultsLoaded: boolean
    setResultsLoaded: (resultsLoaded: boolean) => void
    mapView: boolean
    setMapView: (mapView: boolean) => void
    activeTab: string
    setActiveTab: (activeTab: string) => void
    locations: Location[]
    setLocations: (locations: Location[]) => void
    suggestedAddresses: IMapboxPlace[]
    setSuggestedAddresses: (suggestedAddresses: IMapboxPlace[]) => void
    currentLocation: string
    setCurrentLocation: (currentLocation: string) => void
    searchAsMapMoves: boolean
    setSearchAsMapMoves: (searchAsMapMoves: boolean) => void
    userLocationState: mapboxGeoPoint
    setUserLocationState: (userLocationState: mapboxGeoPoint) => void
    wildcardText: string
    setWildcardText: (wildCardText: string) => void
    radius: {
        label: string
        value: number
    }
    fallbackRadius: { label: string; value: number }
    setFallbackRadius: (radiusBackup: { label: string; value: number }) => void
    setRadius: (radius: { label: string; value: number }) => void

    breweriesCount: number
    setBreweriesCount: (breweriesCount: number) => void
    profile: string
    setProfile: (profile: string) => void
    routeData: routeData
    setRouteData: (routeData: routeData) => void
    drawerOpen: boolean
    setDrawerOpen: (drawerOpen: boolean) => void
    showFilters: boolean
    setShowFilters: (showFilters: boolean) => void
    dropdownToggleIndex: number
    setDropdownToggleIndex: (dropdownToggleIndex: number) => void
    showStopAdd: boolean
    setShowStopAdd: (showStopAdd: boolean) => void
    locationDrawerItem: Location
    setLocationDrawerItem: (locationDrawerItem: Location) => void
    fullScreenShowFullMap: boolean
    setFullScreenShowFullMap: (fullScreenShowFullMap: boolean) => void
    selectedBreweryCoords: [number, number]
    setSelectedBreweryCoords: (selectedBreweryCoords: [number, number]) => void
    selectedBreweryRoutesTitle: string
    setSelectedBreweryRoutesTitle: (selectedBreweryRoutesTitle: string) => void
    selectedBreweryAddress: string
    setSelectedBreweryAddress: (selectedBreweryAddress: string) => void
    mapBounds: mapboxgl.LngLatBounds
    setMapBounds: (mapBounds: mapboxgl.LngLatBounds) => void
    brewCardClicked: boolean
    setBrewCardClicked: (brewCardClicked: boolean) => void
    locationQuery: string
    setLocationQuery: (locationQuery: string) => void
    directionCoordinates: BreweryRouteGeoCoord[]
    setDirectionsCoordinates: ({
        action,
        directionCoordinates,
        listItem,
        reorderedArray,
    }: {
        action: 'add' | 'remove' | 'reorder'
        directionCoordinates?: BreweryRouteGeoCoord
        listItem?: IListItem
        reorderedArray?: BreweryRouteGeoCoord[]
    }) => void
    searchTermQuery: string
    setSearchTermQuery: (searchTermQuery: string) => void
    directionLegs: IDirectionLeg[]
    setDirectionLegs: (directionLegs: IDirectionLeg[]) => void
    getAddressFromCoords: (latitude: number, longitude: number) => Promise<string>
    mobileDirectionsDrawerOpen: boolean
    setMobileDirectionsDrawerOpen: (mobileDirectionsDrawerOpen: boolean) => void
    getLocationAddresses: () => Promise<any>
    filteredIndependentCraftBreweries: Location[]
    setFilteredIndependentCraftBreweries: (filteredIndependentCraftBreweries: Location[]) => void
    mapRouteBounds: { _sw: { lng: number; lat: number }; _ne: { lng: number; lat: number } }
    setMapRouteBounds: (mapRouteBounds: {
        _sw: { lng: number; lat: number }
        _ne: { lng: number; lat: number }
    }) => void
    mapRouteGeoJson: mapboxgl.GeoJSONSourceRaw
    setMapRouteGeoJson: (mapRouteGeoJson: mapboxgl.GeoJSONSourceRaw) => void
    hoveredLocationCard: string
    setHoveredLocationCard: (hoveredLocationCard: string) => void
    wildcardSelectLocationOptions: Location[]
    setWildcardSelectionLocationOptions: (wildcardSelectLocationOptions: Location[]) => void
    wildcardInputLoading: boolean
    setWildcardInputLoading: (wildcardInputLoading: boolean) => void
    selectedWildcardOption: ISelectedWildcardOption
    setSelectedWildcardOption: (selectedWildcardOption: ISelectedWildcardOption) => void
    showMapLocationTitle: boolean
    setShowMapLocationTitle: (showMapLocationTitle: boolean) => void
    defaultCombinedData: ICombinedData[]
    setDefaultCombinedData: (defaultCombinedData: ICombinedData[]) => void
    defaultCombinedRouteInputData: ICombinedData[]
    setDefaultCombinedRouteInputData: (defaultCombinedRouteInputData: ICombinedData[]) => void
    popupData: IPopupData
    setPopupData: (popupData: IPopupData) => void
    distanceFromRouteSelection: { label: string; value: number }
    setDistanceFromRouteSelection: (distanceFromRouteSelection: {
        label: string
        value: number
    }) => void
    stepCoordinates: Coordinate[]
    setStepCoordinates: (stepCoordinates: Coordinate[]) => void
    onLoadCenterCoords: GeoCoords
    setOnLoadCenterCoords: (onLoadCenterCoords: GeoCoords) => void
}

export const mapStore = create<IMapStore>()(
    devtools(
        (set, get) => ({
            mapReady: false,
            setMapReady: (mapReady) => set({ mapReady }),
            resultsLoaded: false,
            setResultsLoaded: (resultsLoaded) => set({ resultsLoaded }),
            mapView: true,
            setMapView: (mapView) => set({ mapView }),
            activeTab: 'breweries',
            setActiveTab: (activeTab) => set({ activeTab }),
            locations: [],
            setLocations: (locations) => set({ locations }),
            filteredIndependentCraftBreweries: [],
            setFilteredIndependentCraftBreweries: (filteredIndependentCraftBreweries) =>
                set({ filteredIndependentCraftBreweries }),
            suggestedAddresses: [],
            setSuggestedAddresses: (suggestedAddresses) => set({ suggestedAddresses }),
            currentLocation: null,
            setCurrentLocation: (currentLocation) => set({ currentLocation }),
            searchAsMapMoves: true,
            setSearchAsMapMoves: (searchAsMapMoves) => set({ searchAsMapMoves }),
            userLocationState: null,
            setUserLocationState: (userLocationState) => set({ userLocationState }),
            wildcardText: '',
            setWildcardText: (wildcardText) => set({ wildcardText }),
            radius: { label: 'Map Bounds', value: 0 },
            setRadius: (radius) => set({ radius }),
            fallbackRadius: { label: '15 miles', value: 15 },
            setFallbackRadius: (fallbackRadius) => set({ fallbackRadius }),
            breweriesCount: 0,
            setBreweriesCount: (breweriesCount) => set({ breweriesCount }),
            profile: 'driving',
            setProfile: (profile) => set({ profile }),
            routeData: {
                distance: 0,
                hours: 0,
                minutes: 0,
            },
            setRouteData: (routeData) => set({ routeData }),
            drawerOpen: false,
            setDrawerOpen: (drawerOpen) => set({ drawerOpen }),
            showFilters: false,
            setShowFilters: (showFilters) => set({ showFilters }),
            dropdownToggleIndex: null,
            setDropdownToggleIndex: (dropdownToggleIndex) => set({ dropdownToggleIndex }),
            showStopAdd: true,
            setShowStopAdd: (showStopAdd) => set({ showStopAdd }),
            locationDrawerItem: null,
            setLocationDrawerItem: (locationDrawerItem) => set({ locationDrawerItem }),
            fullScreenShowFullMap: false,
            setFullScreenShowFullMap: (fullScreenShowFullMap) => set({ fullScreenShowFullMap }),
            selectedBreweryCoords: null,
            setSelectedBreweryCoords: (selectedBreweryCoords) => set({ selectedBreweryCoords }),
            selectedBreweryRoutesTitle: '',
            setSelectedBreweryRoutesTitle: (selectedBreweryRoutesTitle) =>
                set({ selectedBreweryRoutesTitle }),
            selectedBreweryAddress: '',
            setSelectedBreweryAddress: (selectedBreweryAddress) => set({ selectedBreweryAddress }),
            mapBounds: null,
            setMapBounds: (mapBounds) => set({ mapBounds }),
            brewCardClicked: false,
            setBrewCardClicked: (brewCardClicked) => set({ brewCardClicked }),
            locationQuery: '',
            setLocationQuery: (locationQuery) => set({ locationQuery }),
            directionCoordinates: [],
            setDirectionsCoordinates: ({
                action,
                directionCoordinates,
                listItem,
                reorderedArray,
            }) => {
                set((prevState) => {
                    if (action === 'add') {
                        return {
                            directionCoordinates: [
                                ...prevState.directionCoordinates,
                                directionCoordinates,
                            ],
                        }
                    } else if (action === 'remove') {
                        return {
                            directionCoordinates: prevState.directionCoordinates.filter(
                                (item) => item.placeName !== listItem.placeName
                            ),
                        }
                    } else {
                        return {
                            directionCoordinates: reorderedArray,
                        }
                    }
                })
            },
            searchTermQuery: '',
            setSearchTermQuery: (searchTermQuery) => set({ searchTermQuery }),
            directionLegs: [],
            setDirectionLegs: (directionLegs) => set({ directionLegs }),
            getAddressFromCoords: async (latitude: number, longitude: number) => {
                const res = await fetch(
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?limit=1&access_token=${accessToken}
                    `
                )
                const json = await res.json()
                return json.features[0].place_name
            },

            mobileDirectionsDrawerOpen: false,
            setMobileDirectionsDrawerOpen: (mobileDirectionsDrawerOpen) =>
                set({ mobileDirectionsDrawerOpen }),
            getLocationAddresses: async () => {
                if (get().locationQuery) {
                    await fetch(
                        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                            get().locationQuery
                        )}.json?access_token=${accessToken}&limit=25&autocomplete=true&country=US`
                    )
                        .then((response) => response.text())
                        .then((res) => JSON.parse(res))
                        .then((json) => {
                            const places: IMapboxPlace[] = json.features.filter(
                                (item) => item.place_type[0] !== 'postcode'
                            )
                            get().setSuggestedAddresses(places)
                        })
                        .catch((err) => console.error({ err }))
                }
            },
            mapRouteBounds: null,
            setMapRouteBounds: (mapRouteBounds) => set({ mapRouteBounds }),
            mapRouteGeoJson: null,
            setMapRouteGeoJson: (mapRouteGeoJson) => set({ mapRouteGeoJson }),
            hoveredLocationCard: null,
            setHoveredLocationCard: (hoveredLocationCard) => set({ hoveredLocationCard }),
            wildcardSelectLocationOptions: null,
            setWildcardSelectionLocationOptions: (wildcardSelectLocationOptions: Location[]) =>
                set({ wildcardSelectLocationOptions }),
            wildcardInputLoading: false,
            setWildcardInputLoading: (wildcardInputLoading: boolean) =>
                set({ wildcardInputLoading }),
            selectedWildcardOption: null,
            setSelectedWildcardOption: (selectedWildcardOption: ISelectedWildcardOption) =>
                set({ selectedWildcardOption }),
            showMapLocationTitle: false,
            setShowMapLocationTitle: (showMapLocationTitle: boolean) =>
                set({ showMapLocationTitle }),
            defaultCombinedData: [],
            setDefaultCombinedData: (defaultCombinedData) => set({ defaultCombinedData }),
            defaultCombinedRouteInputData: [],
            setDefaultCombinedRouteInputData: (defaultCombinedRouteInputData) =>
                set({ defaultCombinedRouteInputData }),
            popupData: null,
            setPopupData: (popupData: IPopupData) => set({ popupData }),
            distanceFromRouteSelection: { label: '2 miles', value: 2 },
            setDistanceFromRouteSelection: (distanceFromRouteSelection) =>
                set({ distanceFromRouteSelection }),
            stepCoordinates: [],
            setStepCoordinates: (stepCoordinates) => set({ stepCoordinates }),
            onLoadCenterCoords: null,
            setOnLoadCenterCoords: (onLoadCenterCoords) => set({ onLoadCenterCoords }),
        }),
        { name: 'mapStore' }
    )
)

// Leaving this for potential typescript generic opportunities in the future
// export enum Actions {
//     Add = 'add',
//     Remove = 'remove',
//     Reorder = 'reorder',
// }
// export function setCoords<T = Actions>(
//     coords: T extends Actions.Add
//         ? IDirectionCoordinates
//         : T extends Actions.Remove
//         ? IDirectionCoordinates
//         : IDirectionCoordinates[]
// ) {
//     return null
// }

// setCoords<Actions.Add>(mapStore.getState()?.directionCoordinates[0])
