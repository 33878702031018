import * as UI from '@brewlogix/blx-ui'
import * as CC from '@chakra-ui/react'
import mapboxgl from 'mapbox-gl'
import React from 'react'
import { useSavedRoutesStore } from 'src/features/Map/state/useSavedRoutesStore'
import { addUpdateRouteMarkers, drawMapRoute } from 'src/features/Map/utils/functions'
import useMapFunctions from 'src/features/Map/utils/functions/useMapFunctions'
import { BreweryRouteGeoCoord } from 'src/types/schema/graphql'
import { mapStyle } from '../../../../../util/constants'

const DeleteRoute: React.FC = () => {
    // Saved Routes Store
    const deleteBreweryRoute = useSavedRoutesStore((state) => state.deleteBreweryRoute)
    const populateRoutes = useSavedRoutesStore((state) => state.populateRoutes)
    const deleteRouteModalOpen = useSavedRoutesStore((state) => state.deleteRouteModalOpen)
    const setDeleteRouteModalOpen = useSavedRoutesStore((state) => state.setDeleteRouteModalOpen)
    const activeSavedRoute = useSavedRoutesStore((state) => state.activeSavedRoute)

    const { getRouteGeoJson } = useMapFunctions()
    const mapContainer = React.useRef<mapboxgl.Map>()

    const { coordinates, id } = activeSavedRoute

    const setMapContainer = React.useCallback(
        (element) => {
            if (element) {
                const map = new mapboxgl.Map({
                    container: element,
                    style: mapStyle,
                    accessToken: process.env.NEXT_PUBLIC_MAPBOX_TOKEN,
                    minZoom: 4,
                    fitBoundsOptions: { padding: { top: 50, bottom: 20, left: 20, right: 50 } },
                    interactive: false,
                })
                mapContainer.current = map
                const parsedCoords: BreweryRouteGeoCoord[] = coordinates.map(
                    (coord: BreweryRouteGeoCoord) => ({
                        ...coordinates,
                        lat: coord.lat,
                        lon: coord.lon,
                        title: coord.title,
                        address: coord.address,
                    })
                )

                mapContainer?.current?.on('load', () => {
                    ;(async () =>
                        await getRouteGeoJson(parsedCoords)
                            .then((json) => {
                                drawMapRoute(json, mapContainer)
                                addUpdateRouteMarkers(mapContainer?.current, activeSavedRoute)
                            })
                            .catch((err) =>
                                console.log('there has been an error saved route card', err)
                            ))()
                })
            }
        },
        [mapContainer]
    )

    const handleDeleteRoute = async () => {
        await deleteBreweryRoute(id).then((res) => {
            if (res) populateRoutes()
            setDeleteRouteModalOpen(false)
        })
    }

    return (
        <CC.Modal
            isOpen={deleteRouteModalOpen}
            onClose={() => setDeleteRouteModalOpen(false)}
            isCentered={true}
            size='xl'
        >
            <CC.ModalOverlay />
            <CC.ModalContent>
                <CC.ModalHeader>Remove from Favorites</CC.ModalHeader>
                <CC.ModalCloseButton />
                <CC.ModalBody p={0} pl={6}>
                    <p>
                        Are you sure you want to remove this route from your favorites? This will
                        also remove it from all other boards.
                    </p>
                </CC.ModalBody>
                <CC.ModalFooter w='100%' display='flex' width='full' justifyContent='end'>
                    <UI.Button
                        onClick={() => setDeleteRouteModalOpen(false)}
                        size='sm'
                        customClasses='mr-3'
                        label='Cancel'
                    />
                    <UI.Button onClick={handleDeleteRoute} size='sm' theme='error' label='Delete' />
                </CC.ModalFooter>
            </CC.ModalContent>
        </CC.Modal>
    )
}
export default DeleteRoute
