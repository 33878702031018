import { API, Auth } from 'aws-amplify'
import { Alert } from 'src/features/alerts/state/useAlerts'
import { GraphQLResponse } from './types'

export type RequestInput<A> = {
    query: string
    args: Record<string, A>
    file: string
    endpoint?: string
    noAlerts?: boolean
    publicQuery?: boolean
}

export async function apiRequest<T, A>({
    query,
    args,
    file,
    endpoint,
    noAlerts = true,
    publicQuery = false,
}: RequestInput<A>): Promise<GraphQLResponse<T>> {
    const showAlerts = process.env.NODE_ENV === 'development' && !noAlerts
    endpoint = endpoint || query.split('\n').slice(0, 2).join(', ')
    try {
        const session = !publicQuery && (await Auth.currentSession())
        const res: GraphQLResponse<T> = await API.graphql(
            publicQuery
                ? { query, variables: args, authMode: 'AWS_IAM' }
                : {
                      query,
                      variables: args,
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                      authToken: session.getAccessToken().getJwtToken(),
                  }
        )

        if (res?.errors) {
            console.error('apiRequest failure:', `${endpoint} in ${file}`, res?.errors)
            showAlerts &&
                new Alert({
                    id: `api-request-error__${file}--${new Date().getTime()}`,
                    title: 'Oops! Request failed.',
                    description: `There was a problem with ${endpoint} in ${file}`,
                    theme: 'error',
                    duration: 10,
                })
        } else {
            showAlerts &&
                new Alert({
                    id: `api-request-success__${endpoint}--${new Date().getTime()}`,
                    title: `Nice! Your api call ${endpoint} in ${file} was successful`,
                    theme: 'success',
                    duration: 6,
                })
        }

        return { success: true, data: Object.values(res.data)[0], error: res?.errors }
    } catch (error) {
        console.error('apiRequest failure caught:', `${endpoint} in ${file}`, error)
        showAlerts &&
            new Alert({
                id: `api-request-catch-error__${file}--${new Date().getTime()}`,
                title: 'Oops! Request failed.',
                description: `There was a problem with ${endpoint} in ${file}`,
                theme: 'error',
                duration: 10,
            })

        return { success: false, data: null, error }
    }
}
