export const daysOfWeek = [
    {
        label: 'Sun',
        name: 'Sunday',
    },
    {
        label: 'Mon',
        name: 'Sunday',
    },
    {
        label: 'Tue',
        name: 'Sunday',
    },
    {
        label: 'Wed',
        name: 'Sunday',
    },
    {
        label: 'Thu',
        name: 'Sunday',
    },
    {
        label: 'Fri',
        name: 'Sunday',
    },
    {
        label: 'Sat',
        name: 'Sunday',
    },
]
