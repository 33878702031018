import { Brand, CompositeProduct, InputGetById, Location } from 'src/types/schema/graphql'
import { getBrandById, getProductById } from 'src/features/account/queries'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { useFavoriteActionsStore } from '../../favorites/state/useFavoriteActionsStore'
import { RequestInput, apiRequest } from 'src/api/apiRequest'
import { getLocationByIdQuery } from 'src/features/saved/utils/graph/getLocationByIdQuery'

interface IuseAccountFavoritesStore {
    favoriteBrands: Brand[]
    favoriteProducts: CompositeProduct[]
    filteredProducts: CompositeProduct[]
    setFilteredProducts: (product: CompositeProduct[]) => void
    getProducts: () => Promise<CompositeProduct[]>
    getLocations: () => Promise<Location[]>
    favoriteLocations: Location[]
    populateAccountFavorites: () => Promise<void>
    favoritesLoading: boolean
    setFavoritesLoading: (favoritesLoading: boolean) => void
    productsLoading: boolean
    locationsLoading: boolean
    filteredLocations: Location[]
    setFilteredLocations: (locations: Location[]) => void
    getBrands: () => Promise<Brand[]>
    filteredBrands: Brand[]
    setFilteredBrands: (brands: Brand[]) => void
    brandsLoading: boolean
}

export const useAccountFavoritesStore = create<IuseAccountFavoritesStore>()(
    devtools(
        (set, get) => ({
            favoriteBrands: [],
            favoriteProducts: [],
            getProducts: async () => {
                const prodArr = useFavoriteActionsStore.getState().favoriteProductIds
                const arr: CompositeProduct[] = await Promise.all(
                    prodArr?.map(async ({ brandId, productId }) => {
                        const input: RequestInput<InputGetById> = {
                            query: getProductById,
                            args: {
                                input: {
                                    itemId: productId,
                                    parentId: brandId,
                                },
                            },
                            file: 'useFavoriteActionsStore.ts',
                            endpoint: 'getProductById',
                            publicQuery: true,
                            noAlerts: true,
                        }
                        const { data } = await apiRequest<CompositeProduct, InputGetById>(input)
                        return data
                    })
                )
                set({ favoriteProducts: arr, filteredProducts: arr, productsLoading: false })
                return arr
            },
            getLocations: async () => {
                const userStoreLocationsArr = useFavoriteActionsStore.getState().favoriteLocationIds
                const arr: Location[] = await Promise.all(
                    userStoreLocationsArr?.map(async ({ locationId, brandId }) => {
                        const input: RequestInput<InputGetById> = {
                            query: getLocationByIdQuery,
                            args: {
                                input: {
                                    itemId: locationId,
                                    parentId: brandId,
                                },
                            },
                            file: 'useAccountFavoritesStore.ts',
                            endpoint: 'getLocationById',
                            // publicQuery: true,
                        }
                        const { data } = await apiRequest<Location, InputGetById>(input)
                        return data
                    })
                )
                set({
                    favoriteLocations: arr,
                    filteredLocations: arr,
                    locationsLoading: false,
                })
                return arr
            },
            filteredProducts: [],
            setFilteredProducts: (filteredProducts) => set({ filteredProducts }),
            favoriteLocations: [],

            getBrands: async () => {
                const favBrands = useFavoriteActionsStore.getState().favoriteBrandIds
                const arr: Brand[] = await Promise.all(
                    favBrands?.map(async ({ brandId }) => {
                        const input: RequestInput<InputGetById> = {
                            query: getBrandById,
                            args: {
                                input: {
                                    itemId: brandId,
                                },
                            },
                            file: 'useAccountFavoritesStore.ts',
                            endpoint: 'getBrandById',
                            publicQuery: true,
                        }
                        const { data } = await apiRequest<Brand, InputGetById>(input)
                        return data
                    })
                )
                set({ favoriteBrands: arr, filteredBrands: arr, brandsLoading: false })
                return arr
            },
            favoritesLoading: true,

            setFavoritesLoading: (favoritesLoading) => set({ favoritesLoading }),

            populateAccountFavorites: async () => {
                get().setFavoritesLoading(true)
                await Promise.all([get()?.getProducts(), get()?.getLocations(), get()?.getBrands()])
                    .catch((err) => console.error('There was a problem populating favorites', err))
                    .finally(() => get()?.setFavoritesLoading(false))
            },
            productsLoading: true,
            locationsLoading: true,
            filteredLocations: [],
            setFilteredLocations: (filteredLocations) => set({ filteredLocations }),
            filteredBrands: [],
            setFilteredBrands: (filteredBrands) => set({ filteredBrands }),
            brandsLoading: true,
        }),
        { name: `useAccountFavoritesStore` }
    )
)
