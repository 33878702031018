import { gql } from 'graphql-request'

export const getLocationByIdQuery = gql`query GetLocationById($input: inputGetById!) {
	getLocationById(input: $input){
        __typename
		guid
        slug
        description
        locationHighlights {
            headline
            customHeadline
            description
        }
        title
        id
        brand {
            id
            title
            displayTitle
            description
            slug
            website
            yearEstablished
            
            socialMedia {
                handle
                platform
            }
            assets {
                __typename
                id
                alt
                type
                caption
                ... on S3Asset {
                    path
                }
                ... on CloudinaryAsset {
                    cloudinaryId
                }
            }
            traits {
                isIndependent
                brandTypes
                brewTypes 
            }
        }
        links {
            label
            target
            url
        }
        address {
            city    
            country
            geo {
                lat
                lon
            }
            postalCode
            street
            state
            street2
        }
        contact {
            phone
        }
        hours {
            day
            isOpen
            hours
        }
        assets {
            __typename
            id
            alt
            type
            caption
            ... on S3Asset {
                path
            }
            ... on CloudinaryAsset {
                cloudinaryId
            }
        }
        yearOpened
        yearClosed
        productRelationships {
            location {
                id
            }
            product {
                __typename
                id 
                title
            }
            available
            canned
            growler
            onTap
        }
        locationHighlights {
            description
        }
        traits {
            brewOptions
            foodOptions
            miscActivity
            entertainmentActivity
            educationalActivity
            gameActivity
            physicalActivity
            nearbyAttractions{
                title
                approximateDistance
            }
            patronOptions 
            seatingOptions
            tourInfo {
                cost
                toursAvailable
                description
                mustBeLegalDrinkingAge
                hours {
                    day
                    isOpen
                    hours
                }
            }
            isPrimary
            status
            type
        }
	}
}`
