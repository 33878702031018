export const parseBDBEnvSubdomain = (host: string): string => {
    if (
        typeof host === 'string' &&
        (host.includes('vercel') ||
            host.includes('dev') ||
            host.includes('localhost') ||
            host.includes('qa'))
    ) {
        return 'dev'
    } else {
        return 'prod'
    }
}
