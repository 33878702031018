import React from 'react'
import * as UI from '@brewlogix/blx-ui'
import * as CC from '@chakra-ui/react'
import { useSavedBoardsStore } from 'src/features/favorites/state/useSavedBoardsStore'
import { useRouter } from 'next/router'

interface IDeleteBoardModal {}

const DeleteBoardModal: React.FC<IDeleteBoardModal> = () => {
    const deleteBoardModalOpen = useSavedBoardsStore((state) => state.deleteBoardModalOpen)
    const setDeleteBoardModalOpen = useSavedBoardsStore((state) => state.setDeleteBoardModalOpen)
    const deleteBoard = useSavedBoardsStore((state) => state.deleteBoard)
    const boardDetailPageData = useSavedBoardsStore((state) => state.boardDetailPageData)
    const router = useRouter()
    const handleClick = async () => {
        setDeleteBoardModalOpen(true)
        const success = await deleteBoard(boardDetailPageData?.id)
        if (success) {
            setDeleteBoardModalOpen(false)
            router.push('/saved')
        } else {
            console.log('error deleting board', boardDetailPageData)
        }
    }
    return (
        <CC.Modal
            isOpen={deleteBoardModalOpen}
            onClose={() => setDeleteBoardModalOpen(false)}
            isCentered={true}
            size='xl'
        >
            <CC.ModalOverlay />
            <CC.ModalContent className='space-y-3' p={6}>
                <CC.ModalHeader p={0}>Delete Board</CC.ModalHeader>
                <CC.ModalCloseButton />
                <CC.ModalBody p={0}>Are you sure you want to delete this Board?</CC.ModalBody>
                <CC.ModalFooter p={0}>
                    <CC.HStack gap={3}>
                        <CC.Button
                            onClick={() => setDeleteBoardModalOpen(false)}
                            aria-label='Cancel'
                            size='sm'
                        >
                            Cancel
                        </CC.Button>
                        <CC.Button
                            onClick={handleClick}
                            aria-label='Create board'
                            size='sm'
                            variant='error'
                        >
                            Yes, Delete
                        </CC.Button>
                    </CC.HStack>
                </CC.ModalFooter>
            </CC.ModalContent>
        </CC.Modal>
    )
}
export default DeleteBoardModal
