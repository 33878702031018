import {
    BrewOption,
    FoodOption,
    GeoBoxSearchInput,
    GeoRadiusSearchInput,
    InputGeoPolygonSearch,
    InputLocationHours,
    InputSearchBpl,
    Location,
    LocationStatus,
    OpenSearchType,
    PatronOption,
    SeatingOption,
    TimeZone,
    WildCardTitleSearchConnection,
} from 'src/types/schema/graphql'

import { ViewportProps } from 'react-map-gl'
import { apiRequest, RequestInput } from 'src/api/apiRequest'
import searchBPLLocations from 'src/features/locations/utils/graph/searchBPLLocations'
import { mapStore } from '../../state/mapStore'
import { Coordinate } from 'mapbox-gl'

interface ISearchLocationsQuery {
    wildcardText?: string
    viewport?: ViewportProps
    bounds?: any
    geoRadiusSearch?: {
        geoRadius: number
        geoPoint: {
            lat: number
            lon: number
        }
    }
    geoBoxSearch?: {
        geoSWPoint: {
            lat: number
            lon: number
        }
        geoNEPoint: {
            lat: number
            lon: number
        }
    }
    geoPolygonSearch?: InputGeoPolygonSearch
    radius?: {
        label: string
        value: number
    }
    patronFilterOptions?: PatronOption[]
    foodFilterOptions?: FoodOption[]
    seatingFilterOptions?: SeatingOption[]
    brewFilterOptions?: BrewOption[]
    locationHours?: {
        currentlyOpen: boolean
    }
    stateFilter?: {
        label: string
    }
    searchAsMapMoves?: boolean
    distanceFromRouteSelection?: {
        label: string
        value: number
    }
    stepCoordinates?: Coordinate[]
}

export const searchLocations = async (query: ISearchLocationsQuery) => {
    const parsedTimezone: TimeZone = Intl.DateTimeFormat()
        .resolvedOptions()
        .timeZone.replaceAll('/', '___') as TimeZone
    const {
        currentLocation,
        setLocations,
        setBreweriesCount,
        setFilteredIndependentCraftBreweries,
        setWildcardSelectionLocationOptions,
        directionCoordinates,
        activeTab,
    } = mapStore.getState()

    let geoRadiusSearch: GeoRadiusSearchInput
    let geoBoxSearch: GeoBoxSearchInput
    let geoPolygonSearch: InputGeoPolygonSearch
    if (query.viewport && currentLocation !== 'Anywhere') {
        if (query.radius?.label !== 'Map Bounds') {
            geoRadiusSearch = {
                geoRadius: query.radius?.value || 25,
                geoPoint: {
                    lat: query.viewport.latitude,
                    lon: query.viewport.longitude,
                },
            }
        } else {
            if (query.searchAsMapMoves && query.bounds) {
                if (query.bounds && query.bounds._ne && query.bounds._sw) {
                    geoBoxSearch = {
                        geoSWPoint: {
                            lat: query.bounds._sw.lat,
                            lon: query.bounds._sw.lng,
                        },
                        geoNEPoint: {
                            lat: query.bounds._ne.lat,
                            lon: query.bounds._ne.lng,
                        },
                    }
                }
            }
        }
    }
    if (directionCoordinates.length > 1 && activeTab === 'routes') {
        if (query.stepCoordinates.length === 0) return null
        geoRadiusSearch = null
        geoBoxSearch = null
        geoPolygonSearch = {
            geoPoints: query?.stepCoordinates?.map((coord) => {
                if (coord?.[0] && coord?.[1])
                    return {
                        lat: coord[1],
                        lon: coord[0],
                    }
            }),
            geoRadius: query?.distanceFromRouteSelection?.value || 2,
        }
    }

    const input: RequestInput<InputSearchBpl> = {
        query: searchBPLLocations,
        args: {
            input: {
                limit: 150,
                typeFilters: [OpenSearchType.Location],
                search: {
                    wildcardText: query?.wildcardText || '',
                    searchFieldsLocations: {
                        state:
                            query?.stateFilter.label !== 'All' ? query?.stateFilter?.label : null,
                        brewOptions: query?.brewFilterOptions,
                        seatingOptions: query?.seatingFilterOptions,
                        foodOptions: query?.foodFilterOptions,
                        patronOptions: query?.patronFilterOptions,
                        locationHours: {
                            currentlyOpen: query?.locationHours?.currentlyOpen,
                        },
                        geoRadiusSearch,
                        geoBoxSearch,
                        geoPolygonSearch,
                        status: LocationStatus.OpenForBusiness,
                    },
                },
                nextToken: null,
            },
        },
        file: 'searchLocations.ts',
        endpoint: 'searchBpl',
        publicQuery: true,
        noAlerts: true,
    }
    const { data } = await apiRequest<WildCardTitleSearchConnection, InputSearchBpl>(input)

    if (data) {
        const locationResults: Location[] = data?.items.filter((result: Location) =>
            query.stateFilter.label === 'All'
                ? true
                : result.address?.state === query.stateFilter.label
        )
        const openLocations = locationResults?.filter((item) => item?.traits?.isOpenToPublic)
        setLocations(openLocations)
        setWildcardSelectionLocationOptions(locationResults.slice(0, 5))
        setFilteredIndependentCraftBreweries(
            locationResults.filter(
                (result: Location) => result.brand?.traits?.isIndependent === true
            )
        )

        setBreweriesCount(locationResults?.length)
    }
}
