import mapboxgl from 'mapbox-gl'
import { mapStore } from '../../state/mapStore'
import { loadRouteMarkerIcons } from './loadRouteMarkerIcons'

export const addUpdateRouteMarkersFromUserLocation = (mapRef: mapboxgl.Map) => {
    const { locations, directionCoordinates, userLocationState } = mapStore.getState()
    const center: mapboxgl.LngLatLike = [userLocationState.lng, userLocationState.lat]
    const bounds = new mapboxgl.LngLatBounds(center, center)

    if (locations.length > 0) {
        locations.forEach((post) => {
            if (post?.address?.geo?.lat && post?.address?.geo?.lon)
                bounds.extend(new mapboxgl.LngLat(post.address.geo.lon, post.address.geo.lat))
        })
    }
    if (mapRef) {
        if (mapRef.getLayer('routeMarkers')) {
            mapRef.setLayoutProperty('routeMarkers', 'visibility', 'visible')
        }
        if (mapRef.getLayer('endStopMarker')) {
            mapRef.setLayoutProperty('endStopMarker', 'visibility', 'visible')
        }
        loadRouteMarkerIcons(mapRef)

        const geoJsonDirectionCoordinates: mapboxgl.GeoJSONSourceRaw = {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: directionCoordinates?.map((loc) => ({
                    type: 'Feature',
                    properties: {
                        id: loc?.placeName,
                        title: loc?.title,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [loc?.lon, loc?.lat],
                    },
                })),
            },
            cluster: false,
        }
        if (directionCoordinates?.length > 1) {
            const lastDirectionCoordinate = directionCoordinates[directionCoordinates?.length - 1]
            const endStopGeoJson: mapboxgl.GeoJSONSourceRaw = {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            properties: {
                                id: lastDirectionCoordinate?.placeName,
                                title: lastDirectionCoordinate?.title,
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: [
                                    lastDirectionCoordinate?.lon,
                                    lastDirectionCoordinate?.lat,
                                ],
                            },
                        },
                    ],
                },
                cluster: false,
            }
            const endStopSource: mapboxgl.AnySourceImpl = mapRef?.getSource(
                'endStop'
            ) as mapboxgl.GeoJSONSource

            if (!endStopSource) mapRef.addSource('endStop', endStopGeoJson)
            else endStopSource.setData(endStopGeoJson.data as keyof mapboxgl.GeoJSONSource)
        }
        const routeStopSource: mapboxgl.GeoJSONSourceRaw | any = mapRef.getSource('routeStops')
        if (!routeStopSource) mapRef.addSource('routeStops', geoJsonDirectionCoordinates)
        else routeStopSource.setData(geoJsonDirectionCoordinates.data)

        if (!mapRef.getLayer('routeMarkers'))
            mapRef.addLayer({
                id: 'routeMarkers',
                type: 'symbol',
                source: 'routeStops',
                layout: {
                    'icon-image': 'startMarkerIcon',
                    'icon-size': 0.25,
                    'icon-allow-overlap': true,
                },
            })

        if (!mapRef.getLayer('endStopMarker'))
            if (mapRef.getSource('endStop'))
                mapRef.addLayer({
                    id: 'endStopMarker',
                    type: 'symbol',
                    source: 'endStop',
                    layout: {
                        'icon-image': 'endMarkerIcon',
                        'icon-size': 0.25,
                        'icon-allow-overlap': true,
                    },
                })
        if (mapRef?.getLayer('routeLayer'))
            mapRef.setLayoutProperty('routeLayer', 'visibility', 'visible')

        if (mapRef?.getLayer('routeStopsLayer'))
            mapRef.setLayoutProperty('routeStopsLayer', 'visibility', 'visible')
    }
}
