import mapboxgl from 'mapbox-gl'
import { mapStore } from '../../state/mapStore'
import { createGeoJSONCircle } from './createGeoJSONCircle'

export const addUpdateRadiusLayers = (mapRef: mapboxgl.Map) => {
    const { searchAsMapMoves, radius, userLocationState, activeTab } = mapStore.getState()
    const miToKm = (mi: number) => mi * 1.60934
    if (!searchAsMapMoves && activeTab === 'breweries') {
        if (mapRef?.getLayer('polygon')) {
            mapRef.setLayoutProperty('polygon', 'visibility', 'visible')
            mapRef.setLayoutProperty('polygonBorder', 'visibility', 'visible')
        }
        if (!mapRef?.getSource('polygon')) {
            mapRef?.addSource(
                'polygon',
                createGeoJSONCircle(
                    { longitude: +userLocationState.lng, latitude: +userLocationState.lat },
                    miToKm(radius?.value)
                )
            )
        } else {
            const source = mapRef?.getSource('polygon') as mapboxgl.GeoJSONSource
            source.setData(
                createGeoJSONCircle(
                    { longitude: +userLocationState.lng, latitude: +userLocationState.lat },
                    miToKm(radius?.value)
                ).data as keyof mapboxgl.GeoJSONSource
            )
        }

        if (mapRef?.getSource('polygon')) {
            if (!mapRef?.getLayer('polygon'))
                mapRef?.addLayer(
                    {
                        id: 'polygon',
                        type: 'fill',
                        source: 'polygon',
                        layout: {},
                        paint: {
                            'fill-color': '#5D810E',
                            'fill-opacity': 0.15,
                            'fill-outline-color': '#5D810E',
                        },
                    },
                    'clusters'
                )
            if (!mapRef?.getLayer('polygonBorder'))
                mapRef?.addLayer(
                    {
                        id: 'polygonBorder',
                        type: 'line',
                        source: 'polygon',
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round',
                            visibility: 'visible',
                        },
                        paint: {
                            'line-color': '#5D810E',
                            'line-width': 1,
                            'line-opacity': 1,
                        },
                    },
                    'clusters'
                )
        }
    } else {
        if (mapRef?.getLayer('polygon')) {
            mapRef.setLayoutProperty('polygon', 'visibility', 'none')
            mapRef.setLayoutProperty('polygonBorder', 'visibility', 'none')
        }
    }
}
