import React from 'react'
import mapboxgl, { LngLatLike } from 'mapbox-gl'
import { useFilterStore } from '../../state/filterStore'

const { setStateFilter, setPastStates, setFilterCount, allStates } = useFilterStore.getState()
export const handleStateClick = (e: mapboxgl.MapMouseEvent, mapObj: mapboxgl.Map) => {
    if (mapObj?.isStyleLoaded()) {
        mapObj?.setLayoutProperty('state-border-click', 'visibility', 'visible')
        mapObj?.setLayoutProperty('state-borders', 'visibility', 'none')
        const featureStates = mapObj?.queryRenderedFeatures(e.point, {
            layers: ['state-fills'],
        }) as mapboxgl.MapboxGeoJSONFeature[]
        const feature = featureStates[0] as mapboxgl.MapboxGeoJSONFeature | any
        const coordinates: LngLatLike[] = feature?.geometry?.coordinates[0]
        const bounds = coordinates?.reduce(
            (bounds, coord) => bounds.extend(coord),
            new mapboxgl.LngLatBounds(coordinates[0], coordinates[1])
        )
        mapObj?.jumpTo({
            center: bounds?.getCenter(),
        })
        if (featureStates?.length > 0) {
            const featureStateId = featureStates[0]?.id
            allStates?.forEach((allState) => {
                if (allState !== featureStates[0]?.id) {
                    mapObj?.setFeatureState({ source: 'states', id: allState }, { click: false })
                } else {
                    mapObj?.setFeatureState(
                        { source: 'states', id: String(featureStates[0]?.id) },
                        { click: true }
                    )
                }
            })
            setStateFilter({
                label: featureStates[0].properties?.STATE_NAME,
                value: +featureStateId,
            })
            setPastStates(+featureStateId)
            setFilterCount()
        }
    }
}
