import { gql } from 'graphql-request'

export const getBoardByIdQuery = gql`
query GetBoardById($input: inputGetById!) {
	getBoardById(input: $input){
		createdBy
		createdDate
		id
		lastUpdatedBy
		title
		description
		updatedDate
		isPrivate
		favorites {
			brands
			favoriteBrands {
				brandId
				isActive
				notes
			}
			favoriteLocations {
				locationId
				isActive
				brandId
				notes
			}
			favoriteProducts {
				brandId
				isActive
				notes
				productId
			}
			locations {
				brandId
				locationId
			}
			products {
				brandId
				productId
			}
		}
		routes {
          id
		  isActive
          createdBy
            createdDate
            description
            distance
            duration
            id
            lastUpdatedBy
            notes
            routeId
            title
            updatedDate
            coordinates {
                address {
                    city
                    country
                    geo {
                        lat
                        lon
                        title
                    }
                    postalCode
                    state
                    street
                    street2
                }
                lat
                lon
                placeName
                title
                location {
                    title
                    id
                    slug
                    address {
						city
						country
						geo {
                            lat
                            lon
                            title
                        }
						postalCode
						state
						street
						street2
					}
                }
            }
      }
	}
}`
