import * as CC from '@chakra-ui/react'
import * as HeroIcons from '@heroicons/react/solid'
import classNames from 'classnames'
import React from 'react'
import { SavedBoardsList } from 'src/features/favorites/components'
import { useFavoriteActionsStore } from 'src/features/favorites/state/useFavoriteActionsStore'
import { useSavedBoardsStore } from 'src/features/favorites/state/useSavedBoardsStore'
import { coordinatesToInput } from 'src/features/favorites/utils/helpers'
import { getFavoriteModalHeader } from 'src/features/favorites/utils/helpers/getFavoriteModalHeader'
import { InputBreweryRouteGeoCoordInput } from 'src/types/schema/graphql'
import { mapStore } from '../../../state/mapStore'
import { useSavedRoutesStore } from '../../../state/useSavedRoutesStore'

const SaveRoutes: React.FC = () => {
    const setSavedRouteModalOpen = useSavedRoutesStore((state) => state.setSavedRouteModalOpen)
    const savedRouteModalOpen = useSavedRoutesStore((state) => state.savedRouteModalOpen)
    const title = useSavedRoutesStore((state) => state.title)
    const setTitle = useSavedRoutesStore((state) => state.setTitle)
    const description = useSavedRoutesStore((state) => state.description)
    const setDescription = useSavedRoutesStore((state) => state.setDescription)
    const createBreweryRoute = useSavedRoutesStore((state) => state.createBreweryRoute)
    const updateBreweryRoute = useSavedRoutesStore((state) => state.updateBreweryRoute)
    const activeSavedRoute = useSavedRoutesStore((state) => state.activeSavedRoute)
    const parsedRoutes = useSavedRoutesStore((state) => state.parsedRoutes)
    const populateRoutes = useSavedRoutesStore((state) => state.populateRoutes)
    const currentRoute = useSavedRoutesStore((state) => state.currentRoute)
    const setCreateBoardModalOpen = useSavedBoardsStore((state) => state.setCreateBoardModalOpen)
    const populateSavedBoardsList = useSavedBoardsStore((state) => state.populateSavedBoardsList)
    const allFavoritesBoxChecked = useFavoriteActionsStore((state) => state.allFavoritesBoxChecked)

    const directionCoordinates = mapStore((state) => state.directionCoordinates)
    const [titleInput, setTitleInput] = React.useState<string>('')
    const [descriptionInput, setDescriptionInput] = React.useState<string>('')
    React.useEffect(() => {
        setTitleInput(title)
    }, [])

    const shouldUpdateRoute = (): boolean => {
        if (activeSavedRoute) {
            const differentLength =
                activeSavedRoute?.coordinates &&
                directionCoordinates?.length !== activeSavedRoute?.coordinates?.length
            const differentTitle = activeSavedRoute?.title !== title
            const differentDescription = activeSavedRoute?.description !== description
            const routeExists = parsedRoutes.some((route) => route.id === activeSavedRoute?.id)
            return differentLength || differentTitle || differentDescription || routeExists
        }
    }
    const handleTitleChange = (e) => setTitleInput(e?.target?.value)
    const handleNoteChange = (e) => setDescriptionInput(e?.target?.value)

    const handleSaveorUpdateRoute = async () => {
        setTitle(titleInput)
        setDescription(descriptionInput)
        if (shouldUpdateRoute()) {
            const parsedCoords: InputBreweryRouteGeoCoordInput[] = coordinatesToInput(
                activeSavedRoute?.coordinates
            )
            const shouldPopulateBoardPage = allFavoritesBoxChecked
            const data = await updateBreweryRoute(
                activeSavedRoute,
                shouldPopulateBoardPage,
                parsedCoords,
                titleInput,
                descriptionInput,
                true
            )
            if (data) {
                setSavedRouteModalOpen(false)
                populateRoutes()
            }
        } else {
            const data = await createBreweryRoute(
                directionCoordinates,
                currentRoute?.uuid,
                currentRoute?.routes?.[0]?.distance,
                currentRoute?.routes?.[0]?.duration
            )
            if (data) {
                setSavedRouteModalOpen(false)
                populateRoutes()
            }
        }
    }
    // @TODO: Leave for future ticket
    // const handleSaveAsNewRoute = async () => {
    //     setTitle(titleInput)
    //     setDescription(descriptionInput)
    //     await createBreweryRoute().then((res) => res && setSavedRouteModalOpen(false))
    // }

    React.useEffect(() => {
        if (activeSavedRoute) {
            setTitleInput(activeSavedRoute?.title)
            setDescriptionInput(activeSavedRoute?.description)
        }
        ;(async () => await populateSavedBoardsList())()
    }, [])

    return (
        <CC.Modal
            isOpen={savedRouteModalOpen}
            onClose={() => setSavedRouteModalOpen(false)}
            isCentered={true}
            size='xl'
        >
            <CC.ModalOverlay />
            <CC.ModalContent className='space-y-3' p={6}>
                <CC.ModalHeader p={0}>{getFavoriteModalHeader()}</CC.ModalHeader>
                <CC.ModalCloseButton />
                <CC.ModalBody p={0}>
                    <CC.Stack spacing={2} mt={4}>
                        <CC.Text className='text-sm font-bold text-dark'>Title</CC.Text>
                        <CC.Input
                            placeholder={'Add a title for this route'}
                            className=' text-sm'
                            colorScheme='gray'
                            focusBorderColor='gray.500'
                            onChange={(e) => handleTitleChange(e)}
                            value={titleInput}
                            id={`route-${activeSavedRoute?.id}`}
                        />
                    </CC.Stack>
                    <CC.Stack spacing={2} mt={4}>
                        <CC.Text className='text-sm font-bold text-dark'>
                            My Note (optional)
                        </CC.Text>
                        <CC.Textarea
                            placeholder={'Add a note...'}
                            className=' text-sm'
                            colorScheme='gray'
                            focusBorderColor='gray.500'
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                handleNoteChange(e)
                            }
                            maxLength={280}
                            value={descriptionInput || ''}
                        />
                        <CC.Text
                            className={classNames(
                                'flex w-full justify-end text-xs font-semibold',
                                descriptionInput?.length > 279 ? 'text-error' : 'text-dark'
                            )}
                        >
                            {descriptionInput?.length}/280
                        </CC.Text>
                    </CC.Stack>
                    <CC.HStack gap={3} mb={6}>
                        <CC.Button
                            aria-label='Create a new board'
                            size='sm'
                            p={0}
                            borderRadius='lg'
                            border='none'
                            bg='gray.50'
                            onClick={() => setCreateBoardModalOpen(true)}
                        >
                            <div className='flex p-4'>
                                <CC.Icon boxSize='6' as={HeroIcons.PlusIcon} />
                            </div>
                        </CC.Button>
                        <p className='font-semibold'>Create a new board</p>
                    </CC.HStack>
                    <SavedBoardsList />
                </CC.ModalBody>
                <CC.ModalFooter p={0}>
                    <div className='flex w-full justify-end'>
                        <CC.Button
                            colorScheme='blue'
                            mr={3}
                            onClick={() => setSavedRouteModalOpen(false)}
                            size='sm'
                        >
                            Cancel
                        </CC.Button>
                        <div className='flex'>
                            <CC.Button
                                size='sm'
                                onClick={handleSaveorUpdateRoute}
                                variant='dark'
                                isDisabled={titleInput?.length === 0}
                                className={classNames(
                                    activeSavedRoute?.title?.length === 0 && 'hover:text-dark'
                                )}
                            >
                                {shouldUpdateRoute() ? 'Update' : 'Save'}
                            </CC.Button>
                        </div>
                    </div>
                </CC.ModalFooter>
            </CC.ModalContent>
        </CC.Modal>
    )
}
export default SaveRoutes
