import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { userStore } from '../../state/userStore'
import { loginFormStore } from '../../state/loginFormStore'
import * as FormFields from './fields'
import SubmitButton from './partials/SubmitButton'
import ResendCodeButton from './partials/ResendCodeButton'
import LoginFormWrapper from './partials/LoginFormWrapper'

const VerifyAccountForm: React.FC = () => {
    const router = useRouter()
    const verifyAccount = userStore((state) => state.verifyAccount)
    const email = loginFormStore((state) => state?.email)
    const code = loginFormStore((state) => state.code)
    const status = loginFormStore((state) => state.status)
    const populateInitialValues = loginFormStore((state) => state.populateInitialValues)
    const [autoSubmitted, setAutoSubmitted] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (router.isReady) populateInitialValues(router?.query)
    }, [router.isReady])

    React.useEffect(() => {
        if (status === 'ready') {
            if (code && email && !autoSubmitted) {
                setAutoSubmitted(true)
                ;(async () => await handleSubmit())()
            }
        }
    }, [status])

    const handleSubmit = async () => await verifyAccount({ email, code })

    return (
        <LoginFormWrapper onSubmit={handleSubmit}>
            <Chakra.Stack spacing={8}>
                <FormFields.Email />
                <FormFields.Code />

                <SubmitButton label='Verify Account' loadingText='Verifying' />
                <ResendCodeButton />
            </Chakra.Stack>
        </LoginFormWrapper>
    )
}

export default VerifyAccountForm
