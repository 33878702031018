interface GtmEvent {
    event: string
    page?: string
    action?: string
    [Key: string]: string
}

interface IWindow extends Window {
    dataLayer?: GtmEvent[]
}

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-5WK62B6'

export const gtmPageView = (url: string) => {
    const gtmWIndow = window as IWindow
    gtmWIndow.dataLayer.push({
        event: 'pageview',
        page: url,
    })
}

export const gtmEvent = (event: GtmEvent) => {
    const gtmWIndow = window as IWindow
    gtmWIndow.dataLayer.push(event)
}
