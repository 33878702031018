import { mapStore } from '../../state/mapStore'

const { setShowFilters } = mapStore.getState()
/**
 * handleShowFilterState()
 *
 * Set show filter state on screen sizes larger than mobile
 *
 */
const handleShowFilterState = () => {
    if (window.innerWidth > 650) {
        setShowFilters(true)
    }
}

export { handleShowFilterState }
