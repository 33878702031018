import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { modalAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    modalAnatomy.keys
)

/* 
Anatomy of Modal Component:
https://chakra-ui.com/docs/components/modal/theming */

// styles for mulitpart component
const baseStyle = definePartsStyle({
    dialog: {},
    dialogContainer: {},
    overlay: {},
    header: {},
    footer: {},
    body: {},
    closeButton: {},
})

// multi part sizes
const sizes = {}

// Variant styles per part
const variants = {}

// default props for entire modal
const defaultProps = {
    isCentered: true,
    size: 'xl',
}

const multiStyleConfig = defineMultiStyleConfig({ baseStyle, variants, sizes })

const ModalConfig = multiStyleConfig // { defaultProps, multiStyleConfig }

export default ModalConfig
