import mapboxgl from 'mapbox-gl'
import { mapStore } from '../../state/mapStore'
import { createRoot } from 'react-dom/client'
import LocationPopupCard from '../../components/map/LocationPopupCard'
import React from 'react'

export const addClusterPointClickListeners = (
    mapRef: mapboxgl.Map,
    popupRef: React.MutableRefObject<mapboxgl.Popup>
) => {
    const { locations, setPopupData } = mapStore.getState()
    mapRef?.on('click', 'clusters', (e) => {
        const features = mapRef.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
        }) as mapboxgl.MapboxGeoJSONFeature[]
        const clusterId: number = features[0].properties.cluster_id
        const breweriesSource = mapRef.getSource('breweries') as mapboxgl.GeoJSONSource

        breweriesSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return
            if (features[0].geometry.type === 'Point') {
                const centerCoords = features[0].geometry.coordinates as mapboxgl.LngLatLike

                mapRef.easeTo({
                    center: centerCoords,
                    zoom: zoom + 2,
                })
            }
        })
    })
    mapRef?.on('click', (e) => {
        const markerFeatures = mapRef.queryRenderedFeatures(e.point, {
            layers: ['unclustered-point'],
        })
        if (markerFeatures.length > 0) {
            const newPost = locations?.find((post) => post.id === markerFeatures[0].properties.id)
            setPopupData({
                location: newPost,
            })
        }
    })
}
