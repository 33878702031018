import mapboxgl from 'mapbox-gl'
import { BreweryRoute } from 'src/types/schema/graphql'
import { guidGenerator } from './guidGenerator'
import { loadRouteMarkerIcons } from './loadRouteMarkerIcons'

export const addUpdateRouteMarkers = (mapRef: mapboxgl.Map, route: BreweryRoute) => {
    const { coordinates } = route
    const parsedCoords = coordinates?.map((coord) => ({ ...coord, id: guidGenerator() }))

    const bounds = new mapboxgl.LngLatBounds()

    if (coordinates.length > 0) {
        coordinates.forEach((coord) => bounds.extend(new mapboxgl.LngLat(coord.lon, coord.lat)))
    }
    mapRef.fitBounds(bounds, { padding: 50, maxZoom: 15, animate: false })
    if (mapRef) {
        if (mapRef.getLayer('routeMarkers')) {
            mapRef.setLayoutProperty('routeMarkers', 'visibility', 'visible')
        }
        if (mapRef.getLayer('endStopMarker')) {
            mapRef.setLayoutProperty('endStopMarker', 'visibility', 'visible')
        }
        loadRouteMarkerIcons(mapRef)

        const geoJsonDirectionCoordinates: mapboxgl.GeoJSONSourceRaw = {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: parsedCoords?.map((coord) => ({
                    type: 'Feature',
                    properties: {
                        id: coord?.id,
                        title: coord?.title,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [coord?.lon, coord?.lat],
                    },
                })),
            },
            cluster: false,
        }
        if (coordinates?.length > 1) {
            const lastCoordinate = parsedCoords[parsedCoords?.length - 1]

            const endStopGeoJson: mapboxgl.GeoJSONSourceRaw = {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            properties: {
                                id: lastCoordinate?.id,
                                title: lastCoordinate?.title,
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: [lastCoordinate?.lon, lastCoordinate?.lat],
                            },
                        },
                    ],
                },
                cluster: false,
            }
            const endStopSource: mapboxgl.AnySourceImpl = mapRef?.getSource(
                'endStop'
            ) as mapboxgl.GeoJSONSource

            if (!endStopSource) mapRef.addSource('endStop', endStopGeoJson)
            else endStopSource.setData(endStopGeoJson.data as keyof mapboxgl.GeoJSONSource)
        }
        const routeStopSource: mapboxgl.GeoJSONSourceRaw | any = mapRef.getSource('routeStops')

        if (!routeStopSource) mapRef.addSource('routeStops', geoJsonDirectionCoordinates)
        else routeStopSource.setData(geoJsonDirectionCoordinates.data)

        if (!mapRef.getLayer('routeMarkers'))
            mapRef.addLayer({
                id: 'routeMarkers',
                type: 'symbol',
                source: 'routeStops',
                layout: {
                    'icon-image': 'startMarkerIcon',
                    'icon-size': 0.25,
                    'icon-allow-overlap': true,
                },
            })

        if (!mapRef.getLayer('endStopMarker'))
            if (mapRef.getSource('endStop'))
                mapRef.addLayer({
                    id: 'endStopMarker',
                    type: 'symbol',
                    source: 'endStop',
                    layout: {
                        'icon-image': 'endMarkerIcon',
                        'icon-size': 0.25,
                        'icon-allow-overlap': true,
                    },
                })
        if (mapRef?.getLayer('routeLayer'))
            mapRef.setLayoutProperty('routeLayer', 'visibility', 'visible')

        if (mapRef?.getLayer('routeStopsLayer'))
            mapRef.setLayoutProperty('routeStopsLayer', 'visibility', 'visible')
    }
}
