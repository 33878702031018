import React from 'react'
import * as Chakra from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { userStore } from 'src/features/auth/state/userStore'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'
import * as FormFields from './fields'
import SubmitButton from './partials/SubmitButton'
import LoginFormWrapper from './partials/LoginFormWrapper'

const RegisterForm: React.FC = () => {
    const router = useRouter()
    const register = userStore((state) => state.register)
    const name = loginFormStore((state) => state?.name)
    const email = loginFormStore((state) => state?.email)
    const password = loginFormStore((state) => state?.password)
    const passwordStrength = loginFormStore((state) => state?.passwordStrength)
    const loginFormFeedback = loginFormStore((state) => state.loginFormFeedback)
    const setStatus = loginFormStore((state) => state.setStatus)
    const populateInitialValues = loginFormStore((state) => state.populateInitialValues)

    React.useEffect(() => {
        if (router.isReady) populateInitialValues(router?.query)
    }, [router.isReady])

    const handleSubmit = async () => {
        if (passwordStrength < 4) {
            loginFormFeedback({
                type: 'error',
                message: 'Password is not strong enough',
            })
            setStatus('error')
            return
        }
        await register({ name, email, password, redirect: true })
    }

    return (
        <LoginFormWrapper onSubmit={handleSubmit}>
            <Chakra.Stack spacing={8}>
                <FormFields.FullName />
                <FormFields.Email autoFocus={false} />
                <FormFields.Password action='mutate' />

                <SubmitButton label='Sign Up' loadingText='Signing Up' />

                <div className='font-base w-full max-w-prose py-2 text-center font-semibold text-dark'>
                    Already have an account?{' '}
                    <Link href='/login'>
                        <a className='text-info'>Log in</a>
                    </Link>
                </div>
            </Chakra.Stack>
        </LoginFormWrapper>
    )
}

export default RegisterForm
