import * as UI from '@brewlogix/blx-ui'
import * as CC from '@chakra-ui/react'

import { CompositeProduct, Location } from 'src/types/schema/graphql'
import Image from 'next/image'
import getDefaultIconByProduct from 'src/util/helpers/getDefaultIconByProduct'

import React from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { CardImage } from 'src/features/account/components/favorites'

interface IBrewCard {
    item: CompositeProduct | Location
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    children?: React.ReactNode
    link?: {
        href: string
        label: string
    }
    customClasses?: string
}

const SavedFavoritesCard: React.FC<IBrewCard> = ({
    item,
    onClick,
    children,
    link,
    customClasses,
}) => {
    const itemAsset = item?.assets?.length > 0 ? item?.assets[0] : item?.brand?.assets[0] || null
    const defaultIcon = getDefaultIconByProduct(item as CompositeProduct)
    const imageChildren = (
        <div className='flex items-center justify-center overflow-hidden rounded-xl'>
            {itemAsset ? (
                <UI.Media
                    asset={itemAsset}
                    customWrapperClasses={classNames(
                        customClasses ? customClasses : 'rounded-xl',
                        'flex align-center rounded-xl'
                    )}
                    height={368}
                    width={368}
                    alt={itemAsset?.alt}
                />
            ) : (
                <div className=' relative h-full w-full'>
                    <Image
                        src={defaultIcon?.src}
                        alt={`Fallback image for ${item?.__typename}`}
                        height={368}
                        width={368}
                    />
                </div>
            )}
        </div>
    )
    return (
        <CC.VStack
            gap={4}
            position='relative'
            className={classNames(
                'duration-150 ease-out hover:scale-[.98] hover:opacity-90',
                customClasses
            )}
        >
            {link?.href ? (
                <Link href={link.href} passHref>
                    <a aria-label={link.label} className='inline-block w-full'>
                        {imageChildren}
                    </a>
                </Link>
            ) : (
                imageChildren
            )}
            {children}
        </CC.VStack>
    )
}

export default SavedFavoritesCard
