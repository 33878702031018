export const createBreweryRouteMutation = `mutation createBreweryRoute($input: inputCreateBreweryRoute!) {
	createBreweryRoute(input: $input) {
        id
        title
        routeId
        title
        description
        distance
        duration
        coordinates {
            lat
            lon
            title
            placeName
            location {
                id
                brand {
                    id
                }
            }
        }
        createdDate
        updatedDate
        createdBy
    }
}`
