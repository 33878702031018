import { mapStore } from '../../state/mapStore'
import { IRouteData } from '../../types'
const { setRouteData } = mapStore.getState()
/**
 * handleRouteData()
 *
 * Takes the data from the mapbox directions API and formats it to hours, minutes, and miles.
 *
 */

const handleRouteData = (data: IRouteData) => {
    const distanceMiles: number = data?.distance ? data?.distance / 1609.34 : 0
    const hours = Math.floor((data?.duration ? data?.duration / 60 : 0) / 60)
    const decimal = data?.duration ? data?.duration / 60 : 0
    const minutes = +decimal.toFixed(0) % 60

    setRouteData({
        hours: hours || 0,
        minutes: minutes || 0,
        distance: parseFloat(distanceMiles.toFixed(0)),
    })
}
export { handleRouteData }
