import React from 'react'
import { createUserFormConfig } from '../../config/createUserFormConfig'
import { loginFormStore } from '../../state/loginFormStore'
import { motion } from 'framer-motion'
import { useLoginGateStore } from '../../state/useLoginGateStore'
import { userStore } from '../../state/userStore'
import * as CC from '@chakra-ui/react'
import * as FormFields from './fields'
import LoginFormWrapper from './partials/LoginFormWrapper'
import RegistrationFeedback from '../RegistrationFeedback'
import RegistrationFormStep from '../../types/RegistrationFormStep'
import SubmitButton from './partials/SubmitButton'

const ModalRegisterForm: React.FC = () => {
    const { formSteps } = createUserFormConfig
    const setCreateUserFlowActive = useLoginGateStore((state) => state.setCreateUserFlowActive)
    const userFeedback = useLoginGateStore((state) => state.userFeedback)
    const setUserFeedback = useLoginGateStore((state) => state.setUserFeedback)
    const registerFormStep = useLoginGateStore((state) => state.registerFormStep)
    const setRegisterFormStep = useLoginGateStore((state) => state.setRegisterFormStep)
    const register = userStore((state) => state.register)
    const registerVerify = userStore((state) => state.verifyAccount)
    const name = loginFormStore((state) => state?.name)
    const email = loginFormStore((state) => state?.email)
    const password = loginFormStore((state) => state?.password)
    const code = loginFormStore((state) => state?.code)
    const setStatus = loginFormStore((state) => state.setStatus)

    const submitRegistration = async () => {
        const success = await register({ name, email, password, redirect: false })
        if (success) {
            setRegisterFormStep(RegistrationFormStep.RegisterVerify)
            setUserFeedback({
                type: 'info',
                message: 'Please check your email for a verification code.',
            })
        }
    }
    const verifyRegistration = async () => {
        const success = await registerVerify({ email, code })
        if (success) {
            setRegisterFormStep(RegistrationFormStep.RegisterSuccess)
            setCreateUserFlowActive(false)
        }
    }
    const handleFormSubmit = async () => {
        if (registerFormStep === RegistrationFormStep.Register) await submitRegistration()
        if (registerFormStep === RegistrationFormStep.RegisterVerify) await verifyRegistration()
        setStatus('ready')
    }

    const handleLoginClick = () => {
        setCreateUserFlowActive(false)
        setRegisterFormStep(null)
        setUserFeedback(null)
    }

    return (
        <LoginFormWrapper onSubmit={handleFormSubmit}>
            <CC.Stack spacing={6}>
                <motion.div
                    initial={{ y: 25, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 25, opacity: 0 }}
                    key='register-fullName'
                >
                    {registerFormStep === RegistrationFormStep.Register && <FormFields.FullName />}
                </motion.div>
                <FormFields.Email />
                {registerFormStep === RegistrationFormStep.Register && (
                    <FormFields.Password action='mutate' />
                )}
                {registerFormStep === RegistrationFormStep.RegisterVerify && <FormFields.Code />}

                <SubmitButton
                    label={
                        registerFormStep === RegistrationFormStep.Register
                            ? formSteps[0]?.formSubmitButtonLabel
                            : formSteps[1]?.formSubmitButtonLabel
                    }
                    loadingText={
                        registerFormStep === RegistrationFormStep.Register
                            ? formSteps[0]?.loadingText
                            : formSteps[1]?.loadingText
                    }
                />
                {registerFormStep === RegistrationFormStep.Register && (
                    <div className='flex w-full justify-center'>
                        <p className='mr-1'>Already have an account?</p>

                        <CC.Button
                            onClick={handleLoginClick}
                            className='border-none bg-transparent px-0 py-1 text-sm font-semibold text-info transition hover:text-black'
                        >
                            Log in
                        </CC.Button>
                    </div>
                )}
                {userFeedback && <RegistrationFeedback />}
            </CC.Stack>
        </LoginFormWrapper>
    )
}
export default ModalRegisterForm
