import React from 'react'
import * as UI from '@brewlogix/blx-ui'
import classNames from 'classnames'

interface IPasswordCheckItem {
    title: string
    valid: boolean
}

const PasswordCheckItem: React.FC<IPasswordCheckItem> = ({ title, valid }) => {
    const colorClass = `${valid ? 'text-blue-300' : 'text-gray-300'}`
    return (
        <div className='flex items-center gap-3'>
            <div className='flex w-5 items-center justify-center'>
                <UI.Icon
                    slug={valid ? 'HeroIcons.CheckCircle' : 'HeroIcons.XCircle'}
                    customClasses={classNames(
                        'transition-color duration-200 ease-linear',
                        colorClass
                    )}
                />
            </div>
            <span className={classNames('transition-color duration-200 ease-linear', colorClass)}>
                {title}
            </span>
        </div>
    )
}

export default PasswordCheckItem
