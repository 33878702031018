import { gql } from 'graphql-request'

export const removeGuestBoardMutation = gql`mutation RemoveGuestBoard($input: inputRemoveGuestBoard!) {
	removeGuestBoard(input: $input){
		board {
			createdBy
			createdDate
			description
			# favorites { ...favorites }
			id
			isPrivate
			lastUpdatedBy
			# routes { ...routes }
			title
			updatedDate
		}
		id
	}
}`
