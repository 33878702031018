import * as CC from '@chakra-ui/react'
import React from 'react'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'
import { useLoginGateStore } from 'src/features/auth/state/useLoginGateStore'
import useAutoFocus from 'src/hooks/useAutoFocus'
import FieldLabel from './FieldLabel'
import FieldWrapper from './FieldWrapper'

const Email: React.FC<{ format?: 'icon' | 'label'; autoFocus?: boolean }> = ({
    format = 'label',
    autoFocus = true,
}) => {
    const email = loginFormStore((state) => state?.email)
    const updateField = loginFormStore((state) => state.updateField)
    const createUserFlowActive = useLoginGateStore((state) => state.createUserFlowActive)
    const focus = createUserFlowActive ? false : autoFocus
    return (
        <FieldWrapper format={format}>
            <FieldLabel format={format} icon='MailIcon'>
                Email
            </FieldLabel>
            <CC.Input
                type='email'
                placeholder='your.email@example.com'
                className='border-gray-100'
                id='email'
                autoComplete='email'
                value={email}
                onChange={({ target }) => updateField('email', target.value)}
                paddingInlineStart={format === 'icon' ? 'var(--chakra-space-10)' : null}
                {...useAutoFocus(focus)}
            />
        </FieldWrapper>
    )
}

export default Email
