import React from 'react'
import * as CC from '@chakra-ui/react'
import { createNewBoardConfig } from '../config/createNewBoardConfig'
import { useSavedBoardsStore } from '../state/useSavedBoardsStore'

interface ICreateBoardActions {}

const CreateBoardActions: React.FC<ICreateBoardActions> = () => {
    const { actions } = createNewBoardConfig
    const newBoardTitle = useSavedBoardsStore((state) => state.newBoardTitle)
    return (
        <div className='flex'>
            <CC.HStack gap={3}>
                <CC.Button onClick={() => actions?.cancel?.onClick()} aria-label='Cancel' size='sm'>
                    Cancel
                </CC.Button>
                <CC.Button
                    onClick={async () => await actions?.create?.onClick()}
                    aria-label='Create board'
                    size='sm'
                    variant='secondary'
                    isDisabled={newBoardTitle?.length === 0}
                >
                    Create Board
                </CC.Button>
            </CC.HStack>
        </div>
    )
}
export default CreateBoardActions
