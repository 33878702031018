import React from 'react'
import * as CC from '@chakra-ui/react'
import * as HeroIcons from '@heroicons/react/outline'
import { FavoriteType } from 'src/features/favorites/types/enums/FavoriteType'
import { useFavoriteActionsStore } from 'src/features/favorites/state/useFavoriteActionsStore'
import { BoardItemType, Brand, CompositeProduct, Location } from 'src/types/schema/graphql'
import { getNote } from 'src/features/favorites/utils/helpers/getNote'
import { useRouter } from 'next/router'
import classNames from 'classnames'

interface IFavoritesMenu {
    favoriteType: FavoriteType
    item: Location | CompositeProduct | Brand
}

const FavoriteCardFooter: React.FC<IFavoritesMenu> = ({ favoriteType, item }) => {
    const router = useRouter()
    const { userId, boardId } = router.query as { userId: string; boardId: string }
    const setUpdatedNote = useFavoriteActionsStore((state) => state.setUpdatedNote)
    const setActiveFavoriteItem = useFavoriteActionsStore((state) => state.setActiveFavoriteItem)
    const setActiveFavoriteType = useFavoriteActionsStore((state) => state.setActiveFavoriteType)
    const setFavoriteModalOpen = useFavoriteActionsStore((state) => state.setFavoriteModalOpen)
    const note = getNote(item?.id, favoriteType, true)
    const setActiveFavoriteAction = useFavoriteActionsStore(
        (state) => state.setActiveFavoriteAction
    )
    const editingIsEnabled = boardId && userId ? false : true
    const handleMenuAction = (
        e: React.MouseEvent<HTMLButtonElement>,
        action: 'edit' | 'remove'
    ) => {
        e.stopPropagation()
        if (action === 'edit') setUpdatedNote('')
        setActiveFavoriteAction(action)
        setActiveFavoriteItem(item)
        setActiveFavoriteType(favoriteType as unknown as BoardItemType)
        setFavoriteModalOpen(true)
    }
    const handleClick = () => {
        if (!editingIsEnabled) return
        setActiveFavoriteAction('edit')
        setActiveFavoriteItem(item)
        setActiveFavoriteType(favoriteType as unknown as BoardItemType)
        setFavoriteModalOpen(true)
    }
    return (
        <CC.VStack
            className={classNames(
                'bg-gray-50',
                editingIsEnabled && 'cursor-pointer duration-150 ease-out hover:bg-gray-100'
            )}
            resize='none'
            fontSize={'sm'}
            onClick={handleClick}
            alignItems='flex-start'
            rounded='xl'
            p='4'
            w='full'
        >
            <p className='text-xs font-semibold text-gray-400'>My Note</p>
            <div className='w-full'>{note || 'Add a note'}</div>
        </CC.VStack>
    )
}
export default FavoriteCardFooter
