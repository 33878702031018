import mapboxgl from 'mapbox-gl'

interface CenterCoords {
    latitude: number
    longitude: number
}
type CreateGeoJSONCircleType = (
    center: CenterCoords,
    radiusInKM: number,
    points?: number
) => mapboxgl.GeoJSONSourceRaw

export const createGeoJSONCircle: CreateGeoJSONCircleType = (center, radiusInKm, points = 500) => {
    const { latitude, longitude } = center
    const ret: number[][] = []
    const distanceX = radiusInKm / (111.32 * Math.cos((latitude * Math.PI) / 180))
    const distanceY = radiusInKm / 110.574

    let theta, x, y
    for (let i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI)
        x = distanceX * Math.cos(theta)
        y = distanceY * Math.sin(theta)

        ret.push([longitude + x, latitude + y])
    }
    ret.push(ret[0])
    const geojsonObj: mapboxgl.GeoJSONSourceRaw = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [ret],
                    },
                    properties: {},
                },
            ],
        },
    }

    return geojsonObj
}
