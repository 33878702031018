import React from 'react'
import * as CC from '@chakra-ui/react'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'
import { userStore } from 'src/features/auth/state/userStore'

const ResendCodeButton: React.FC = () => {
    const status = loginFormStore((state) => state.status)
    const email = loginFormStore((state) => state.email)
    const resendAccountVerificationCode = userStore((state) => state.resendAccountVerificationCode)
    const [isSending, setIsSending] = React.useState<boolean>(false)

    const handleResend = async () => {
        setIsSending(true)
        if (email?.length < 4) {
            setIsSending(false)
            return
        }

        await resendAccountVerificationCode({ email }).then(() => setIsSending(false))
    }

    return (
        <CC.Button
            variant='link'
            isLoading={isSending}
            loadingText='Resending Code'
            type='button'
            onClick={handleResend}
            className='text-dark'
            isDisabled={status === 'loading'}
        >
            Resend Verification Code
        </CC.Button>
    )
}

export default ResendCodeButton
