import { IMapBoxDirections } from '../../types/IMapboxDirections'
import mapboxgl from 'mapbox-gl'
import React from 'react'
import { handleRouteData } from './handleRouteData'
import { mapStore } from '../../state/mapStore'
import { IRouteData } from '../../types'
import { routesConfig } from '../../config/routesConfig'

export const drawMapRoute = (
    json: IMapBoxDirections,
    map: React.MutableRefObject<mapboxgl.Map>
) => {
    const { directionCoordinates, setMapRouteBounds, setMapRouteGeoJson } = mapStore.getState()
    const { arrows, line } = routesConfig
    const data: IRouteData = json.routes[0]
    handleRouteData(data)
    const route = data?.geometry?.coordinates
    const geojson: mapboxgl.GeoJSONSourceRaw = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: route,
                    },
                },
            ],
        },
    }
    setMapRouteGeoJson(geojson)

    if (map.current) {
        const routeSource: mapboxgl.AnySourceImpl = map.current.getSource(
            'routeSource'
        ) as mapboxgl.GeoJSONSource
        if (routeSource) {
            if (map.current.getLayer('routeLayer')) {
                map.current.setLayoutProperty('routeLayer', 'visibility', 'visible')
            }
            if (map.current.getLayer('routeDirectionArrows'))
                map.current.setLayoutProperty('routeDirectionArrows', 'visibility', 'visible')

            if (map.current.getLayer('routeStopsLayer'))
                map.current.setLayoutProperty('routeStopsLayer', 'visibility', 'visible')
            routeSource.setData(geojson.data as mapboxgl.MapboxGeoJSONFeature)
        } else {
            map.current.addSource('routeSource', geojson)
            if (!map.current.getLayer('routeLayer')) {
                if (map.current.getLayer('clusters')) {
                    map.current.addLayer(line as mapboxgl.AnyLayer, 'clusters')
                } else {
                    map.current.addLayer(line as mapboxgl.AnyLayer)
                }
            }

            if (!map.current.getLayer('routeDirectionArrows')) {
                if (map.current.getLayer('clusters')) {
                    map.current.addLayer(arrows as mapboxgl.AnyLayer, 'clusters')
                } else {
                    map.current.addLayer(arrows as mapboxgl.AnyLayer)
                }
            }
        }
        if (map?.current.getLayer('polygon')) {
            map?.current.setLayoutProperty('polygon', 'visibility', 'none')
            map?.current.setLayoutProperty('polygonBorder', 'visibility', 'none')
        }
    }
    // Get all start and stop coords to generate bounds
    if (directionCoordinates?.length > 0) {
        const start = new mapboxgl.LngLat(
            Number(directionCoordinates[0].lon),
            Number(directionCoordinates[0].lat)
        )
        const bounds = new mapboxgl.LngLatBounds(start, start)

        directionCoordinates.forEach((item) => {
            bounds.extend(new mapboxgl.LngLat(Number(item.lon), Number(item.lat)))
        })
        route.forEach((item) => {
            bounds.extend(new mapboxgl.LngLat(item[0], item[1]))
        })
        setMapRouteBounds(bounds as mapboxgl.LngLatBounds | any)
    }
}
