import React from 'react'
import * as CC from '@chakra-ui/react'
import { useFavoriteActionsStore } from '../state/useFavoriteActionsStore'
import { Location } from 'src/types/schema/graphql'
import { isFavorited } from '../utils/helpers/isFavorited'
import { FavoriteType } from '../types/enums/FavoriteType'

const FavoritesModalActions: React.FC = () => {
    const setFavoriteModalOpen = useFavoriteActionsStore((state) => state.setFavoriteModalOpen)
    const toggleFavorite = useFavoriteActionsStore((state) => state.toggleFavorite)
    const activeFavoriteItem = useFavoriteActionsStore((state) => state.activeFavoriteItem)
    const activeFavoriteType = useFavoriteActionsStore((state) => state.activeFavoriteType)
    const favoritesNote = useFavoriteActionsStore((state) => state.favoritesNote)
    const updatedNote = useFavoriteActionsStore((state) => state.updatedNote)
    const shouldUpdateAccountStore = useFavoriteActionsStore(
        (state) => state.shouldUpdateAccountStore
    )
    const updateFavorite = useFavoriteActionsStore((state) => state.updateFavorite)
    const activeFavoriteAction = useFavoriteActionsStore((state) => state.activeFavoriteAction)
    const favoriteIsChanged = useFavoriteActionsStore((state) => state.favoriteIsChanged)
    const handleFavoritesAction = async () => {
        const isFav = isFavorited(
            activeFavoriteItem.id,
            activeFavoriteType as unknown as FavoriteType
        )
        setFavoriteModalOpen(false)
        if (isFav) {
            if (activeFavoriteAction === 'remove') {
                toggleFavorite(
                    activeFavoriteType as unknown as FavoriteType,
                    activeFavoriteItem.id,
                    (activeFavoriteItem as Location)?.brand?.id,
                    shouldUpdateAccountStore,
                    favoritesNote
                )
            } else {
                await updateFavorite(
                    activeFavoriteType as unknown as FavoriteType,
                    activeFavoriteItem.id,
                    (activeFavoriteItem as Location)?.brand?.id,
                    true,
                    updatedNote
                )
            }
        }
    }
    const handleUndoCancel = async () => {
        setFavoriteModalOpen(false)
        if (activeFavoriteAction === 'add') {
            await toggleFavorite(
                activeFavoriteType as unknown as FavoriteType,
                activeFavoriteItem.id,
                (activeFavoriteItem as Location)?.brand?.id,
                shouldUpdateAccountStore,
                favoritesNote
            )
        }
    }
    const noteDisabled = () => {
        if (activeFavoriteAction === 'remove') {
            return false
        } else {
            return !favoriteIsChanged
        }
    }

    return (
        <div className='flex w-full justify-between'>
            <CC.Button variant='default' size='sm' onClick={handleUndoCancel}>
                {activeFavoriteAction === 'remove' ? 'Cancel' : 'Undo'}
            </CC.Button>
            <CC.Button
                size='sm'
                onClick={async () => await handleFavoritesAction()}
                isDisabled={noteDisabled()}
                variant={activeFavoriteAction === 'remove' ? 'error' : 'default'}
            >
                {activeFavoriteAction === 'remove' ? 'Remove' : 'Update'}
            </CC.Button>
        </div>
    )
}
export default React.memo(FavoritesModalActions)
