import { UpdateUser, User } from 'src/types/schema/graphql'
import { updateUserMutation } from '../graph'
import { RequestInput, apiRequest } from 'src/api/apiRequest'

export const saveAppsyncUser = async (userData: UpdateUser) => {
    const input: RequestInput<UpdateUser> = {
        query: updateUserMutation,
        args: {
            input: userData,
        },
        file: 'saveAppsyncUser.ts',
        endpoint: 'updateUser',
        publicQuery: false,
    }
    const { data } = await apiRequest<User, UpdateUser>(input)
    return data
}
