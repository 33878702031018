import React from 'react'
import * as Chakra from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { userStore } from 'src/features/auth/state/userStore'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'
import * as FormFields from './fields'
import SubmitButton from './partials/SubmitButton'
import LoginFormWrapper from './partials/LoginFormWrapper'

const LoginForm: React.FC = () => {
    const router = useRouter()
    const authorize = userStore((state) => state.authorize)
    const email = loginFormStore((state) => state?.email)
    const password = loginFormStore((state) => state?.password)
    const populateInitialValues = loginFormStore((state) => state.populateInitialValues)

    React.useEffect(() => {
        if (router.isReady) populateInitialValues(router?.query)
    }, [router.isReady])

    const handleSubmit = async () => await authorize({ email, password })

    return (
        <LoginFormWrapper onSubmit={handleSubmit}>
            <Chakra.Stack spacing={8}>
                <FormFields.Email format='icon' />
                <FormFields.Password action='enter' format='icon' />

                <SubmitButton label='Log In' loadingText='Logging In' />

                <div className='font-base w-full max-w-prose py-2 text-center font-semibold text-dark'>
                    Don&#39;t have an account?{' '}
                    <Link href='/login/register'>
                        <a className='text-info'>Sign up</a>
                    </Link>
                </div>
            </Chakra.Stack>
        </LoginFormWrapper>
    )
}

export default LoginForm
