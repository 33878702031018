import Image from 'next/image'
import React from 'react'
import { Link } from 'src/types/schema/graphql'
import { AppStoreOptions } from 'src/types/ui/enums'

interface IAppStoreButton {
    appStore: AppStoreOptions
}

interface AppStoreLink extends Link {
    imagePath: string
}

const appStoreButtonsConfig: { [key in AppStoreOptions]: AppStoreLink } = {
    AppStore: {
        label: 'Apple App Store',
        url: 'https://apps.apple.com/us/app/brewerydb-by-brewlogix/id6449491682',
        imagePath: '/assets/mobile-app_store_badge@2x.jpg',
    },
    GooglePlay: {
        label: 'Google Play store',
        url: 'https://play.google.com/store/apps/details?id=com.brewlogix.brewerydb&pcampaignid=website',
        imagePath: '/assets/mobile-google-play-download-android-app@2x.jpg',
    },
}

const AppStoreButton: React.FC<IAppStoreButton> = ({ appStore }) => {
    const { label, url, imagePath } = appStoreButtonsConfig[appStore]

    return (
        <a
            href={url}
            target='_blank'
            rel='noreferrer'
            className='relative block h-auto w-36 overflow-hidden rounded-lg'
        >
            <span className='sr-only'>Download BreweryDB on the {label}</span>
            <Image
                src={imagePath}
                alt={`Download BreweryDB on the ${label}`}
                width={150}
                height={50}
                layout='responsive'
                className='w-full'
            />
        </a>
    )
}

export default AppStoreButton
