import React from 'react'
import * as UI from '@brewlogix/blx-ui'
import Link from 'next/link'
import classNames from 'classnames'

interface IMenuItem {
    id: string
    customClasses?: string
    href?: string
    size?: 'sm' | 'md' | 'lg'
    theme?: 'light' | 'dark'
    children?: React.ReactNode
    target?: string
    icon?: string
    onClick?: () => void
}

const MenuItem: React.FC<IMenuItem> = ({
    id,
    customClasses,
    href,
    size,
    theme,
    children,
    target,
    icon,
    onClick,
    ...otherProps
}) => {
    return (
        <Link id={id} href={href} {...otherProps}>
            <a
                className={classNames(
                    'blx-menu-item text-base',
                    customClasses,
                    {
                        [`blx-menu-item--${size}`]: !!size,
                    },
                    { [`blx-menu-item--${theme}`]: !!theme }
                )}
                target={target}
                onClick={onClick}
            >
                {icon && <UI.Icon theme='dark' size='md' slug={icon} customClasses='mr-4' />}
                {children}
            </a>
        </Link>
    )
}
export default MenuItem
