import { ComponentStyleConfig } from '@chakra-ui/react'

const AlertConfig: ComponentStyleConfig = {
    baseStyle: {
        container: {
            fontSize: 'md',
            borderRadius: 'lg',
            w: 'fit-content',
        },
    },
    variants: {
        error: {
            container: {
                backgroundColor: 'red.50',
                color: 'red.500',
            },
        },
        success: {
            container: {
                backgroundColor: 'green.50',
                color: 'green.500',
            },
        },
        info: {
            container: {
                backgroundColor: 'blue.50',
                color: 'blue.500',
            },
        },
        warning: {
            container: {
                backgroundColor: 'yellow.50',
                color: 'yellow.500',
            },
        },
    },
}
export default AlertConfig
