const getAuthenticatedUserQuery = /* GraphQL */ `
query GetAuthenticatedUser {
  getAuthenticatedUser {
    address {
      city
      country
      postalCode
      state
      street
      street2
    }
    bdbAccess
    brewlogixSuperadmin
    contact {
      email
      phone
    }
    contactPreference
    createdBy
    createdDate
    description
    familyName
    favorites {
      brands 
      locations {
        brandId
        locationId
      }
      products{
        brandId
        productId
      }
      favoriteBrands {
        brandId
        notes
        isActive
      }
      favoriteLocations {
        brandId
        locationId
        notes
        isActive
      }
      favoriteProducts {
        brandId
        productId
        notes
        isActive
        }
    }
    routes {
        id
        routeId
        description
        duration
        distance
        title
        coordinates {
            lat
            lon
            title
        }
        createdDate
        createdBy
        updatedDate
        lastUpdatedBy
    }
    givenName
    id
    jobTitle
    meta
    mmbBrands {
      brandId
      role
    }
    slug
  }
}`

export default getAuthenticatedUserQuery
