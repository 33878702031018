import { gql } from 'graphql-request'

export const saveGuestBoardMutation = gql`mutation SaveGuestBoard($input: inputSaveGuestBoard!) {
	saveGuestBoard(input: $input){
		id
		board {
			createdBy
			createdDate
			description
			# favorites { ...favorites }
			id
			isPrivate
			lastUpdatedBy
			# routes { ...routes }
			title
			updatedDate
		}
	}
}`
