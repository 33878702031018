import React from 'react'
import * as CC from '@chakra-ui/react'
import { userStore } from 'src/features/auth/state/userStore'
import { userAccountStore } from '../../state/userAccountStore'
import { useAlerts } from 'src/features/alerts/state/useAlerts'
import { alertMessages } from '../../constants/alertMessages'

const DeleteAccountModal: React.FC = () => {
    const deleteAccountModalOpen = userStore((state) => state.deleteAccountModalOpen)
    const setDeleteAccountModalOpen = userStore((state) => state.setDeleteAccountModalOpen)
    const deleteUser = userStore((state) => state.deleteUser)
    const logout = userStore((state) => state.logout)
    const clearUserAccountStore = userAccountStore((state) => state.clearUserAccountStore)

    const handleDeleteUser = async () => {
        const success = await deleteUser()
        clearUserAccountStore()
        setDeleteAccountModalOpen(false)
        await logout()

        if (success) {
            useAlerts.getState().addAlert({
                ...alertMessages.deleteSuccess,
                id: alertMessages.deleteSuccess.id + new Date().getMilliseconds(),
            })
        } else {
            useAlerts.getState().addAlert({
                ...alertMessages.deleteError,
                id: alertMessages.deleteError.id + new Date().getMilliseconds(),
            })
        }
    }

    return (
        <CC.Modal
            isOpen={deleteAccountModalOpen}
            onClose={() => setDeleteAccountModalOpen(false)}
            isCentered={true}
            size='xl'
        >
            <CC.ModalOverlay />
            <CC.ModalContent className='space-y-3' p={6}>
                <CC.ModalHeader p={0}>Delete Account</CC.ModalHeader>
                <CC.ModalCloseButton />
                <CC.ModalBody p={0}>
                    Are you sure you want to delete this account and lose access to all of your
                    BreweryDB data?
                </CC.ModalBody>
                <CC.ModalFooter p={0}>
                    <CC.HStack gap={3}>
                        <CC.Button
                            onClick={() => setDeleteAccountModalOpen(false)}
                            aria-label='Cancel'
                            size='sm'
                        >
                            Cancel
                        </CC.Button>
                        <CC.Button
                            onClick={handleDeleteUser}
                            aria-label='Create board'
                            size='sm'
                            variant='error'
                        >
                            Yes, Delete
                        </CC.Button>
                    </CC.HStack>
                </CC.ModalFooter>
            </CC.ModalContent>
        </CC.Modal>
    )
}

export default DeleteAccountModal
