import { useCallback, useEffect, useRef } from 'react'
import { throttle } from 'lodash'
import mapboxgl from 'mapbox-gl'
import {
    addClusterPointClickListeners,
    addStateSourceLayers,
    addUpdatePostSourceLayers,
    addUpdateRouteMarkersFromUserLocation,
    handleStateClick,
} from '.'
import markerImage from '../../../../../public/assets/Marker@4x.png'
import { useFilterStore } from '../../state/filterStore'
import { mapStore } from '../../state/mapStore'
import { searchLocations } from '../requests/searchLocations'
import { addCursorStyleListeners } from './addCursorStyleListeners'
import { addMarkersLayers } from './addMarkersLayers'
import { useSavedRoutesStore } from '../../state/useSavedRoutesStore'
import { IMapBoxDirections } from '../../types'
import { BreweryRouteGeoCoord } from 'src/types/schema/graphql'

const useMapFunctions = () => {
    // Map Store
    const activeTab = mapStore((state) => state.activeTab)
    const directionCoordinates = mapStore((state) => state.directionCoordinates)
    const profile = mapStore((state) => state.profile)
    const fullScreenShowFullMap = mapStore((state) => state.fullScreenShowFullMap)
    const locations = mapStore((state) => state.locations)
    const wildcardText = mapStore((state) => state.wildcardText)
    const radius = mapStore((state) => state.radius)
    const searchAsMapMoves = mapStore((state) => state.searchAsMapMoves)
    const setDirectionLegs = mapStore((state) => state.setDirectionLegs)
    const setResultsLoaded = mapStore((state) => state.setResultsLoaded)
    const userLocationState = mapStore((state) => state.userLocationState)
    const setSelectedWildcardOption = mapStore((state) => state.setSelectedWildcardOption)
    const setShowMapLocationTitle = mapStore((state) => state.setShowMapLocationTitle)
    const openNowHours = useFilterStore((state) => state.openNowHours)

    // Filter Store
    const stateFilter = useFilterStore((state) => state.stateFilter)
    const foodFilterOptions = useFilterStore((state) => state.foodFilterOptions)
    const brewFilterOptions = useFilterStore((state) => state.brewFilterOptions)
    const seatingFilterOptions = useFilterStore((state) => state.seatingFilterOptions)
    const patronFilterOptions = useFilterStore((state) => state.patronFilterOptions)
    const independentCraftOnly = useFilterStore((state) => state.independentCraftOnly)
    const { setCurrentRoute } = useSavedRoutesStore()

    const map = useRef<mapboxgl.Map | null>()
    const popupRef = useRef(new mapboxgl.Popup({ offset: 15, maxWidth: 'none' }))

    const throttledSearchLocations = useCallback(throttle(searchLocations, 1000), [
        activeTab,
        searchAsMapMoves,
    ])

    const getRouteGeoJson = async (
        routeStops: BreweryRouteGeoCoord[]
    ): Promise<IMapBoxDirections> => {
        const routeStopsURL = routeStops
            .map((coord, index) => {
                const lon = (+coord?.lon)?.toFixed(4)
                const lat = (+coord?.lat)?.toFixed(4)
                return index === routeStops.length - 1 ? `${lon},${lat}` : `${lon},${lat};`
            })
            .join('')
        let query

        if (routeStops?.length > 1) {
            query = await fetch(
                `https://api.mapbox.com/directions/v5/mapbox/${profile}/${routeStopsURL}?steps=true&geometries=geojson&overview=full&access_token=${process.env.NEXT_PUBLIC_MAPBOX_TOKEN}`,
                { method: 'GET' }
            )
            const json = await query.json()
            setCurrentRoute(json)
            setDirectionLegs(json?.routes?.[0]?.legs)
            return json
        }
    }

    // Function that fires on map move and useEffect that adds or removes the map listener when searchAsMapMoves changes
    const onMapMove = async () => {
        setResultsLoaded(false)
        setSelectedWildcardOption({
            label: 'Current map area',
            value: 'Current map area',
            coordinates: map.current.getCenter() as unknown as number[],
            type: 'place',
            id: 'current-map-area',
        })
        setShowMapLocationTitle(false)
        const bounds: mapboxgl.LngLatBoundsLike = map.current.getBounds()
        await throttledSearchLocations({
            wildcardText,
            viewport: { longitude: userLocationState.lng, latitude: userLocationState.lat },
            stateFilter,
            brewFilterOptions,
            patronFilterOptions,
            foodFilterOptions,
            seatingFilterOptions,
            bounds,
            searchAsMapMoves,
            radius,
            locationHours: {
                currentlyOpen: openNowHours,
            },
        }).then(() => setResultsLoaded(true))
    }

    useEffect(() => {
        if (searchAsMapMoves) {
            map.current?.on('dragend', onMapMove)
            map.current?.on('zoomend', onMapMove)
        }
        return () => {
            map.current?.off('dragend', onMapMove)
            map.current?.off('zoomend', onMapMove)
        }
    }, [
        searchAsMapMoves,
        map.current,
        stateFilter,
        radius,
        wildcardText,
        patronFilterOptions,
        foodFilterOptions,
        seatingFilterOptions,
        brewFilterOptions,
        independentCraftOnly,
        userLocationState,
        openNowHours,
    ])

    useEffect(() => {
        if (map.current) {
            map.current.loadImage(markerImage.src, (error, image) => {
                if (error) throw error
                if (!map.current.hasImage('markerIcon')) map.current.addImage('markerIcon', image)
            })
            addUpdatePostSourceLayers(map.current)
            addClusterPointClickListeners(map.current, popupRef)

            map.current.on('movestart', () => {
                popupRef.current.remove()
            })
        }
    }, [locations])

    const handleMapLoad = () => {
        if (directionCoordinates?.length > 1) addUpdateRouteMarkersFromUserLocation(map.current)
        addUpdatePostSourceLayers(map.current)
        addStateSourceLayers(map.current)
        addMarkersLayers(map.current)
        addClusterPointClickListeners(map.current, popupRef)
        addCursorStyleListeners(map.current)
    }
    useEffect(() => {
        if (map.current) map.current.resize()
    }, [fullScreenShowFullMap])

    useEffect(() => {
        map.current?.on('load', () => {
            handleMapLoad() // use this to kick off the initial actions in a useEffect
            map.current.resize()
            map.current?.on('click', (e) => handleStateClick(e, map.current))
        })
        return () => {
            map.current?.off('click', (e) => handleStateClick(e, map.current))
        }
    }, [map.current])

    return {
        throttledSearchLocations,
        getRouteGeoJson,
        map,
        onMapMove,
    }
}

export default useMapFunctions
