import { createMultiStyleConfigHelpers, DrawerProps } from '@chakra-ui/react'
import { drawerAnatomy } from '@chakra-ui/anatomy'
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    drawerAnatomy.keys
)

/* 
Anatomy of Drawer Component:
https://chakra-ui.com/docs/components/modal/theming */

const baseStyle = definePartsStyle({
    dialog: {
        pb: 0,
    },
    dialogContainer: {},
    header: {
        p: {
            base: 6,
            lg: 8,
        },
        display: 'flex',
        fontSize: '2xl',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    footer: {},
    body: {
        pl: {
            base: 6,
            lg: 8,
        },
        pr: {
            base: 6,
            lg: 8,
        },
        pb: {
            base: 6,
            lg: 8,
        },
        pt: 0,
    },
    closeButton: {
        position: 'relative',
        w: 8,
        h: 8,
        top: 'unset',
        right: 'unset',
    },
})

const sizes = {}
const variants = {}

const DrawerConfig = defineMultiStyleConfig({ baseStyle, variants, sizes })

export default DrawerConfig
