import * as CC from '@chakra-ui/react'
import classNames from 'classnames'
import { set } from 'lodash'
import React from 'react'
import { useFavoriteActionsStore } from '../state/useFavoriteActionsStore'

const FavoritesNoteInput: React.FC = () => {
    const favoritesNote = useFavoriteActionsStore((state) => state.favoritesNote)
    const setFavoritesNote = useFavoriteActionsStore((state) => state.setFavoritesNote)
    const setFavoriteIsChanged = useFavoriteActionsStore((state) => state.setFavoriteIsChanged)
    const updatedNote = useFavoriteActionsStore((state) => state.updatedNote)
    const setUpdatedNote = useFavoriteActionsStore((state) => state.setUpdatedNote)
    const setActiveFavoriteAction = useFavoriteActionsStore(
        (state) => state.setActiveFavoriteAction
    )
    React.useEffect(() => {
        setUpdatedNote(favoritesNote)
    }, [])
    React.useEffect(() => {
        if (updatedNote === favoritesNote) setFavoriteIsChanged(false)
        else setFavoriteIsChanged(true)
    }, [updatedNote])

    return (
        <CC.Stack spacing={2} mt={4}>
            <CC.Text className='text-sm font-bold text-dark'>My Note (optional)</CC.Text>
            <CC.Textarea
                placeholder={'Add a note...'}
                className=' text-sm'
                colorScheme='gray'
                focusBorderColor='gray.500'
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setUpdatedNote(e.target.value)
                }
                maxLength={280}
                value={updatedNote || ''}
            />
            <CC.Text
                className={classNames(
                    'flex w-full justify-end text-xs font-semibold',
                    updatedNote?.length > 279 ? 'text-error' : 'text-dark'
                )}
            >
                {updatedNote?.length}/280
            </CC.Text>
        </CC.Stack>
    )
}

export default FavoritesNoteInput
