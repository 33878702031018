import { BreweryRouteGeoCoord, InputBreweryRouteGeoCoordInput } from 'src/types/schema/graphql'

export const coordinatesToInput = (
    coordinates: BreweryRouteGeoCoord[]
): InputBreweryRouteGeoCoordInput[] =>
    coordinates?.map(({ lat, lon, title, placeName, location }) => ({
        lat,
        lon,
        title,
        placeName,
        location:
            location?.id && location?.brand?.id
                ? {
                      brandId: location?.brand?.id,
                      locationId: location?.id,
                  }
                : null,
    }))
