import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { LoadingStatus } from 'src/types/ui'
import { ParsedUrlQuery } from 'querystring'
import { guidGenerator } from 'src/features/Map/utils/functions'
import { useAlerts } from 'src/features/alerts/state/useAlerts'

interface FormData {
    status: LoadingStatus
    email: string
    name: string
    code: string
    password: string
}
interface DefaultData extends FormData {
    status: LoadingStatus
    returnPath: string
    showPassword: boolean
    showPasswordRequirements: boolean
    passwordStrength: number
    titleOverride: string
    descriptionOverride: string
}

interface ILoginFormFeedback {
    message: string
    type: 'success' | 'error' | 'info' | 'warning'
}

interface ILoginFormStore extends DefaultData {
    setStatus: (status: LoadingStatus) => void
    updateField: (key: keyof FormData, value: string) => void
    togglePasswordVisibility: () => void
    togglePasswordRequirements: (show?: boolean) => void
    populateInitialValues: (query: ParsedUrlQuery) => boolean
    setPasswordStrength: (strength: number) => void
    setOverrideContent: ({
        titleOverride,
        descriptionOverride,
    }: {
        titleOverride: string
        descriptionOverride: string
    }) => void
    loginFormFeedback: ({ message, type }: ILoginFormFeedback) => void
    resetLoginForm: () => void
}
const defaultValues: DefaultData = {
    status: 'loading',
    email: '',
    name: '',
    code: '',
    password: '',
    returnPath: null,
    showPassword: false,
    showPasswordRequirements: false,
    passwordStrength: 0,
    descriptionOverride: null,
    titleOverride: null,
}

const loginFormStore = create<ILoginFormStore>()(
    devtools(
        (set, get) => ({
            ...defaultValues,
            populateInitialValues: (query) => {
                if (Object.keys(query).length < 1) {
                    set({ status: 'ready' })
                    return false
                }
                const name = query?.name
                    ? decodeURIComponent(String(query.name)).replace(/\s/s, '+')
                    : get().name
                const email = query?.email
                    ? decodeURIComponent(String(query.email)).replace(/\s/s, '+')
                    : get().email
                const code = query?.code ? String(query.code) : get().code
                const password = query?.password
                    ? decodeURIComponent(String(query.password))
                    : get().password
                const returnPath = query?.return
                    ? decodeURIComponent(String(query.return))
                    : get().returnPath
                set({
                    name,
                    email,
                    code,
                    password,
                    returnPath,
                })
                setTimeout(() => {
                    set({ status: 'ready' })
                }, 200)
                return true
            },
            setPasswordStrength: (strength) => set({ passwordStrength: strength }),
            togglePasswordVisibility: () =>
                set({
                    showPassword: !get().showPassword,
                }),
            togglePasswordRequirements: (show) =>
                set({
                    showPasswordRequirements:
                        show !== undefined ? show : !get().showPasswordRequirements,
                }),
            setStatus: (status) => set({ status }),
            loginFormFeedback: ({ message, type }) => {
                const uniqueId = guidGenerator()
                useAlerts.getState().addAlert({
                    id: `${type}--${uniqueId}`,
                    theme: type,
                    icon: `HeroIcons.${type === 'success' ? 'CheckCircle' : 'XCircle'}`,
                    title: message,
                    status: 'active',
                    duration: 6,
                })
            },
            setOverrideContent: ({ titleOverride, descriptionOverride }) =>
                set({
                    titleOverride,
                    descriptionOverride,
                }),
            updateField: (key, value) =>
                set({
                    [key]: key === 'email' ? value.toLowerCase() : value,
                }),
            resetLoginForm: () => set({ ...defaultValues }),
        }),
        {
            name: 'loginFormStore',
        }
    )
)
export { loginFormStore }
