import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
    container: {
        boxShadow: 'none',
    },
    header: { color: 'gray.500', fontWeight: 'extrabold', fontSize: '2xl', pb: '16px' },
    body: {
        p: 6,
        borderWidth: 1,
        borderRadius: 'lg',
        borderColor: 'gray.100',
    },
})

const multiStyleConfig = defineMultiStyleConfig({ baseStyle })

const CardConfig = { baseStyle, multiStyleConfig }

export default CardConfig
