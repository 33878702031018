import { IDirectionStep } from '../../types'

export const convertTripMetrics = (step: IDirectionStep) => {
    const distanceMiles: number = step?.distance ? step?.distance / 1609.34 : 0
    const hours = Math.floor((step?.duration ? step?.duration / 60 : 0) / 60)
    const decimal: number = step?.duration ? step?.duration / 60 : 0
    const minutes: number = +decimal?.toFixed(0) % 60
    const totalTime =
        hours > 0 ? `${hours} ${hours > 1 ? 'hours' : 'hour'} ${minutes}mins` : `${minutes} mins`
    return { totalTime, distance: `${parseFloat(distanceMiles.toFixed(1))} miles` }
}
