import React from 'react'
import { loginFormStore } from '../../state/loginFormStore'
import { useLoginGateStore } from '../../state/useLoginGateStore'
import { userStore } from '../../state/userStore'
import * as CC from '@chakra-ui/react'
import * as FormFields from './fields'
import LoginFormWrapper from './partials/LoginFormWrapper'
import RegistrationFormStep from '../../types/RegistrationFormStep'
import SubmitButton from './partials/SubmitButton'

interface ILoginForm {}

const ModalLoginForm: React.FC<ILoginForm> = () => {
    const setLoginGateActive = useLoginGateStore((state) => state.setLoginGateActive)
    const setCreateUserFlowActive = useLoginGateStore((state) => state.setCreateUserFlowActive)
    const setUserFeedback = useLoginGateStore((state) => state.setUserFeedback)
    const setRegisterFormStep = useLoginGateStore((state) => state.setRegisterFormStep)
    const authorize = userStore((state) => state.authorize)
    const email = loginFormStore((state) => state?.email)
    const password = loginFormStore((state) => state?.password)
    const setStatus = loginFormStore((state) => state.setStatus)

    const handleSubmit = async () => {
        const success = await authorize({ email, password })
        if (success) setLoginGateActive(false)
    }
    const handleSignUp = () => {
        setCreateUserFlowActive(true)
        setRegisterFormStep(RegistrationFormStep.Register)
        setUserFeedback(null)
    }

    React.useEffect(() => {
        setStatus('ready')
    }, [])

    return (
        <LoginFormWrapper onSubmit={handleSubmit}>
            <CC.Stack spacing={6}>
                <FormFields.Email format='icon' />
                <FormFields.Password action='enter' format='icon' />
                <SubmitButton label='Log In' loadingText='Logging In' />

                <div className='flex w-full justify-center'>
                    <p className='mr-1'>Dont have an account?</p>
                    <CC.Button
                        onClick={handleSignUp}
                        className='border-none bg-transparent px-0 py-1 text-sm font-semibold text-info transition hover:text-black'
                    >
                        Sign up
                    </CC.Button>
                </div>
            </CC.Stack>
        </LoginFormWrapper>
    )
}
export default ModalLoginForm
