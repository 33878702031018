import * as UI from '@brewlogix/blx-ui'

import { AlertThemes, useAlerts } from '../state/useAlerts'

import React from 'react'
import classNames from 'classnames'
import * as CC from '@chakra-ui/react'

export const GlobalAlerts: React.FC = () => {
    const alerts = useAlerts((state) => state.alerts)
    const dismissAlert = useAlerts((state) => state.dismissAlert)
    const getIcon = (theme: AlertThemes) => {
        switch (theme) {
            case 'error':
                return 'HeroIcons.XCircle'
            case 'success':
                return 'HeroIcons.CheckCircle'
            case 'warning':
                return 'HeroIcons.ExclamationCircle'
            default:
                break
        }
    }

    return (
        <CC.Portal>
            {alerts?.length > 0 && (
                <div className='global-alerts fixed bottom-4 z-[2000] flex h-auto w-full flex-col items-center justify-center space-y-4'>
                    {alerts.map(
                        ({
                            title,
                            id,
                            description = '',
                            status = 'active',
                            theme = 'success',
                            icon,
                            showCloseButton = true,
                        }) => (
                            <UI.Alert
                                key={id}
                                id={id}
                                title={title}
                                description={description}
                                icon={icon || getIcon(theme)}
                                theme={theme}
                                allowClose={showCloseButton}
                                format='inline'
                                customClasses={classNames('w-full max-w-xl', `alert--${status}`)}
                                onClose={() => dismissAlert(id)}
                            />
                        )
                    )}
                </div>
            )}
        </CC.Portal>
    )
}
