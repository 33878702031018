import React from 'react'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export type AlertThemes =
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'light'
    | 'primary'
    | 'dark'
    | 'link'
    | 'default'
export interface IAlert {
    id: string
    title: string
    description?: React.ReactNode
    theme?: AlertThemes
    status?: 'active' | 'dismissed'
    duration?: 6 | 10
    icon?: string
    showCloseButton?: boolean
}
export class Alert {
    alert: IAlert

    constructor(alert: IAlert) {
        this.setAlert(alert)
    }

    setAlert = (alert: IAlert) => useAlerts.getState().addAlert(alert)
}

interface IuseAlerts {
    alerts: IAlert[]
    addAlert: (alert: IAlert) => void
    dismissAlert: (id: string) => void
    resetAlerts: () => void
}

export const useAlerts = create<IuseAlerts>()(
    devtools(
        (set, get) => ({
            alerts: [],
            addAlert: async (alert) => {
                const allAlerts = get().alerts
                set({ alerts: [...allAlerts, alert] })

                if (alert?.duration) {
                    setTimeout(() => {
                        get().dismissAlert(alert?.id)
                    }, alert.duration * 1000)
                }
            },
            dismissAlert: (id) => {
                const alerts = get().alerts
                const alertIndex = alerts.findIndex((alert) => alert.id === id)
                if (alerts[alertIndex]) {
                    alerts[alertIndex].status = 'dismissed'
                    set({ alerts: alerts })
                    const alertsFiltered = alerts.filter((alert) => alert.id !== id)

                    setTimeout(() => {
                        set({ alerts: alertsFiltered })
                    }, 600)
                }
            },
            resetAlerts: async () => {
                const allAlerts = get().alerts
                allAlerts.forEach((item) => get().dismissAlert(item.id))
            },
        }),
        { name: 'alerts-store' }
    )
)
