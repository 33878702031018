import mapboxgl from 'mapbox-gl'

export const addMarkersLayers = (mapRef: mapboxgl.Map) => {
    if (mapRef?.getSource('breweries')) {
        if (!mapRef?.getLayer('clusters')) {
            mapRef.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'breweries',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': '#333333',
                    'circle-radius': ['step', ['get', 'point_count'], 14, 100, 20, 750, 27],
                    'circle-stroke-color': '#fff',
                    'circle-stroke-width': 3,
                },
            })
        }
        if (!mapRef?.getLayer('cluster-count')) {
            mapRef.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'breweries',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 16,
                    'text-allow-overlap': true,
                },
                paint: {
                    'text-color': '#fff',
                },
            })
        }
        if (!mapRef?.getLayer('unclustered-point')) {
            mapRef.addLayer({
                id: 'unclustered-point',
                type: 'symbol',
                source: 'breweries',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': 'markerIcon',
                    'icon-size': 0.25,
                    'icon-allow-overlap': true,
                },
            })
        }
    }
}
