import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { GlobalAlerts } from 'src/features/alerts/components/GlobalAlerts'
import { gtmPageView } from 'src/features/tracking/utils/gtm'

interface ILayout {
    children: React.ReactNode
}

const Layout: React.FC<ILayout> = ({ children }) => {
    const router = useRouter()

    useEffect(() => {
        router.events.on('routeChangeComplete', gtmPageView)
        return () => {
            router.events.off('routeChangeComplete', gtmPageView)
        }
    }, [router.events])

    return (
        <>
            <Head>
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1.0'
                    key='viewport'
                />
                <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
                <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
                <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
                <link rel='icon' href='/favicon.png' />
            </Head>
            {children}
            <GlobalAlerts />
        </>
    )
}

export { Layout }
