import React from 'react'

// Components
import { Layout } from '../src/components/global/Layout'

import Script from 'next/script'
import '../src/styles/main.css'
import '../src/styles/mapbox.css'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import { AppProps } from 'next/app'
import { GTM_ID } from 'src/features/tracking/utils/gtm'
import amplifyConfig from 'src/api/config/amplifyConfig'
import { Amplify } from 'aws-amplify'
import { ChakraProvider } from '@chakra-ui/react'
import theme from 'src/chakra/theme'
import { RegistrationSource } from 'src/types/schema/graphql'
import { AppPropsWithLayout } from 'src/types/ui'
import DefaultLayout from 'src/components/global/DefaultLayout'

Amplify.configure({
    ...amplifyConfig,
    ssr: true,
    API: {
        graphql_headers: async () => {
            return null
        },
    },
    Auth: {
        clientMetadata: {
            app: RegistrationSource.BreweryDbWeb,
        },
    },
})

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.config({ ignoreMobileResize: true })

const App: React.FC<AppProps> = ({ Component, pageProps }: AppPropsWithLayout) => {
    const getLayout = Component.getLayout
        ? Component.getLayout
        : (page: React.ReactNode) => <DefaultLayout>{page}</DefaultLayout>
    return (
        <ChakraProvider theme={theme}>
            <Script
                id='GTM-container'
                strategy='afterInteractive'
                dangerouslySetInnerHTML={{
                    __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${GTM_ID}');
            `,
                }}
            />
            <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
                    height='0'
                    width='0'
                    style={{ display: 'none', visibility: 'hidden' }}
                />
            </noscript>
            <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
        </ChakraProvider>
    )
}

export default App
