import * as CC from '@chakra-ui/react'
import * as HeroIcons from '@heroicons/react/outline'
import { motion } from 'framer-motion'
import React from 'react'
import { useLoginGateStore } from '../state/useLoginGateStore'

const RegistrationFeedback: React.FC = () => {
    const userFeedback = useLoginGateStore((state) => state.userFeedback)
    const getHeroIcon = () => {
        switch (userFeedback.type) {
            case 'info':
                return HeroIcons.InformationCircleIcon
            case 'success':
                return HeroIcons.CheckCircleIcon
            case 'warning':
                return HeroIcons.ExclamationIcon
            case 'error':
                return HeroIcons.ExclamationCircleIcon
            default:
                return HeroIcons.InformationCircleIcon
        }
    }
    const icon = getHeroIcon()
    return (
        <motion.div
            className='flex w-full justify-center'
            initial={{ y: 25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 25, opacity: 0 }}
            key='userFeedback'
        >
            <CC.Alert variant={userFeedback.type}>
                <CC.Icon fontSize='lg' as={icon} mr={2} />
                {userFeedback.message}
            </CC.Alert>
        </motion.div>
    )
}
export default RegistrationFeedback
