export const getSharedBoardQuery = /* GraphQL */ `query GetSharedBoard($input: inputSharedBoard!) {
	getSharedBoard(input: $input){
		updatedDate
		title
		lastUpdatedBy
		id
		createdDate
		createdBy
        description
		favorites {
            favoriteBrands {
				brandId
				isActive
				notes
			}
			favoriteLocations {
				locationId
				isActive
				brandId
				notes
			}
			favoriteProducts {
				brandId
				isActive
				notes
				productId
			}
		}
		isPrivate
		routes {
          id
          createdBy
            createdDate
            description
            distance
            duration
            id
            lastUpdatedBy
            notes
            routeId
            title
            updatedDate
            coordinates {
                address {
                    city
                    country
                    geo {
                        lat
                        lon
                        title
                    }
                    postalCode
                    state
                    street
                    street2
                }
                lat
                lon
                placeName
                title
                location {
                    title
                    id
                    slug
                    address {
						city
						country
						geo {
                            lat
                            lon
                            title
                        }
						postalCode
						state
						street
						street2
					}
                }
            }
      }
	}
}`
