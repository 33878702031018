import React from 'react'
import * as CC from '@chakra-ui/react'
import * as Hero from '@heroicons/react/solid'

interface IFieldLabel {
    children: React.ReactNode
    format: 'icon' | 'label'
    icon?: string
}

const FieldLabel: React.FC<IFieldLabel> = ({ children, icon, format }) => {
    return (
        <>
            {format === 'icon' ? (
                <CC.InputLeftElement pointerEvents='none'>
                    <CC.Icon as={Hero?.[icon]} boxSize={6} p={0.5} />
                </CC.InputLeftElement>
            ) : (
                <CC.FormLabel fontSize='sm' fontWeight='bold'>
                    {children}
                </CC.FormLabel>
            )}
        </>
    )
}

export default FieldLabel
