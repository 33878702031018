import { gql } from 'graphql-request'
export const updateBoardMutation = gql`mutation UpdateBoard($input: inputUpdateBoard!) {
	updateBoard(input: $input){
		updatedDate
		title
		description
		lastUpdatedBy
		id
		createdDate
		createdBy
		favorites {
			brands
			favoriteBrands {
				brandId
				isActive
				notes
			}
			favoriteLocations {
				locationId
				isActive
				brandId
				notes
			}
			favoriteProducts {
				productId
				isActive
				brandId
				notes
			}
			locations {
				brandId
				locationId
			}
			products {
				brandId
				productId
			}
		}
	}
}`
