import mapboxgl from 'mapbox-gl'
import { mapStore } from '../../state/mapStore'
export const addUpdatePostSourceLayers = (mapRef: mapboxgl.Map) => {
    const { locations } = mapStore.getState()
    const geoJsonPosts: mapboxgl.GeoJSONSourceRaw = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: locations?.map((loc) => {
                const shortLon = +loc?.address?.geo?.lon?.toFixed(8)
                const shortLat = +loc?.address?.geo?.lat?.toFixed(8)
                return {
                    type: 'Feature',
                    properties: {
                        id: loc?.id,
                        title: loc?.brand?.title,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [shortLon, shortLat],
                    },
                }
            }),
        },
        cluster: true,
        clusterMinPoints: 5,
        clusterMaxZoom: 10, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    }

    if (!mapRef?.getSource('breweries')) {
        mapRef?.addSource('breweries', geoJsonPosts)
    } else {
        const source = mapRef?.getSource('breweries') as mapboxgl.GeoJSONSource
        source.setData(geoJsonPosts.data as keyof mapboxgl.GeoJSONSource)
    }
}
