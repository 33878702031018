import { guidGenerator } from 'src/features/Map/utils/functions'
import { Asset, Board, Favorites } from 'src/types/schema/graphql'
import getDefaultIconByProduct from 'src/util/helpers/getDefaultIconByProduct'
import { getBrandById, getLocationById, getProductById } from '../utils/requests'

export const getBoardAssets = async (favorites: Favorites, title) => {
    const products = favorites?.favoriteProducts.slice(0, 3)
    const locations = favorites?.favoriteLocations.slice(0, 3)
    const brands = favorites?.favoriteBrands.slice(0, 3)
    let productAssetArr = []
    let locationAssetArr = []
    let brandAssetArr = []
    if (products?.length > 0) {
        const arr: Asset[] = await Promise.all(
            products?.map(async (product) => {
                const data = await getProductById(product?.productId, product?.brandId)
                const assets = data?.assets.length > 0 ? data?.assets : data?.brand?.assets
                const defaultImgData = getDefaultIconByProduct(data)
                return (
                    assets?.[0] ||
                    ({
                        alt: '',
                        caption: '',
                        path: defaultImgData.src,
                        type: 'fallback',
                        __typename: data?.__typename,
                    } as unknown as Asset)
                )
            })
        )
        productAssetArr = arr
    }
    if (locations?.length > 0) {
        const arr: Asset[] = await Promise.all(
            locations?.map(async (location) => {
                const data = await getLocationById(location?.locationId, location?.brandId)
                const assets = data?.assets.length > 0 ? data?.assets : data?.brand?.assets
                return assets?.[0]
            })
        )
        locationAssetArr = arr
    }
    if (brands?.length > 0) {
        const arr: Asset[] = await Promise.all(
            brands?.map(async (brand) => {
                const data = await getBrandById(brand?.brandId)
                return data?.assets?.[0]
            })
        )
        brandAssetArr = arr
    }
    return {
        title,
        productAssets: productAssetArr,
        locationAssets: locationAssetArr,
        brandAssets: brandAssetArr,
    }
}
