import { IAlert } from 'src/features/alerts/state/useAlerts'

export const alertMessages: { [key: string]: IAlert } = {
    profileSuccess: {
        id: 'profileSuccess-success',
        title: 'Success',
        description: 'Successfully updated profile.',
        icon: 'HeroIcons.CheckCircle',
        status: 'active',
        theme: 'success',
        duration: 6,
    },
    profileError: {
        id: 'profileError-error',
        title: 'Error',
        description: 'Error updating profile. Please try again later.',
        icon: 'HeroIcons.XCircle',
        status: 'active',
        theme: 'error',
        duration: 6,
    },
    deleteSuccess: {
        id: 'deleteSuccess-success',
        title: 'Success',
        description: 'Successfully deleted account.',
        icon: 'HeroIcons.CheckCircle',
        status: 'active',
        theme: 'success',
        duration: 6,
    },
    deleteError: {
        id: 'deleteError-error',
        title: 'Error',
        description: 'Error deleting account, Please try again later.',
        icon: 'HeroIcons.XCircle',
        status: 'active',
        theme: 'error',
        duration: 6,
    },
    passwordSuccess: {
        id: 'passwordSuccess-success',
        title: 'Success',
        description: 'Successfully updated password.',
        icon: 'HeroIcons.CheckCircle',
        status: 'active',
        theme: 'success',
        duration: 6,
    },
    passwordResponseError: {
        id: 'passwordResponseError-error',
        title: 'Error',
        description: "Couldn't update password. Please try again in a little bit.",
        icon: 'HeroIcons.XCircle',
        status: 'active',
        theme: 'error',
        duration: null,
    },
    passwordAllNeedToBeFilled: {
        id: 'passwordAllNeedToBeFilled-error',
        title: 'Error',
        description: 'All password fields must be filled.',
        icon: 'HeroIcons.XCircle',
        status: 'active',
        theme: 'error',
        duration: null,
    },
    passwordCantBeSame: {
        id: 'fpasswordCantBeSame-error',
        title: 'Error',
        description: "Current password can't be the same as new password.",
        icon: 'HeroIcons.XCircle',
        status: 'active',
        theme: 'error',
        duration: null,
    },
    passwordNewCantBeEmpty: {
        id: 'passwordNewCantBeEmpty-error',
        title: 'Error',
        description: "New password filed can't be empty.",
        icon: 'HeroIcons.XCircle',
        status: 'active',
        theme: 'error',
        duration: null,
    },
    passwordOldCantBeEmpty: {
        id: 'passwordOldCantBeEmpty-error',
        title: 'Error',
        description: "Old password filed can't be empty.",
        icon: 'HeroIcons.XCircle',
        status: 'active',
        theme: 'error',
        duration: null,
    },
    passwordTooWeak: {
        id: 'passwordTooWeak-error',
        title: 'Error',
        description:
            'New password is too weak. Please choose a password that meets all security requirements.',
        icon: 'HeroIcons.XCircle',
        status: 'active',
        theme: 'error',
        duration: null,
    },
}
