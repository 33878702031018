import React from 'react'
import * as CC from '@chakra-ui/react'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'

interface SubmitButtonProps {
    loadingText: string
    label: string
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ label, loadingText }) => {
    const status = loginFormStore((state) => state.status)
    return (
        <CC.Button
            variant='dark'
            size='md'
            isLoading={status === 'loading'}
            loadingText={loadingText}
            type='submit'
            py={4}
        >
            {label}
        </CC.Button>
    )
}

export default SubmitButton
