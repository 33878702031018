import { RequestInput, apiRequest } from 'src/api/apiRequest'
import { getProductByIdQuery } from '../graph/getProductByIdQuery'
import { CompositeProduct, InputGetById, Product } from 'src/types/schema/graphql'

export const getProductById = async (productId: string, brandId: string) => {
    const input: RequestInput<InputGetById> = {
        query: getProductByIdQuery,
        args: {
            input: {
                itemId: productId,
                parentId: brandId,
            },
        },
        endpoint: 'getProductById',
        file: 'getProductById.ts',
        publicQuery: true,
    }
    const { data, success, error } = await apiRequest<CompositeProduct, InputGetById>(input)
    if (success) {
        return data
    } else {
        console.log(error)
    }
}
