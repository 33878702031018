import { useLoginGateStore } from '../state/useLoginGateStore'
import addFavoritesImage from '../../../../public/assets/loginGate/LoginGate--AddFavorite.jpg'
import saveRouteImage from '../../../../public/assets/loginGate/LoginGate--SaveRoute.jpg'
import RegistrationFormStep from '../types/RegistrationFormStep'
import { createUserFormConfig } from './createUserFormConfig'

const { formSteps } = createUserFormConfig
const headerImage = () =>
    useLoginGateStore.getState().userAction === 'Save Route' ? saveRouteImage : addFavoritesImage
const imageAlt = () =>
    useLoginGateStore.getState().userAction === 'Save Route'
        ? 'Five beers sitting on a table at a brewery in a row'
        : 'Two people enjoying a beer at their favorite brewery'
const loginTitle = () =>
    useLoginGateStore.getState().userAction === 'Save Route'
        ? 'Log in to save a BreweryRoute'
        : 'Log in to add a favorite'
const loginDescription = () =>
    useLoginGateStore.getState().userAction === 'Save Route'
        ? 'You must be logged in to save a BreweryRoute. Log in or Create an Account'
        : 'You must be logged in to add favorites. Log in or Create an Account'
const registerTitle = () =>
    useLoginGateStore.getState().registerFormStep !== RegistrationFormStep.RegisterVerify &&
    formSteps[0]?.title
const registerFormDescription = () =>
    useLoginGateStore.getState().registerFormStep !== RegistrationFormStep.RegisterVerify &&
    formSteps[0]?.description

export const formHeaderConfig = {
    login: {
        title: loginTitle,
        description: loginDescription,
        image: { src: headerImage, alt: imageAlt },
    },
    register: {
        title: registerTitle,
        description: registerFormDescription,
        image: { src: headerImage, alt: imageAlt },
    },
    registerVerify: {
        title: () => 'Almost Done!',
        description: () => 'Verify your email address to log in.',
        image: { src: headerImage, alt: imageAlt },
    },
}
