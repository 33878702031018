import React from 'react'
import { Asset, BreweryRoute, Favorites } from 'src/types/schema/graphql'
import { CardImage } from 'src/features/account/components/favorites'
import { getBoardAssets } from 'src/features/saved/helpers/getBoardAssets'
import mapImagePreview from '../../../../public/assets/map-preview.png'
import Image from 'next/image'

interface IFavoritesModalThumbnail {
    favorites: Favorites
    title: string
    route: BreweryRoute
}

const FavoritesModalThumbnail: React.FC<IFavoritesModalThumbnail> = ({
    route,
    favorites,
    title,
}) => {
    const [allAssets, setAllAssets] = React.useState<Asset[]>([])

    React.useEffect(() => {
        ;(async () => {
            const { productAssets, locationAssets, brandAssets } = await getBoardAssets(
                favorites,
                title
            )
            const displayAssets = [
                locationAssets[0],
                productAssets[0],
                productAssets[1],
                productAssets[2],
                locationAssets[1],
                locationAssets[2],
                brandAssets[0],
                brandAssets[1],
                brandAssets[2],
            ]
            const filtered = displayAssets.filter((item) => item !== undefined)
            setAllAssets(filtered)
        })()
    }, [])

    return (
        <div className='h-14 w-14 min-w-[3.5rem] rounded-lg bg-gray-200'>
            {allAssets.length > 0 ? (
                <CardImage
                    customClasses='h-14 w-14 min-w-[3.5rem] flex align-center justify-center overflow-hidden rounded-lg object-cover'
                    asset={allAssets[0]}
                    alt={'Location Logo'}
                />
            ) : (
                <>
                    {route && (
                        <Image
                            src={mapImagePreview}
                            alt='Map Image Preview'
                            className='rounded-lg'
                        />
                    )}
                </>
            )}
        </div>
    )
}
export default FavoritesModalThumbnail
