import { BrewOption, FoodOption, PatronOption, SeatingOption } from 'src/types/schema/graphql'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { ShowAllFilters } from '../types/MapTypes'

interface IStateFilter {
    label: string
    value: number
}

interface IFilterStore {
    brewFilterOptions: BrewOption[]
    setBrewFilterOptions: (brewFilterOptions: BrewOption[]) => void
    stateFilter: IStateFilter
    setStateFilter: (stateFilter: IStateFilter) => void
    patronFilterOptions: PatronOption[]
    setPatronFilterOptions: (patronFilterOptions: PatronOption[]) => void
    foodFilterOptions: FoodOption[]
    setFoodFilterOptions: (foodFilterOptions: FoodOption[]) => void
    seatingFilterOptions: SeatingOption[]
    setSeatingFilterOptions: (seatingFilterOptions: SeatingOption[]) => void
    showFiltersDrawer?: boolean
    setShowFiltersDrawer?: (showFiltersDrawer: boolean) => void
    toggleBrewArrowUp: boolean
    setToggleBrewArrowUp: (toggleBrewArrowUp: boolean) => void
    showAllFilters: ShowAllFilters
    setShowAllFilters: (showAllFilters: ShowAllFilters) => void
    filterCount: number
    setFilterCount: () => void
    toggleStateArrowUp: boolean
    setToggleStateArrowUp: (toggleStateArrowUp: boolean) => void
    togglePatronArrowUp: boolean
    setTogglePatronArrowUp: (togglePatronArrowUp: boolean) => void
    toggleFoodArrowUp: boolean
    setToggleFoodArrowUp: (toggleFoodArrowUp: boolean) => void
    toggleSeatingArrowUp: boolean
    setToggleSeatingArrowUp: (toggleSeatingArrowUp: boolean) => void
    toggleHoursArrowUp: boolean
    setToggleHoursArrowUp: (toggleHoursArrowUp: boolean) => void
    independentCraftOnly: boolean
    openNowHours: boolean
    setOpenNowHours: (openNowHours: boolean) => void
    setIndependentCraftOnly: (independentCraftOnly: boolean) => void
    newStateOptions: IStateFilter[]
    setNewStateOptions: (newStateOptions: IStateFilter[]) => void
    clearBorders: boolean
    setClearBorders: (clearBorders: boolean) => void
    fallBackStateFilter: IStateFilter
    setFallBackStateFilter: (fallBackStateFilter: IStateFilter) => void
    pastStates: number[]
    setPastStates: (pastStates: number) => void
    allStates: number[]
}

export const useFilterStore = create<IFilterStore>()(
    devtools(
        (set, get) => ({
            brewFilterOptions: [],
            setBrewFilterOptions: (brewFilterOptions) => set({ brewFilterOptions }),
            patronFilterOptions: [],
            setPatronFilterOptions: (patronFilterOptions) => set({ patronFilterOptions }),
            foodFilterOptions: [],
            setFoodFilterOptions: (foodFilterOptions) => set({ foodFilterOptions }),
            seatingFilterOptions: [],
            setSeatingFilterOptions: (seatingFilterOptions) => set({ seatingFilterOptions }),
            openNowHours: false,
            setOpenNowHours: (openNowHours) => set({ openNowHours }),
            showFiltersDrawer: false,
            setShowFiltersDrawer: (showFiltersDrawer) => set({ showFiltersDrawer }),
            toggleBrewArrowUp: true,
            setToggleBrewArrowUp: (toggleBrewArrowUp) => set({ toggleBrewArrowUp }),
            showAllFilters: {
                brewOptions: false,
                foodOptions: false,
                patronOptions: false,
                seatingOptions: false,
                hoursOptions: false,
            },
            setShowAllFilters: (showAllFilters) => set({ showAllFilters }),
            filterCount: 0,
            setFilterCount: () =>
                set((state) => {
                    const stateFilterCount = state.stateFilter.label === 'All' ? 0 : 1
                    return {
                        filterCount:
                            state.brewFilterOptions.length +
                            state.patronFilterOptions.length +
                            state.foodFilterOptions.length +
                            state.seatingFilterOptions.length +
                            (state.independentCraftOnly ? 1 : 0) +
                            stateFilterCount,
                    }
                }),
            toggleStateArrowUp: true,
            setToggleStateArrowUp: (toggleStateArrowUp) => set({ toggleStateArrowUp }),
            stateFilter: { label: 'All', value: 0 },
            setStateFilter: (stateFilter) => set({ stateFilter }),
            togglePatronArrowUp: true,
            setTogglePatronArrowUp: (togglePatronArrowUp) => set({ togglePatronArrowUp }),
            toggleFoodArrowUp: true,
            setToggleFoodArrowUp: (toggleFoodArrowUp) => set({ toggleFoodArrowUp }),
            toggleSeatingArrowUp: true,
            setToggleSeatingArrowUp: (toggleSeatingArrowUp) => set({ toggleSeatingArrowUp }),
            toggleHoursArrowUp: true,
            setToggleHoursArrowUp: (toggleHoursArrowUp) => set({ toggleHoursArrowUp }),
            independentCraftOnly: false,
            setIndependentCraftOnly: (independentCraftOnly) => set({ independentCraftOnly }),
            newStateOptions: [],
            getStateData: (async () => {
                try {
                    fetch('https://docs.mapbox.com/mapbox-gl-js/assets/us_states.geojson')
                        .then((res) => res.json())
                        .then((data) => {
                            get().setNewStateOptions(
                                data?.features
                                    ?.map((feature) => ({
                                        label: feature.properties.STATE_NAME,
                                        value: feature.id,
                                    }))
                                    ?.sort((a, b) => a.label.localeCompare(b.label))
                            )
                        })
                } catch (err) {
                    console.error(
                        'There has been an error receiving the states from mapbox on line 480 in useMapFunctions.tsx custom hook',
                        err
                    )
                }
            })(),
            setNewStateOptions: (newStateOptions) => set({ newStateOptions }),
            clearBorders: false,
            setClearBorders: (clearBorders) => set({ clearBorders }),
            fallBackStateFilter: { label: 'All', value: 0 },
            setFallBackStateFilter: (fallBackStateFilter) => set({ fallBackStateFilter }),
            pastStates: [],
            setPastStates: (pastStates) =>
                set((prevState) => {
                    return { pastStates: [...prevState.pastStates, pastStates] }
                }),
            allStates: Array.from(Array(80).keys()),
        }),
        { name: 'filterStore' }
    )
)
