import { User } from 'src/types/schema/graphql'
import { getAuthenticatedUserQuery } from '../graph'

import { RequestInput, apiRequest } from 'src/api/apiRequest'

export const fetchUser = async (userId: string): Promise<User> => {
    const input: RequestInput<unknown> = {
        query: getAuthenticatedUserQuery,
        args: {
            input: {},
        },
        file: 'fetchUser.ts',
        endpoint: 'getAuthenticatedUserQuery',
    }
    const { data } = await apiRequest<User, unknown>(input)

    return data
}
