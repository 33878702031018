import { useFavoriteActionsStore } from '../../state/useFavoriteActionsStore'
import { FavoriteType } from '../../types/enums/FavoriteType'

export const isFavorited = (id: string, type: FavoriteType) => {
    let isAFavorite: boolean
    switch (type) {
        case FavoriteType.Location: {
            const locationArr = useFavoriteActionsStore.getState()?.favoriteLocationIds
            const existingFav = locationArr?.find(({ locationId }) => locationId === id)
            isAFavorite = existingFav?.isActive
            break
        }
        case FavoriteType.Product: {
            const productArr = useFavoriteActionsStore.getState()?.favoriteProductIds
            const existingFav = productArr?.find(({ productId }) => productId === id)
            isAFavorite = existingFav?.isActive
            break
        }
        case FavoriteType.Brand: {
            const brandArr = useFavoriteActionsStore.getState()?.favoriteBrandIds
            const existingFav = brandArr?.find(({ brandId }) => brandId === id)
            isAFavorite = existingFav?.isActive
            break
        }
        default:
            isAFavorite = false
    }

    return isAFavorite
}
