const amplifyConfig = {
    aws_project_region: 'us-east-2',
    aws_cognito_identity_pool_id:
        process.env.NEXT_PUBLIC_AWS_ID_POOL || 'us-east-2:66488975-9f4c-4e33-befe-059eeef8fd21',
    aws_cognito_region: 'us-east-2',
    aws_user_pools_id: process.env.NEXT_PUBLIC_AWS_POOL || 'us-east-2_hTjyLjV4S',
    aws_user_pools_web_client_id:
        process.env.NEXT_PUBLIC_AWS_CLIENT || '7uq0dkuoevo4gktjbc76o4cehd',
    aws_appsync_graphqlEndpoint:
        process.env.NEXT_PUBLIC_AWS_ENDPOINT ||
        'https://5rmkzam5rre3zebtr27ga5ecw4.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
}
export default amplifyConfig
