import { RequestInput, apiRequest } from 'src/api/apiRequest'
import { Brand, InputGetById } from 'src/types/schema/graphql'
import { getBrandByIdQuery } from '../graph/getBrandByIdQuery'

export const getBrandById = async (brandId: string) => {
    const input: RequestInput<InputGetById> = {
        query: getBrandByIdQuery,
        args: {
            input: {
                itemId: brandId,
            },
        },
        file: 'useSavedBoardsStore.ts',
        endpoint: 'getBrandById',
        noAlerts: true,
        publicQuery: true,
    }
    const { data, success, error } = await apiRequest<Brand, InputGetById>(input)
    if (success) {
        return data
    } else {
        console.log(error)
    }
}
