import React from 'react'
import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'
import { useSavedBoardsStore } from 'src/features/favorites/state/useSavedBoardsStore'
import { useRouter } from 'next/router'

interface IDeleteBoardModal {}

const DeleteGuestBoardModal: React.FC<IDeleteBoardModal> = () => {
    const deleteGuestBoardModalOpen = useSavedBoardsStore(
        (state) => state.deleteGuestBoardModalOpen
    )
    const setDeleteGuestBoardModalOpen = useSavedBoardsStore(
        (state) => state.setDeleteGuestBoardModalOpen
    )
    const removeGuestBoard = useSavedBoardsStore((state) => state.removeGuestBoard)
    const boardDetailPageData = useSavedBoardsStore((state) => state.boardDetailPageData)
    const router = useRouter()
    const handleClick = async () => {
        setDeleteGuestBoardModalOpen(true)
        const success = await removeGuestBoard()
        if (success) {
            setDeleteGuestBoardModalOpen(false)
            router.push('/saved')
        } else {
            console.log('error deleting board', boardDetailPageData)
        }
    }

    return (
        <Modal
            isOpen={deleteGuestBoardModalOpen}
            onClose={() => setDeleteGuestBoardModalOpen(false)}
            isCentered={true}
            size='xl'
        >
            <ModalOverlay />
            <ModalContent className='space-y-3' p={6}>
                <ModalHeader p={0}>Remove Bookmarked Board</ModalHeader>
                <ModalCloseButton />
                <ModalBody p={0}>Are you sure you want to remove this bookmarked board?</ModalBody>
                <ModalFooter p={0}>
                    <HStack gap={3}>
                        <Button
                            onClick={() => setDeleteGuestBoardModalOpen(false)}
                            aria-label='Cancel'
                            size='sm'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleClick}
                            aria-label='Remove Guest Board'
                            size='sm'
                            variant='error'
                        >
                            Yes, Remove
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
export default DeleteGuestBoardModal
