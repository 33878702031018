const passwordRequirementLabels = [
	{
		title: 'Upper and lower case letters',
		key: 'upperAndLowerCase',
	},
	{ title: 'A number', key: 'hasNumber' },
	{
		title: 'Special character {}`#$%^&*()_+-=.,><~',
		key: 'hasSpecialCharacter',
	},
	{ title: 'Minimum of 8 characters', key: 'enoughCharacters' },
]

const passwordStrengthMapping = {
	'1': 'bg-red-500',
	'2': 'bg-orange-500',
	'3': 'bg-yellow-500',
	'4': 'bg-green-500',
}

export { passwordRequirementLabels, passwordStrengthMapping }
