export const routesConfig = {
    polygon: {
        id: 'routeLayerShadowFE',
        type: 'fill',
        source: 'routePolygonFE',
        layout: {
            visibility: 'invisible',
        },
        paint: {
            'fill-opacity': 0.25,
            'fill-color': '#A600E0',
            'fill-outline-color': 'rgba(166, 0, 224, 0.25)',
        },
    },
    arrows: {
        id: 'routeDirectionArrows',
        type: 'symbol',
        source: 'routeSource',
        paint: {},
        layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 50,
            'icon-image': 'directionsTriangle',
            'icon-rotate': 90,
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-size': 0.6,
        },
    },
    line: {
        id: 'routeLayer',
        type: 'line',
        source: 'routeSource',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
            visibility: 'visible',
        },
        paint: {
            'line-color': '#A600E0',
            'line-width': 7,
            'line-opacity': 0.75,
        },
    },
}
