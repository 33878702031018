export const srmTerms = {
    1: 'Light Straw',
    2: 'Straw',
    3: 'Straw',
    4: 'Straw',
    5: 'Light Gold',
    6: 'Gold',
    7: 'Gold',
    8: 'Gold',
    9: 'Gold',
    10: 'Light Amber',
    11: 'Light Amber',
    12: 'Amber',
    13: 'Amber',
    14: 'Amber',
    15: 'Amber',
    16: 'Dark Amber',
    17: 'Dark Amber',
    18: 'Dark Amber',
    19: 'Light Brown',
    20: 'Brown',
    21: 'Brown',
    22: 'Brown',
    23: 'Brown',
    24: 'Brown',
    25: 'Brown',
    26: 'Brown',
    27: 'Brown',
    28: 'Brown',
    29: 'Brown',
    30: 'Dark Brown',
    31: 'Dark Brown',
    32: 'Dark Brown',
    33: 'Dark Brown',
    34: 'Dark Brown',
    35: 'Dark Brown',
    36: 'Dark Brown',
    37: 'Dark Brown',
    38: 'Black',
    39: 'Black',
    40: 'Black',
    41: 'Black',
    42: 'Black',
    43: 'Black',
    44: 'Black',
    45: 'Black',
    46: 'Black',
    47: 'Black',
    48: 'Black',
    49: 'Black',
    50: 'Black',
    51: 'Black',
    52: 'Black',
    53: 'Black',
    54: 'Black',
    55: 'Black',
    56: 'Black',
    57: 'Black',
    58: 'Black',
    59: 'Black',
    60: 'Black',
    61: 'Black',
    62: 'Black',
    63: 'Black',
    64: 'Black',
    65: 'Black',
    66: 'Black',
    67: 'Black',
    68: 'Black',
    69: 'Black',
    70: 'Black',
    71: 'Black',
    72: 'Black',
    73: 'Black',
    74: 'Black',
    75: 'Black',
    76: 'Black',
    77: 'Black',
    78: 'Black',
    79: 'Black',
    80: 'Black',
    81: 'Black',
    82: 'Black',
    83: 'Black',
    84: 'Black',
    85: 'Black',
    86: 'Black',
    87: 'Black',
    88: 'Black',
    89: 'Black',
    90: 'Black',
    91: 'Black',
    92: 'Black',
    93: 'Black',
    94: 'Black',
    95: 'Black',
    96: 'Black',
    97: 'Black',
    98: 'Black',
    99: 'Black',
    100: 'Black',
}
