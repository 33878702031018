import React from 'react'
import classNames from 'classnames'
import * as UI from '@brewlogix/blx-ui'

interface IAvatarTheme {
    text: string
    background: string
}
interface IAccountAvatar {
    user: any
    role: string
    customClasses?: string
    size: string
    onClick?: () => void
}

const avatarThemes: IAvatarTheme[] = [
    {
        text: 'text-dark',
        background: 'bg-yellow-50',
    },
    {
        text: 'text-info',
        background: 'bg-blue-50',
    },
    {
        text: 'text-green-600',
        background: 'bg-green-50',
    },
    {
        text: 'text-light',
        background: 'bg-green-500',
    },
    {
        text: 'text-light',
        background: 'bg-secondary',
    },
]

const sizeClasses = {
    sm: 'w-8 h-8 min-w-8 min-h-8 p-1 text-xs',
    md: 'w-16 h-16 min-w-16 min-h-16 p-2 text-2xl',
}
const badgeSizeClasses = {
    sm: 'mr-1 mt-1 px-1 py-1 h-5 w-5',
    md: 'mr-2 mt-2 px-2 h-6 w-6',
}

export const AccountAvatar = ({ user, role, customClasses, size, ...props }: IAccountAvatar) => {
    const [avatarTheme, setAvatarTheme] = React.useState<IAvatarTheme>(avatarThemes[0])
    const getAvatarTheme = () => {
        if (!user?.givenName) return avatarThemes[0]
        const themeKey = (user?.givenName?.charCodeAt(0) % 5).toFixed(0)
        const theme = avatarThemes[themeKey]
        return theme
    }
    React.useEffect(() => {
        setAvatarTheme(getAvatarTheme())
    }, [user, role])

    const Avatar: React.FC = () => {
        return (
            <div
                className={classNames(
                    'flex flex-col items-center justify-center rounded-full font-semibold',
                    sizeClasses[size],
                    avatarTheme?.background,
                    avatarTheme?.text
                )}
            >
                {user?.givenName && user?.givenName[0]}
                {user?.familyName && user?.familyName[0]}
            </div>
        )
    }
    return (
        <div className={classNames('account-avatar', customClasses)} {...props}>
            {(role === 'superadmin' && (
                <UI.Badge
                    badgeContent={'💪'}
                    showZero={true}
                    theme='dark'
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    customClasses={classNames('w-auto ring-white ring-2', badgeSizeClasses[size])}
                    customWrapperClasses='w-fit'
                >
                    <Avatar />
                </UI.Badge>
            )) || <Avatar />}
        </div>
    )
}
