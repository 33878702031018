export const getBrandByIdQuery = /* GraphQL */ `
query GetBrandById($input: inputGetById!) {
getBrandById(input: $input) {   
    __typename
    id
    title
    slug
    displayTitle
    subtitle
    description
    assets {
        __typename
        id
        alt
        type
        caption
        ... on S3Asset {
            path
        }
        ... on CloudinaryAsset {
            cloudinaryId
        }
    }
    traits {
        brandTypes
        brewTypes
        isContract
        isIndependent
        status
    }
    locations{
        id
        brand {
    assets {
        __typename
        id
        alt
        type
        caption
        ... on S3Asset {
            path
        }
        ... on CloudinaryAsset {
            cloudinaryId
        }
    }
    }
    }
    createdDate
    updatedDate
    }
}`
