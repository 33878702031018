import endMarkerImage from '../../../../../public/assets/EndMarker@4x.png'
import startMarkerImage from '../../../../../public/assets/StartMarker@4x.png'
import directionsTriangle from '../../../../../public/assets/directionsTriangleWhite.png'

export const loadRouteMarkerIcons = (mapRef: mapboxgl.Map) => {
    mapRef.loadImage(startMarkerImage.src, (error, image) => {
        if (error) throw error
        if (!mapRef.hasImage('startMarkerIcon')) mapRef.addImage('startMarkerIcon', image)
    })
    mapRef.loadImage(endMarkerImage.src, (error, image) => {
        if (error) throw error
        if (!mapRef.hasImage('endMarkerIcon')) mapRef.addImage('endMarkerIcon', image)
    })
    mapRef.loadImage(directionsTriangle.src, (error, image) => {
        if (error) throw error
        if (!mapRef.hasImage('directionsTriangle')) mapRef.addImage('directionsTriangle', image)
    })
}
