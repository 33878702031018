import { IFormField } from '../types'

interface ICreateUserFormStep {
    title: string | null
    description: string | null
    type: 'register' | 'registerVerify'
    fields: IFormField[]
    formSubmitButtonLabel: string
    loadingText: string
}
interface ICreateUserFormConfig {
    formSteps: ICreateUserFormStep[]
}
export const createUserFormConfig: ICreateUserFormConfig = {
    formSteps: [
        {
            title: 'Create an account',
            description: 'Enter your information below to sign up.',
            type: 'register',
            fields: [
                {
                    name: 'fullName',
                    label: 'Full Name',
                    type: 'text',
                    placeholder: 'Full Name',
                    autoComplete: 'name',
                    required: true,
                    pattern: null,
                    id: 'newFullName',
                    className: null,
                },
                {
                    name: 'email',
                    label: 'Email',
                    type: 'email',
                    placeholder: 'Email',
                    autoComplete: 'email',
                    required: true,
                    pattern: null,
                    id: 'newEmail',
                    className: null,
                },
                {
                    name: 'password',
                    label: 'Password',
                    className: 'border-gray-100 focus:border-dark',
                    placeholder: 'Password',
                    autoComplete: 'new-password',
                    required: true,
                    pattern: null,
                    id: 'newPassword',
                },
            ],
            formSubmitButtonLabel: 'Sign Up',
            loadingText: 'Registering...',
        },
        {
            title: null,
            description: null,
            type: 'registerVerify',
            fields: [
                {
                    name: 'verifyCode',
                    label: 'Verification Code',
                    type: 'text',
                    placeholder: 'Verification Code',
                    autoComplete: 'off',
                    required: true,
                    pattern: '[0-9]*',
                    className: 'border-gray-100',
                    id: 'verifyCode',
                },
            ],
            formSubmitButtonLabel: 'Verify Email',
            loadingText: 'Verifying...',
        },
    ],
}
