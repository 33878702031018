import componentConfig from './componentConfig'
import { extendTheme } from '@chakra-ui/react'
import tailwindConstants from '@brewlogix/blx-ui/tailwind.constants.json'

const tailwind = tailwindConstants

const theme = extendTheme({
    fonts: {
        heading: 'Lato, sans-serif',
        body: 'Lato, sans-serif',
    },
    components: componentConfig,
    colors: {
        ...tailwind.theme.extend.colors,
        ...tailwind.theme.colors,
    },
})

export default theme
