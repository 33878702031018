import { ComponentStyleConfig } from '@chakra-ui/react'

const BoxConfig: ComponentStyleConfig = {
    baseStyle: {
        borderWidth: 1,
        borderRadius: 'lg',
        bg: 'gray.100',
        p: 6,
        transition: 'all',
        duration: '500ms',
    },
    sizes: {
        sm: {
            fontSize: 'sm',
            p: 2,
        },
        md: {
            fontSize: 'md',
            px: 6,
            py: 6,
        },
        lg: {
            fontSize: 'lg',
            px: 8,
            py: 8,
        },
    },
    // Variants  map to themes
    variants: {
        default: {},
        dark: {
            bg: 'gray.800',
            color: 'white',
        },
        medium: {
            bg: 'gray.100',
            color: 'black',
        },
        light: {
            bg: 'white',
            color: 'gray-800',
        },
    },
    // The default size and variant values
    defaultProps: {
        size: 'md',
        variant: 'default',
    },
}

export { BoxConfig }
