import * as UI from '@brewlogix/blx-ui'
import * as CC from '@chakra-ui/react'
import classNames from 'classnames'
import React from 'react'
import { FavoriteType } from 'src/features/favorites/types/enums/FavoriteType'
import { Asset, Brand, CompositeProduct, Location } from 'src/types/schema/graphql'

interface IFavoriteCardHeader {
    item: Location | Brand | CompositeProduct
    favoriteType: FavoriteType
}

const FavoriteCardHeader: React.FC<IFavoriteCardHeader> = ({ item, favoriteType }) => {
    const [classes, setClasses] = React.useState<string>('')
    const [title, setTitle] = React.useState<string>('')
    const [subTitle, setSubTitle] = React.useState<string>('')
    const [brandAsset, setBrandAsset] = React.useState<Asset>(null)
    const getClasses = () => {
        if (item?.__typename === 'Brand') {
            return classNames(
                'text-gray-400 text-sm',
                !(item as Brand)?.displayTitle &&
                    !(item as Brand)?.title &&
                    'brew-card__subtitle--empty'
            )
        } else {
            return classNames(
                'text-gray-400 text-sm',
                !(item as Location)?.brand?.displayTitle &&
                    !(item as Location)?.brand?.title &&
                    'brew-card__subtitle--empty'
            )
        }
    }
    const getBrandAsset = () => {
        if (item?.__typename === 'Brand') {
            return (item as Brand)?.assets[0]
        } else {
            return (item as Location)?.brand?.assets[0]
        }
    }
    React.useEffect(() => {
        setClasses(getClasses())
        let itemTitle = ''
        let itemSubTitle = ''
        if (item?.__typename === 'Brand' || item?.__typename === 'Location') {
            itemTitle = (item as Brand)?.displayTitle || (item as Brand)?.title
            itemSubTitle =
                (item as Brand)?.address?.city && (item as Brand)?.address?.state
                    ? `${(item as Brand)?.address?.city}, ${(item as Brand)?.address?.state}`
                    : ''
        } else {
            itemTitle = item?.title
            itemSubTitle =
                (item as Location)?.brand?.displayTitle || (item as Location)?.brand?.title
        }
        setBrandAsset(getBrandAsset)
        setTitle(itemTitle)
        setSubTitle(itemSubTitle)
    }, [])
    return (
        <CC.HStack justifyContent='space-between'>
            <CC.VStack alignItems='start' gap={1}>
                <CC.Heading
                    as='h3'
                    id={`brewCard--${item?.id}`}
                    className='text-base font-bold'
                    display='flex'
                    mb={0}
                >
                    {title}
                </CC.Heading>
                <p className={classes}>{subTitle}</p>
            </CC.VStack>
            {brandAsset && (
                <div className='h-12 w-12 overflow-hidden rounded-full'>
                    <UI.Media
                        asset={brandAsset}
                        customWrapperClasses='bg-gray-100 h-12 w-12 '
                        width={60}
                        height={60}
                        alt={`Logo Image for ${item?.title}`}
                    />
                </div>
            )}
        </CC.HStack>
    )
}
export default FavoriteCardHeader
