import React from 'react'
import * as CC from '@chakra-ui/react'
import { IField } from '../types/interfaces/IField'
import { useSavedBoardsStore } from '../state/useSavedBoardsStore'

interface ICreateBoardFormField {
    field: IField
}

const CreateBoardFormField: React.FC<ICreateBoardFormField> = ({ field }) => {
    const createBoardFormSubmitted = useSavedBoardsStore((state) => state.createBoardFormSubmitted)
    const setNewBoardTitle = useSavedBoardsStore((state) => state.setNewBoardTitle)

    const { label, placeholder, type, name, onChange } = field
    const [value, setValue] = React.useState('')

    React.useEffect(() => setNewBoardTitle(value), [createBoardFormSubmitted])

    return (
        <CC.InputGroup display='flex' flexDirection={'column'} key={name}>
            <CC.FormLabel className='text-sm font-bold'>{label}</CC.FormLabel>
            <CC.Input
                placeholder={placeholder}
                type={type}
                name={name}
                className='text-sm'
                colorScheme='gray'
                focusBorderColor='gray.500'
                onChange={(e) => onChange(e)}
            />
        </CC.InputGroup>
    )
}
export default CreateBoardFormField
