import { gql } from 'graphql-request'
export const updateFavoriteMutation = gql`mutation UpdateFavorite($input: inputFavorite!) {
	updateFavorite(input: $input){
		createdDate
		id
		updatedDate
		address {
			city
			country
			geo {
				lat
				lon
				title
			}
			postalCode
			state
			street
			street2
		}
		bdbAccess
		brewlogixSuperadmin
		contact {
			email
			phone
		}
		contactPreference
		createdBy
		defaultSubscription
		description
		familyName
		favorites {
			brands
			favoriteBrands {
				brandId
				isActive
				notes
			}
			favoriteLocations {
				locationId
				isActive
				brandId
				notes
			}
			favoriteProducts {
				productId
				isActive
				brandId
				notes
			}
			locations {
				brandId
				locationId
			}
			products {
				brandId
				productId
			}
		}
		givenName
		jobTitle
		lastUpdatedBy
		meta
		registrationSource
		slug
		trustedUser
	}
}`
