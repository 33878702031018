export const getBrandById = /* GraphQL */ `
query GetBrandById($input: inputGetById!) {
  getBrandById(input: $input) {   
      __typename
      id
      title
      slug
      displayTitle
      subtitle
      description
      traits {
        brandTypes
        brewTypes
        isContract
        isIndependent
        status
      }
       address {
        country
        city
        geo {
          lat
          lon
        }
        postalCode
        state
        street
        street2
      }
      website
      yearEstablished
      socialMedia {
        handle
        platform
      }
      contact {
        email
        phone
      }
      assets {
        __typename
        id
        alt
        type
        caption
        ... on S3Asset {
            path
        }
        ... on CloudinaryAsset {
            cloudinaryId
        }
    }
      locations {
        id
        title
        slug
        description
        address {
          street
          street2
          city
          state
          country
          postalCode
          geo {
            lat
            lon
          }
        }
        locationHighlights {
          description
        }
        traits {
          status
          type
          isProductionFacility
          foodOptions
          seatingOptions
          isPrimary
          foodOptions
          seatingOptions
          gameActivity
          miscActivity
          entertainmentActivity
          educationalActivity
          physicalActivity
          patronOptions
          tourInfo {
            description,
            toursAvailable
            mustBeLegalDrinkingAge
            cost, 
            hours {
              day
          isOpen
          hours
            }
          }
        }
        brand {
          id
          slug
          title
          displayTitle
          assets {
            __typename
            id
            alt
            type
            caption
            ... on S3Asset {
                path
            }
            ... on CloudinaryAsset {
                cloudinaryId
            }
          }
        }

        
      }
      products {
          id
          title
      }
      createdDate
      updatedDate
    }
  }`
